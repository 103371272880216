// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import {
  Button,
  Text,
  Flex,
  HelpLinkCard,
  MessageLinkCard,
  HomeArrowIcon,
  OptionCard,
  Skeleton,
  TwitterIcon,
  TelegramIcon,
  MediumIcon,
  LinkExternal
} from '@hurricaneswap/uikit'
import axios from "axios"

import LazyLoad from 'react-lazyload';
import { Link, useHistory } from 'react-router-dom'
import CoinToShow from './config'
import { helps, messages } from './config'
// import HomePageFooter from '../../components/HomePageFooter'
import useTheme from '../../hooks/useTheme'
import { useTranslation } from 'react-i18next'
import { SafeIcon, FastIcon, SimpleIcon } from './Icons'
import i18next from '../../i18n'
import useMobile from '../../hooks/useMobile'
import useHctUsdtBalance from 'hooks/useHctUsdtBalance'
import PeckshieldIcon from '../../assets/imgs/peckShield.svg'
import { clippingParents } from '@popperjs/core'
import Invester from './invester'
import useRequest from '@ahooksjs/use-request'
import { couldStartTrivia, isConstructorDeclaration } from 'typescript'

import peckShieldLogo from '../../assets/imgs/peckShield.svg'
import slowmistLogo from '../../assets/imgs/slowmist.svg'
import hashexLogo from '../../assets/imgs/hashex.svg'

const Wrapper = styled.div<{ ifMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  font-size: ${({ ifMobile }) => (ifMobile ? '14px' : '16px')};
`
const EntryLinkWrap = styled(Flex)`
  @media (max-width: 680px) {
    flex-direction: column;
  }
`
const FlexSection = styled(Wrapper)`
  font-size: ${({ ifMobile }) => (ifMobile ? '14px' : '16px')};
`

const Title = styled(Text)`
  font-family: GTTextBold;
  font-size: 2.5em;
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text};
`

const IconContainer = styled.div`
  width: 47.83px;
  height: 47.83px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content: center;
`
const CardWrapper = styled.div`
  >div{
    width: 100%;
    max-width: 490px;
    margin-top: 10px;
  }
  margin: auto;
  width: 100%;
  max-width: 1000px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 490px);
  grid-gap: 20px;
  @media (max-width: 522px) {
      grid-template-columns: repeat(auto-fill, 100%);
  
  }
`

const GridContainer = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: ${(ifMobile => (ifMobile ? '1fr 1fr 1fr' : 'repeat(auto-fill, 300px)'))};
  grid-gap: 20px;
`
const AuditReportLinkWrap = styled.div`
text-align:center;
color:#5723A6;
a{

}
`
const AuditReportGridContainer = styled.div<{ ifMobile: boolean }>`
display: grid;
justify-content: center;
grid-template-columns: ${(ifMobile => (ifMobile ? '1fr 1fr 1fr' : 'repeat(auto-fill, 300px)'))};
grid-gap: 20px;
padding: 30px 0 50px;
`

const CoinCardWrapper = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 230px));
  grid-gap: 35px 20px;
  width: 100%;
  max-width: 100%;
  margin-bottom: ${({ ifMobile }) => (ifMobile ? '30px' : '136px')};
  
  > div{
    width: 100%;
    max-width: 230px; 
  
  }
  
  @media (max-width: 510px) {
      grid-template-columns: repeat(2, 1fr);
      >div{
        width: 100%;
        height: 210px;
        img{
            transform: scale(0.7);
            transform-origin: bottom right;
        }
      }
      
  }
  
  @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      >div{
        width: 100%;
      }
  }
`

const GridWrapper = styled(Flex) <{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 320px));
  grid-gap: ${({ ifMobile }) => (ifMobile ? '15px' : '20px')};
 `

const DataCard = styled.div<{ ifMobile: boolean, ifNarrow: boolean }>`
  position:relative;
  background: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.5)'};
  border: 1px solid ${({ theme }) => theme.isDark ? 'rgba(77, 60, 85, 0.12)' : '#F1EDFF'}; 
  backdrop-filter: blur(50px);
  border-radius: 24px;
  // max-width: 320px;
  width: ${({ ifNarrow }) => ifNarrow ? '182px' : '100%'};
  // height: 166px;
  padding:20px 32px;
  font-family: GTText;
  & span{
    color:inherit;
    font-family: GTText;
    font-style: normal;
    font-weight: bold;
    line-height: 32px;
    margin-right:8px;
    margin-left:-3px
  }
  & div{
    margin-bottom:4px
  }
  div:first-child
  { 
    font-weight: 500;
    line-height: 22px;
    font-family: GTText;
  }
  div:last-child
  { 
    font-family: GTText;
    font-weight: normal;
    line-height: 22px;
    color: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.44)' : 'rgba(77, 60, 85, 0.65)'};
    margin-bottom:0px

  }
  #px {
    font-size: 1em; 
  }

  ${({ ifMobile }) => (ifMobile && css`grid-area: 2 / 1 / 2 / 3;`)}
  
`
const DataCardActivity = styled(DataCard)`
  background: linear-gradient(180deg, #FFFFFF 0%, #FFD686 100%);
  border: 1px solid rgba(255, 160, 82, 0.3);
  backdrop-filter: blur(50px);
  & div{
    color:${({ theme }) => theme.isDark ? '#995d36' : '#4D3C55'};
  }
  @media (max-width: 640px) { 
   }
`
const WrapOptionCard = styled(OptionCard)`
  &.dynamic:hover {
    transform: scale(0.98);
    img{
        transform: scale(1.05)
    }
}
`

const Info = styled.div`
margin-top: 10px;
margin-bottom: 10px;
text-align: center;
//--progress:0.5;
width:100%;
max-width: 300px;
display:flex;
flex-direction:column;
align-items:center;
margin-top:40px;
transition:all 0.5s ;
:hover{
  transform:scale(1.2)
 // animation-duration: 5s;
 // animation-name: swashIn;
  //animation-iteration-count: infinite
}

@keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }

  90% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
/*@keyframes swashIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(-90deg);
    transform: perspective(800px) rotateX(-90deg);
  }
  50% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(50deg);
    transform: perspective(800px) rotateX(50deg);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: perspective(800px) rotateX(0deg);
    transform: perspective(800px) rotateX(0deg);
  }
}*/
   

/*transition: 0.4s transform ease-in-out; 
 transform:scale(calc(1.8 - (0.8 * var(--progress)))) translateY(calc(60px * (1 - var(--progress))));
 opacity:var(--progress); */

:last-of-type{
margin-bottom:80px;
}

`
const AuditReportGrid = styled.div<{ ifMobile: boolean }>`
  text-align:center;
`

const Socialflex = styled(Flex)`
 width:100vw;
 justify-content:left;
 margin-top:60px;
 svg {
   margin-left:20px
 }
`
const Background = styled.div`
 width:100vw;
 background:${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(241, 237, 255, 0.4)'};
 padding:64px;
`
const Text1 = styled(Text) <{ ifMobile: Boolean }>`
  font-size: 0.875em;
  line-height: 16px;
  letter-spacing: 0.5px;
  width: 100%;
  max-width:588px;
  min-width: ${({ ifMobile }) => (ifMobile ? '100%' : '519px')};
  text-align: ${({ ifMobile }) => (ifMobile ? 'center' : 'left')};
  flex: 1;
  margin-right:80px;
  margin-bottom: 20px;
  color:${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.65)' : 'rgba(77, 60, 85, 0.85)'};
  
  @media (max-width: 1000px) {
    margin-right:0; 
  }
`

const Privacy = styled.div`
margin-top: 24px;
font-family: GTText;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #C7C8D9;
margin-left:20px
`

const PsImg = styled.img<{ width: String }>`
  width: ${({ width }) => (width)};
`
const LinkWithHover = styled.a`
  width: 100%;
  
  .audit span{
    transition: all linear 0.2s;
  }
`

const InvestLinkWithHover = styled.a`
    transition: all linear 0.2s;
    filter: ${({ theme }) => (theme.isDark ? 'contrast(0.6) opacity(0.8)' : ' opacity(0.6) ')};
  // .rectFill{
    // fill: #8D80D5;
    // color: #8D80D5;
    // transition: all linear 0.2s;
  // }
  :hover{
      filter: ${({ theme }) => (theme.isDark ? 'contrast(0.3) opacity(1)' : ' opacity(1) ')};
    // .rectFill{
      // fill: #6425C5;
      // color: #6425C5;
    // }
  }
`

const Triangle = styled.div<{ color: String; up: Boolean }>`
  width: 0;
  height: 0;
  ${({ up }) => up ? 'border-bottom: 10px solid #4BDD97' : 'border-top: 10px solid #FC5A94'};
  //border-top: 10px solid ${({ color }) => (color)};
  //border-bottom: 10px solid ${({ color }) => (color)};
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-left:6px
`
const StyledLinkExternal = styled(LinkExternal)`
  color: #5B8DEF;
  svg{
    fill: #5B8DEF;
  }
`
const ActivityIcon = styled.a`
  position:absolute;
  top:-32px;
  right:-20px;
  @media (max-width: 560px) { 
    left:-15px
  }
  &:hover{
    animation-name: tada;
    animation-timing-function: ease-in-out;
    animation-duration:1s;
    animation-iteration-count: 1
  }
  @keyframes tada {
    from {
      transform: scale3d(1, 1, 1);
    }
  
    10%,
    20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
  
    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
  
    40%,
    60%,
    80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
  
    to {
      transform: scale3d(1, 1, 1);
    }
  }

`

const initialValue = {
  // hct
  ['0x45C13620B55C35A5f539d26E88247011Eb10fDbd']: {
    price: '',
    arp: ''
  },
  // avax
  ['0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7']: {
    price: '',
    arp: ''
  },
  // usdt.e
  ['0xc7198437980c041c805A1EDcbA50c1Ce5db95118']: {
    price: '',
    arp: ''
  },
  // aCake
  ['0x7ed05cf8f77fb75a10947e0715c0876e074ff676']: {
    price: '',
    arp: ''
  },
  // wbtc.e
  ['0x50b7545627a5162f82a992c33b87adc75187b218']: {
    price: '',
    arp: ''
  },
  // aave.e
  ['0x63a72806098Bd3D9520cC43356dD78afe5D386D9']: {
    price: '',
    arp: ''
  },
  // qi
  ['0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5']: {
    price: '',
    arp: ''
  },
  // link.e
  ['0x5947BB275c521040051D82396192181b413227A3']: {
    price: '',
    arp: ''
  },
  // xava
  ['0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4']: {
    price: '',
    arp: ''
  }
}

const fetchTokensBody = "{\n  \"query\": \"{\\n  tokens{\\n   id\\n   symbol\\n   derivedBNB\\n    derivedUSD\\n  }\\n}\",\n  \"variables\": null\n}";
const fetchVolumeBody = "{\n  \"query\": \"{\\n  pancakeFactories(first: 5){\\n   totalVolumeUSD\\n }\\n}\",\n  \"variables\": null\n}"

const Home = () => {
  const theme = useTheme()
  const backgroundcolor = theme.isDark ? 'linear-gradient(90deg, #8D80D5 0%, #A37CDD 91.07%)' : 'linear-gradient(90deg, #5723A6 0%, #8D80D5 91.07%)'
  const CoinID = { 1027: 'eth', 7186: 'cake', 7083: 'uni', 8983: 'eps', 4512: 'link', 8335: 'mdx', 5805: 'avax' }
  const { t } = useTranslation()
  const ifMobile = useMobile()
  const ifMobileForPeckShield = useMobile(1000)
  const ifMobileForNav = useMobile(1200)
  const { push } = useHistory()
  // const [coinData, setCoinData] = useState(window.localStorage.getItem('cards') && JSON.parse(window.localStorage.getItem('cards')).hct ? JSON.parse(window.localStorage.getItem('cards')) : initialValue)
  const [coinData, setCoinData] = useState(initialValue)
  const [helpTrans, setHelpTrans] = useState(helps)
  const [messageTrans, setMessageTrans] = useState(messages)
  const [allottedValue, setAllottedValue] = useState(0)
  const [toAlloted, setToAlloted] = useState(0)
  const [toAllotedUSDT, setToAllotedUSDT] = useState(0)
  const [toAllotedWavax, setToAllotedWavax] = useState(0)
  const [toAllotedWavaxUSDT, setToAllotedWavaxUSDT] = useState(0)
  const distributedFeesHistory = 180455633 * 0.001 * 0.9  // hct
  const distributedNum = 604060 + 20000 + 6133.471 + 6000 + 28000 + 8500 + 16310 + 52900 + 50558 + 25700 + 17312 + 21779 + 28598.86 + 37061.08 + 20899.88 + 20165.16 + 21934 + 10701.23 + 3822.48 + 11556 + 28643.46 + 27766 + 72323 + 11969.4
  const distributedWAVAXNum = 8.6 + 3.417 + 3 + 12 + 3.9 + 6 + 9 + 7 + 7 + 4.08 + 4.34 + 5.233 + 6.94 + 3.636 + 3.13 + 3.13 + 1.48 + 1.19 + 2.69 + 4.35 + 2.75 + 3.3 + 1.42
  const distributedUSDT = 20000 * 0.065 + 8 * 103.56 + 6133.471 * 0.063367 + 3.417 * 113.15 + 3 * 118.971 + 12 * 115 + 3.9 * 117.3 + 6 * 107.389 + 9 * 103.376 + 7 * 106 * 0.055 + 8500 * 0.053 + 16310 * 0.047 + 52900 * 0.035 + 40558 * 0.027 + 25700 * 0.026 * 2 + 17312 * 0.02 * 2 + 21779 * 0.01817 * 2 + 28598.86 * 0.0165 + 5.233 * 86 + 6.94 * 61.67 + 37061.08 * 0.011 + 0.011 * 20899.88 + 3.636 * 62.77 + 214.4 + 218 + 218 + 225 + 122.22 + 121.34 + 46.2 + 106.22 + 239.27 + 120 + 231 + 384.8 + 114.85 + 216.93 + 252.43 + 518.58 + 66.78 + 103.7
  const distributedFees = distributedFeesHistory + distributedUSDT

  // const [distributedFees, setDistributedFees] = useState(0)
  const [tvl, setTvl] = useState(0)
  const { run: fetchTokens, data: tokenData } = useRequest(
    () => fetch("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      method: 'POST',
      body: fetchTokensBody,
      variables: null
    }), { manual: true, pollingInterval: 5000 })

  const { run: fetchVolume, data: volumeData } = useRequest(
    () => fetch("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      method: 'POST',
      body: fetchVolumeBody,
      variables: null
    }), { manual: true, pollingInterval: 5000 })

  const handleTokenValue = async (data) => {
    const res = await data.text()
    const tokens = JSON.parse(res)?.data?.tokens
    // console.log('handleTokenValue', tokens)
    setCoinData(old => {
      const _ = JSON.parse(JSON.stringify(old))
      Object.keys(_).map((item, index) => {
        const __ = tokens.filter(i => {
          return item.toLowerCase() == i?.id.toLowerCase()
        })
        if (__.length > 0) {
          _[item].price = Number(__[0]?.derivedUSD).toFixed(3)
        }
      })
      return _
    })
  }

  const handleVolumeValue = async (data) => {
    // const res = await data.text()
    // console.log(data)
    // volume总量
    //  const volume = JSON.parse(res)?.data?.pancakeDayDatas[0]?.dailyVolumeUSD
    //  setAllottedValue(Number(volume).toFixed())
  }
  const getTotalValue = async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const totalVolumeUSD = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      query: `{
        pancakeFactories(first: 5) {
          totalVolumeUSD
        }
      }`,
      variables: null
    });

    if (totalVolumeUSD?.data?.data?.pancakeFactories) {
      const res = totalVolumeUSD?.data?.data?.pancakeFactories[0]['totalVolumeUSD'] * 0.001 * 0.9  // 2021-12-16 *0.5系数
      // console.log(res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'],'2222')
      const result = (res - distributedUSDT) * 0.5
      setAllottedValue(Number(result).toFixed(2))

    }
  };

  const getTVL = async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      query: `{
        pancakeFactories(first: 5) {
          id
          totalLiquidityUSD
        }
      }`,

      variables: null
    });


    const singelToken = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/farm-v2", {
      query: `{
        pools(where: {id: "23"}){
          entryUSD
          exitUSD
        }
      }
      `,
      variables: null
    });

    const xHCT = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/farm-v2", {
      query: `{
        pools(where: {id: "0"}){
          slpDeposited
          slpWithdrawn
        }
      }
      `,
      variables: null
    });


    const sHCT = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/farm-v2", {
      query: `{
        pools(where: {id: "24"}){
          slpDeposited
          slpWithdrawn
        }
      }
      `,
      variables: null
    });
    const hctAddr = "0x45C13620B55C35A5f539d26E88247011Eb10fDbd"
    const wavaxAddr = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
    const price = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      // 之后换成每天的   
      query: `{
            tokens(where:{
              id_in:["${hctAddr.toLocaleLowerCase()}","${wavaxAddr.toLocaleLowerCase()}"]
            }){
              id
              symbol
              derivedUSD
             
            }
          }`,
      variables: null
    });


    const ratio = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/bar", {
      query: `{
        bars(first: 5) {
          id
          ratio
        }
      }
      `,
      variables: null
    });


    if (res?.data?.data?.pancakeFactories) {
      const pairLq = res?.data?.data?.pancakeFactories[0]['totalLiquidityUSD']
      // console.log(result)
      /**
       * 获取比例
       * xhct 价格
       * xHCTTotalSupply / bar里面的HCT
       */

      const SingleFarm = singelToken?.data?.data?.pools[0]['entryUSD'] - singelToken?.data?.data?.pools[0]['exitUSD']
      const t = ratio?.data?.data?.bars[0].ratio

      const priceList = price?.data?.data?.tokens
      let hctPrice = 0
      let wavaxPrice = 0
      priceList?.map(token => {
        if (token?.id === hctAddr.toLocaleLowerCase()) {
          hctPrice = token?.derivedUSD
        }
        if (token?.id === wavaxAddr.toLocaleLowerCase()) {
          wavaxPrice = token?.derivedUSD
        }
      })
      // const hctprice = price?.data?.data?.tokens[0]?.derivedUSD
      // console.log('hctprice-----', hctPrice, price)
      const xHCTTvl = (xHCT?.data?.data?.pools[0]['slpDeposited'] - xHCT?.data?.data?.pools[0]['slpWithdrawn']) * hctPrice * t
      const sHCTTvl = (sHCT?.data?.data?.pools[0]['slpDeposited'] - sHCT?.data?.data?.pools[0]['slpWithdrawn']) * hctPrice

      const totaltvl = Number(pairLq) + SingleFarm + xHCTTvl + sHCTTvl
      setTvl(parseInt(Number(totaltvl)).toLocaleString())



      //  console.log('res',res)
    }
  };


  useEffect(() => {
    fetchTokens()
    fetchVolume()
    getTotalValue()
    getTVL()
  }, [])

  useEffect(() => {
    if (tokenData) {
      handleTokenValue(tokenData)
    }
  }, [tokenData])

  useEffect(() => {
    if (volumeData) {
      handleVolumeValue(volumeData)
    }
  }, [volumeData])

  useEffect(() => {
    const helpTrans = helps.map((help) => ({ label: help['label'], href: help['href'] }))
    const messageTrans = messages.map((message) => ({ label: message['label'], href: message['href'] }))
    const data = _.cloneDeep(helpTrans)
    const data1 = _.cloneDeep(messageTrans)
    setHelpTrans(data)
    setMessageTrans(data1)
  }, [i18next.languages])

  const routerToSwap = (coin) => {

    if (coin.title == 'avax') {
      push(`/swap`)
    } else {
      push(`/swap?inputCurrency=${coin.address}`)
    }
  }

  /**
   * 6000   0.060517
回购wavax数量（2.5/6） 3   118.971
   */


  // console.log('coinData', coinData)
  useEffect(() => {
    const allotted = (parseInt((allottedValue) / parseFloat(coinData['0x45C13620B55C35A5f539d26E88247011Eb10fDbd']?.price)))
    const allottedWAVAX = ((allottedValue) / parseFloat(coinData['0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7']?.price)).toFixed(2)
    const allottedUSDT = parseInt(allottedValue) * 2
    if (allottedWAVAX > 0 && !Number.isNaN(allottedWAVAX)) {
      setToAllotedWavax(allottedWAVAX)
    }
    if (allotted > 0 && !Number.isNaN(allotted)) {
      setToAlloted(allotted)
    }
    if (allottedUSDT > 0) {
      setToAllotedUSDT(allottedUSDT)
    }

  }, [allottedValue, coinData])
  return (
    <Wrapper id='wrapper' ifMobile={ifMobile}>
      <FlexSection ifMobile={ifMobile}>
        <Title style={{ fontSize: "1.8rem" }}>{t("homePageSlogan")}</Title>
        {/* <Text textAlign='center' mb={20} fontSize='1.5em' fontWeight='400' maxWidth='642px' width='100vw'>
          {t('homePageSlogan')}
        </Text> */}
        <EntryLinkWrap >
          <Button as={Link} to='/swap' style={{
            justifyContent: 'space-between',
            fontSize: '1.75em',
            height: '80px',
            width: '280px',
            boxShadow: '0px 14px 60px rgba(139, 70, 182, 0.4)',
            borderRadius: '68px',
            background: backgroundcolor,
            textAlign: 'center',
            margin: '20px 20px 0'
          }}>{t('StartTrading')}
            <IconContainer><HomeArrowIcon width='40px' /></IconContainer>
          </Button>
        </EntryLinkWrap>
        <StyledLinkExternal href="https://v1.hurricaneswap.com/" mt='40px' mb={ifMobile ? '50px' : '0'} >{t("Go Hurricane V1")}</StyledLinkExternal>


      </FlexSection>


      <section style={{ marginTop: '80px', marginBottom: '60px', width: '100%' }}>
        <GridWrapper ifMobile={ifMobile}>
          <DataCard ifNarrow={ifMobile}>
            <Text fontSize={ifMobile ? '16px' : '18px'}>{t('Total Distributed')}</Text>
            <Text fontSize={ifMobile ? '17px' : '24px'}><span>{parseInt(distributedNum).toLocaleString()}</span>HCT</Text>
            <Text fontSize={ifMobile ? '17px' : '24px'}><span>{parseInt(distributedWAVAXNum).toLocaleString()}</span>WAVAX</Text>
            <Text fontSize={ifMobile ? '16px' : '18px'}>≈${parseInt((distributedFees)).toLocaleString()}</Text>
          </DataCard>
          <DataCard ifNarrow={ifMobile}>
            <Text fontSize={ifMobile ? '16px' : '18px'}>{t('To be allotted')}</Text>
            <Text fontSize={ifMobile ? '17px' : '24px'}><span> {toAlloted && toAlloted?.toLocaleString() || '---'} </span>HCT</Text>
            <Text fontSize={ifMobile ? '17px' : '24px'}><span> {toAllotedWavax && toAllotedWavax?.toLocaleString() || '---'} </span>WAVAX</Text>
            <Text fontSize={ifMobile ? '15px' : '18px'}>≈${toAllotedUSDT && toAllotedUSDT.toLocaleString() || '---'}</Text>
          </DataCard >
          <DataCard ifMobile={ifMobileForNav}>
            <Text fontSize={ifMobile ? '16px' : '18px'}>{t('Total Value Locked')}</Text>
            <Text fontSize={ifMobile ? '17px' : '24px'}>$ <span>{(tvl) || '---'}</span></Text>
            <Text id='px' fontSize={ifMobile ? '15px' : '18px'}>{t('Cover HurricaneStation')}</Text>
          </DataCard>
        </GridWrapper >

      </section >

      <section style={{ width: '100%', maxWidth: '1000px' }}>

        <CoinCardWrapper ifMobile={ifMobile}>
          {CoinToShow.map((coin) => (
            <WrapOptionCard onClick={() => {
              routerToSwap(coin)
            }} key={coin.currency} chain={coin.chain} chainIconUrl={coin.chainIconUrl} coinIconUrl={coin.coinIconUrl}
              currency={coin.currency} cardColor={coin.color} className={ifMobile ? '' : 'dynamic'}>
              <Text fontSize='1.25em!important'>{coin.displayName}</Text>

              {
                (coinData[coin.contract] && coinData[coin.contract].price)
                  ? (<Text fontSize='1.5em!important'>${coinData[coin.contract].price}</Text>)
                  : (<Skeleton animation="pulse" variant="rect" width="60%" height="32px" />)
              }
              {/* {(coinData[coin.title] && coinData[coin.title].arp)
                  ? (
                    <Flex alignItems='center'>
                      <Text fontSize='1em!important'>{coinData[coin.title].arp} % </Text>
                      <Triangle up={coinData[coin.title].arp > 0 ? true : false} />
                    </Flex>
                  )
                  : (<Skeleton animation="pulse" variant="rect" width="30%" height="22px" />)} */}

            </WrapOptionCard >
          ))}
        </CoinCardWrapper >
      </section >

      <section style={{ width: '100%', maxWidth: '1000px' }}>
        <Title style={{ textAlign: ifMobile ? 'center' : 'center' }}>{t('Why HurricaneSwap')}</Title>
        <GridContainer id='msg' ifMobile={ifMobile}>
          <Info className={ifMobile ? 'info swashIn' : 'info'}>
            <SimpleIcon />
            <Text mt={1} fontSize='20px' fontWeight='400'>{t('Easier')}</Text>
            <Text>{t('Add liquidity in 2 steps, 15 seconds')}</Text>
          </Info>
          <Info className={ifMobile ? 'info swashIn' : 'info'}>
            <SafeIcon />
            <Text mt={1} fontSize='20px' fontWeight='400'>{t('Safer')}</Text>
            <Text>{t('Avoid the risk of money laundering')}</Text>
          </Info>
          <Info className={ifMobile ? 'info swashIn' : 'info'}>
            <FastIcon />
            <Text mt={1} fontSize='20px' fontWeight='400'>{t('Cheaper')}</Text>
            <Text>{t('0.2% cross-chain fee, lower slippage')}</Text>
          </Info>
        </GridContainer>
      </section>
      <section style={{ width: '100%', maxWidth: '1000px' }}>
        <Title style={{ textAlign: ifMobile ? 'center' : 'center' }}>{t('Audit Report')}</Title>
        <AuditReportLinkWrap>
          <a href="https://github.com/HurricaneSwap/AuditReport" target="_blank">{t('See All Audit Report')}</a>
        </AuditReportLinkWrap>
        <AuditReportGridContainer ifMobile={ifMobile}>
          <AuditReportGrid className={ifMobile ? 'info swashIn' : 'info'}>
            <a href="https://github.com/HurricaneSwap/AuditReport/blob/main/PeckShield-Audit-Report-HurricaneSwap-v1.0.pdf" target="_blank">
              <img src={peckShieldLogo} />
            </a>
          </AuditReportGrid>
          <AuditReportGrid className={ifMobile ? 'info swashIn' : 'info'}>
            <a href="https://github.com/HurricaneSwap/AuditReport/blob/main/HashEx_HurricaneSwap%20Smart%20Contracts%20Audit%20Report_December%202021.pdf" target="_blank">
              <img src={hashexLogo} />
            </a>
          </AuditReportGrid>
          <AuditReportGrid className={ifMobile ? 'info swashIn' : 'info'}>
            <a href="https://github.com/HurricaneSwap/AuditReport/blob/main/SlowMist%20Audit%20Report%20-%20HurricaneSwap-v2-contract.pdf" target="_blank">
              <img src={slowmistLogo} />
            </a>
          </AuditReportGrid>
        </AuditReportGridContainer>
      </section>

      <LazyLoad height={200} offset={100}>
        <Invester />
      </LazyLoad>
      {
        !ifMobile && (
          <Background >
            {/* <Title style={ { textAlign: ifMobile ? 'center' : 'left' } }>{ t ('Need help with something?') }</Title> */}
            <CardWrapper>
              <HelpLinkCard title={t('Help')} helps={helpTrans} style={{ maxWidth: '490px', width: '100%' }}
                isDark={theme.isDark} />
              <MessageLinkCard title={t('Press')} helps={messageTrans}
                style={{ maxWidth: '490px', width: '100%' }} isDark={theme.isDark} />
            </CardWrapper>
          </Background>
        )
      }
      {/* <HomePageFooter /> */}
    </Wrapper >
  )
}

export default Home
