import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Flex, Text, Button } from '@hurricaneswap/uikit'
import { NavLink } from 'react-router-dom'
import useMobile from '../../hooks/useMobile'
import coinGroup from '../../assets/imgs/coinGroup.png'
import stationBg from '../../assets/imgs/station-intro-bg.png'



const CardContainer = styled.div<{ ifMobile: boolean }>`
  max-width: 710px;
  width: 100%;
  padding: ${({ ifMobile }) => (ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  min-height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => (theme.colors.swapContainer)};
  border:${({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none')}; 
  border-radius: 20px;

`

const Card = styled.div<{ ifMobile?: boolean }>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px 5px 20px;
  background:url(${stationBg}) no-repeat right center;
  background-size: contain;  
  @media(max-width: 720px) {
    background:url(${stationBg}) no-repeat center 20px;
    padding-top:300px;
    background-size: contain;  
    word-break: keep-all;
    white-space: nowrap;
    font-size:12px;
  }
`
const Wrap = styled.div`
 
`

const Circle = styled.div`
  max-width: 332px;
  max-height: 332px;
  position: relative;
  ::after{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid black;  
  }
`
const DesFlex = styled(Flex)`
  padding:30px 21px 0;
  @media(max-width: 720px) {
    justify-content:center;
    padding:0
  }
`
const DesTextFlex = styled(Text)`
font-size:16px;
text-align:left;
line-height:30px;
margin-bottom:10px;
@media(max-width: 720px) {
  font-size:14px;
  text-align:center;
}
`
const DesButtonFlex = styled(Button)`
@media(max-width: 480px) {
  width:100%;
}
`
const titile = 'What\'s HurricaneStation'
const des1 = 'Bridge the liquidity from multi-chain to Avalanche;'
const des2 = 'LP rewards through providing liquidity;'
const des3 = 'Farm rewards through staking Cross-Chain LP Tokens.'



const StationDes = () => {
  const ifMobile = useMobile()
  const { t } = useTranslation()

  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Wrap >
          <DesFlex >
            <Flex alignItems={ifMobile ? 'center' : 'flex-start'} flexDirection='column' maxWidth='380px' style={{ boxSizing: 'content-box' }}>
              <Text mb='20px' fontSize='24px' >{titile}</Text>
              <DesTextFlex>{t(des1)}</DesTextFlex>
              <DesTextFlex>{t(des2)}</DesTextFlex>
              <DesTextFlex>{t(des3)}</DesTextFlex>
              <Flex>
                <DesButtonFlex variant="secondary" width="200px" height="48px" style={{ fontSize: '16px', marginBottom: '12px' }}>
                  <a href='https://hurricaneswap.medium.com/launch-of-hurricaneswap-testnet-v2-station-tutorial-633392c2a120' rel="noreferrer" target='_blank'>Tutorial</a></DesButtonFlex>
                <DesButtonFlex variant="secondary" width="200px" height="48px" style={{ fontSize: '16px', marginLeft: '12px', marginBottom: '12px' }}>
                  <NavLink to='/deposit' >Bridge</NavLink>
                </DesButtonFlex>
              </Flex>
            </Flex>
          </DesFlex>
        </Wrap>
      </Card>
    </CardContainer>

  )
}

export default StationDes
