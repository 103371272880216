import { Button, Flex, Text } from '@hurricaneswap/uikit'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Currency } from '@glhf-libs/sdk'
import styled from 'styled-components'
import { Dots } from 'components/swap/styleds'
import { lpBridgeDefaultChainConfig as defaultChainConfig } from 'constants/index'
import Select from 'components/Lpbridge/Select'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { useSwitchChain } from 'hooks/Station'
import { StyledBnbLogo } from 'components/CurrencyLogo'
import LpTokenSelect from 'components/LpTokenSelect'
import lpBridgeList from 'constants/lpbridgeList'
import getTargetChainIdList from 'utils/lpBridge'
import { Input as NumericalInput } from '../NumericalInput'
import { useActiveWeb3React } from '../../hooks'
import { TranslateString } from '../../utils/translateTextHelpers'
import useTheme from '../../hooks/useTheme'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`


const TrigerFlex = styled(Flex)`
    @media (max-width: 375px) { 
        display: none;
    }
`
const StyleFlex = styled(Flex)`
    @media (max-width: 320px) { 
        margin-bottom: 0px
    }
`

const PairLogos = ({ baseLogo, quoteLogo }) => {
  return (
    <TrigerFlex>
      <StyledBnbLogo src={baseLogo} size='20px' />
      <StyledBnbLogo src={quoteLogo} size='20px' />
    </TrigerFlex>
  )
}

const StyledText = styled(Text)`
@media (max-width: 320px) { 
  font-size: 15px;
  margin-bottom: 5px
}

`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  /*background-color: ${({ theme }) => theme.colors.background};*/
  // z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  /*background-color: ${({ theme }) => theme.colors.input};*/
  /*box-shadow: ${({ theme }) => theme.shadows.inset};*/ 
`

interface CurrencyInputPanelProps {
  defaultBalanceValue?: any
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  hideInput?: boolean
  id: string
  dir?: string
  disabled?: boolean
  chainConfig?: Array<any>
  defaultBridgeChainId?: any
  setSelectResult?: any
  selectLp?: any
}

export default function CurrencySelectPanel({
  setSelectResult,
  disabled = false,
  defaultBalanceValue = ' -',
  value,
  dir = "Input",
  onUserInput,
  onMax,
  showMaxButton,
  label = TranslateString(14, 'Input'),
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  hideInput = false,
  id,
  chainConfig,
  defaultBridgeChainId,
  selectLp,
}: CurrencyInputPanelProps) {
  const { account, chainId } = useActiveWeb3React()
  // const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { theme } = useTheme()
  const options = useMemo(() => {
    return (chainConfig || defaultChainConfig).map(i => {
      return ({
        label: i.shortName,
        value: i.chainId,
        src: i.src
      })
    }).filter(i => i)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])
  const login = useSwitchChain()
  const [sortOption, setSortOption] = useState(defaultBridgeChainId)
  const handleSortOptionChange = (option: any) => {
    login(option.value)
  }

  const tokenPairsWithLiquidityTokens = useMemo(
    () => {
      return lpBridgeList.map((tokens) => {
        const _token = getTargetChainIdList(chainId, tokens)
        return ({
          liquidityToken: _token,
          tokens: [
            tokens.token,
            tokens.quoteToken
          ],
          lpLogo: tokens.lpLogo,
          token0Logo: tokens.token.logoURI,
          token1Logo: tokens.quoteToken.logoURI,
          prefix: tokens?.prefix,
          from: tokens?.from
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lpBridgeList, chainId]
  )

  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenPairsWithLiquidityTokens, chainId])

  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  const lpOptions = useMemo(() => {
    if (tokenPairsWithLiquidityTokens) {
      return tokenPairsWithLiquidityTokens.map(i => {

        // @ts-ignore
        const _label = `${i?.liquidityToken?.symbol}`
        const _value = i?.liquidityToken?.address
        const prefix = i?.prefix[sortOption]
        return {
          label: `${prefix}${_label}`,
          value: _value,
          ...i,
          ...v2PairsBalances[_value],
        }
      })
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, tokenPairsWithLiquidityTokens, v2PairsBalances, sortOption])

  const [lpSelection, setLpSelection] = useState(null)

  const handleSortLpTokenOptionChange = (option: any) => {
    setLpSelection(option)
  }

  // init
  useEffect(() => {
    if (!lpSelection) {
      setLpSelection(old => {
        return lpOptions[0]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, label, selectLp, lpOptions, lpSelection])

  useEffect(() => {
    onCurrencySelect(lpSelection?.liquidityToken)
    if (setSelectResult) {
      setSelectResult(lpSelection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpSelection])

  const chooseTokenInfo = lpOptions.filter(lp => lp.value === selectLp?.address)[0]
  // @ts-ignore
  // console.log(chooseTokenInfo?.currency?.chainId)
  const OutputContent = useCallback(() =>
    selectLp === undefined ? (<div>Select LP-token</div>) : (
      <Flex alignItems='center' paddingLeft='16px'>
        { // @ts-ignore
          (chooseTokenInfo?.label.includes('Hc-') ?
            <StyledBnbLogo src={chooseTokenInfo?.lpLogo} size='24px' /> :

            <PairLogos baseLogo={chooseTokenInfo?.token0Logo} quoteLogo={chooseTokenInfo?.token1Logo} />)}
        <Text ml='10px'>{chooseTokenInfo?.label}</Text>
      </Flex>
    ), [selectLp, chooseTokenInfo])

  const balance = useMemo(() => {
    if (hideBalance) {
      return defaultBalanceValue || ' -'
    }
    if (!currency) {
      return 'Invalid Currency'
    }
    if (v2PairsBalances[lpSelection?.liquidityToken?.address] === undefined) {
      return <Dots>Loading</Dots>
    }
    if (fetchingV2PairBalances) {
      return <Dots>Loading</Dots>
    }
    if (v2PairsBalances[lpSelection?.liquidityToken?.address]) {
      return `Balance: ${v2PairsBalances[lpSelection?.liquidityToken?.address]?.toSignificant(6)}`
    }

    return ' -'
  }, [fetchingV2PairBalances, hideBalance, currency, v2PairsBalances, lpSelection, defaultBalanceValue])

  const maxLength = useMemo(() => {
    return value.split('.')[0].length + 19
  }, [value])

  return (
    <>
      <InputPanel id={id}>
        <Container hideInput={hideInput}>
          {dir && <StyledText mb='10px' color={theme.colors.darkText}>{dir}</StyledText>}

          <StyleFlex alignItems='center' mb='25px'>
            <Flex mr='10px' flex={1}>
              <Select
                options={options}
                disabled={options.length === 1 ? Boolean(true) : Boolean(false)}
                showLogo={Boolean(true)}
                onChange={handleSortOptionChange}
              />
            </Flex>

            <Flex flex={3}>
              {label === 'Output' ? (
                <OutputContent />
              ) :
                (
                  <LpTokenSelect options={lpOptions}
                    disabled={lpOptions.length === 1 ? Boolean(true) : Boolean(false)}
                    loading={fetchingV2PairBalances}
                    showLogo={Boolean(true)}
                    onChange={handleSortLpTokenOptionChange}
                    v2PairsBalances={v2PairsBalances}
                  />)
              }
            </Flex>
          </StyleFlex>

          <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}
            selected={disableCurrencySelect}>
            {!hideInput && (
              <>
                <NumericalInput
                  maxLength={maxLength}
                  className="token-amount-input"
                  value={value}
                  onUserInput={val => {
                    onUserInput(val)
                  }}
                />
                {account && currency && showMaxButton && label !== 'To' && (
                  <Button onClick={onMax} size="sm" variant="text">
                    MAX
                  </Button>
                )}
              </>
            )}

          </InputRow>

          {account && (
            <Text color={theme.colors.darkText} onClick={onMax} fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
              {balance}
            </Text>
          )}

        </Container>

      </InputPanel>
    </>
  )
}
