import React, { ReactNode, useCallback, useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import CardNav from 'components/CardNav'
import { useLocation } from 'react-router'
import { Heading, IconButton, Text, Flex, useModal } from '@hurricaneswap/uikit'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'
import useTheme from '../../hooks/useTheme'


interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
  showSettings?: boolean
}

// TODO: use UI Kit
const CogIcon = ({ color }) => (
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 1C21 0.447715 20.5523 0 20 0C19.4477 0 19 0.447715 19 1V10C19 10.5523 19.4477 11 20 11C20.5523 11 21 10.5523 21 10V1Z" fill={color} />
    <path d="M8 6C8 5.44772 8.44771 5 9 5H11V1C11 0.447715 11.4477 0 12 0C12.5523 0 13 0.447715 13 1V5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H9C8.44771 7 8 6.55228 8 6Z" fill={color} />
    <path d="M13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V10Z" fill={color} />
    <path d="M0 12C0 11.4477 0.447715 11 1 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H5V19C5 19.5523 4.55228 20 4 20C3.44772 20 3 19.5523 3 19V13H1C0.447715 13 0 12.5523 0 12Z" fill={color} />
    <path d="M16 14C16 13.4477 16.4477 13 17 13H23C23.5523 13 24 13.4477 24 14C24 14.5523 23.5523 15 23 15H21V19C21 19.5523 20.5523 20 20 20C19.4477 20 19 19.5523 19 19V15H17C16.4477 15 16 14.5523 16 14Z" fill={color} />
    <path d="M5 1C5 0.447715 4.55228 0 4 0C3.44772 0 3 0.447715 3 1V8C3 8.55228 3.44772 9 4 9C4.55228 9 5 8.55228 5 8V1Z" fill={color} />
  </svg>
)

const HistoryIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 6V12L16 14" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const StyledPageHeader = styled.div`
  // border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 51px 24px 0 24px;
  @media (max-width: 320px) { 
    padding: 45px 21px 0 21px;
}
`
const StyledPageHeaderTitle = styled(StyledPageHeader)`
  text-align:center;
  font-size:22px;
  color:${({ theme }) => theme.colors.text};
`
const Details = styled.div`
  flex: 1;
`

const StyledPageHeaderFlex = styled.div`
  display: flex;
  justify-content: space-between;
`

const MarginTop = styled.div`
  margin-top: 40px;
`
const IconButtonWrapper = styled(IconButton)`
  height: auto;
  @media (max-width: 320px) { 
    width: 30px;
}
`
const StyledHeading = styled(Heading)`
  @media (max-width: 320px) { 
    font-size: 18px;
}
`

const PageHeader = ({ title, description, children, showSettings = true }: PageHeaderProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleDismissSettings = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  const handleOpenSettings = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])
  // const [onPresentSettings] = useModal (<SettingsModal/>)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal />)
  const [activeIndex, setActiveIndex] = useState(0)
  const { pathname } = useLocation()
  // console.log('pathname-----', pathname)
  const tellActive = useCallback(() => {
    if (pathname.includes('swap')) {
      setActiveIndex(1)
    } else if (pathname.includes('station')) {
      setActiveIndex(2)
    } else if (pathname.includes('add')) {
      setActiveIndex(0)
    } else {
      setActiveIndex(1)
    }
  }, [pathname])

  useEffect(() => {
    tellActive()
  }, [pathname, tellActive])

  const { isDark, theme } = useTheme()

  const ifInStation = useMemo(() => pathname.includes('station'), [pathname])

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <StyledPageHeaderFlex>
            {
              ifInStation && <div />
            }
            <StyledHeading><CardNav activeIndex={activeIndex} /></StyledHeading>
            <Flex justifyContent='center' flexDirection='row'>
              <IconButtonWrapper variant="text" onClick={onPresentRecentTransactions} title="History">
                <HistoryIcon color={theme.colors.text} />
              </IconButtonWrapper>
              {
                showSettings && (
                  <IconButtonWrapper variant="text" onClick={handleOpenSettings} title="Settings">
                    <CogIcon color={theme.colors.text} />
                  </IconButtonWrapper>
                )
              }
            </Flex>
          </StyledPageHeaderFlex>
          {
            showSettings && (<SettingsModal isOpen={modalOpen} onDismiss={handleDismissSettings} />)
          }



          {description && (
            <MarginTop>
              <Text color="textSubtle" fontSize="14px" textAlign="left">
                {description}
              </Text>
            </MarginTop>
          )}

        </Details>

      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
