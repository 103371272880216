const MAIN = {
  bsc: {
    "explorer": "https://bscscan.com",
    // "por": "https://api.hurricaneswap.org/subgraphs/name/por-bsc", //Lite 
    "por": 'https://api.thegraph.com/subgraphs/name/hurricaneswap/por-bsc'// full
  },
  avax: {
    "explorer": "https://snowtrace.io",
    "por": "https://api.thegraph.com/subgraphs/name/hurricaneswap/por-avax",
    "nhctHubGraph": "https://api.thegraph.com/subgraphs/name/hurricaneswap/nhct-hub",
    "nhctHubAddr": "0x1CBF069068011b114c699702485bb10b9b43f13e",
    "nhctAddr": "0x3CE2fceC09879af073B53beF5f4D04327a1bC032",
    "hctAddr": "0x45C13620B55C35A5f539d26E88247011Eb10fDbd"
  }
}
const TEST = {
  bsc: {
    "explorer": "https://testnet.bscscan.com",
    "por": "https://api.thegraph.com/subgraphs/name/hurricaneswap/por-bsc-chapel"
  },
  avax: {
    "explorer": "https://testnet.snowtrace.io",
    "por": 'https://api.thegraph.com/subgraphs/name/hurricaneswap/por-avax-fuji',
    "nhctHubGraph": "https://api.thegraph.com/subgraphs/name/hurricaneswap/nhct-hub-fuji",
    "nhctHubAddr": "0xfE3d248c782e64aa67C992194ec84E6d907DA518",
    "nhctAddr": "0x48f27465E5Dd5D308D2d8306f4B66B844b3aF56E",
    "hctAddr": "0xeddeb2ff49830f3aa30fee2f7fabc5136845304a"
  }
}


const Config = process.env.REACT_APP_ENV === 'MAIN' ? { ...MAIN } : { ...TEST }
export default Config