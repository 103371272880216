import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Flex, Text } from '@hurricaneswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import useRequest from '@ahooksjs/use-request'
import { formatEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'

import { useActiveWeb3React } from 'hooks'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useContract } from 'hooks/useContract'

import '../../css/modal.css'
import lockIcon from '../../assets/imgs/lock.png'
import useMobile from '../../hooks/useMobile'

import starIcon from '../../assets/imgs/star-icon.png'
import LOCK_ABI from '../../constants/abis/lock.json'

const InfoBlock = styled.div`
  flex-basis:50%;
  flex-grow:1;
  padding-left:24px;
  position:relative;
  font-size:20px;
  margin-bottom:30px;
  color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 

  &::before {
    content: '';
    position: absolute;
    background: url('${starIcon}') no-repeat;
    width: 16px;
    height: 16px;
    display:block;
    left:6px;
`
const InfoBlockTitle = styled.div`
color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 

`
const InfoBlockContent = styled.div`
color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 

      padding-top:10px;
`
const InfoBlockTip = styled.div`
color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 

      line-height:30px;
      margin-top:10px;
      font-size:15px;
      ol{
        list-style:decimal;
        margin-left:20px;
      }
`
const BlodText = styled.div`
      font-weight:600;
`
const CardContainer = styled.div<{ ifMobile: boolean }>`
  max-width: 710px;
  width: 100%;
  padding: ${({ ifMobile }) => (ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  // height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => (theme.colors.swapContainer)};
  // border:${({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none')}; 
  border-radius: 20px;
`
const Card = styled.div<{ ifMobile?: boolean }>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px;
  background-image: url(${({ ifMobile }) => (!ifMobile ? lockIcon : null)});
  background-size: auto;
  background-position: top right;
  background-repeat: no-repeat;
`

// lockContractAddr 地址需要修改2个地方，另外一个地方在src/pages/Lock/index.tsx 0x3E69502d0b40E473d52eE1c99D000C3Cc26Ad2a5
const lockContractAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x0b6D1C3E60722123718FaF13bFc48270BD14E9A9' : '0xdcCB85D5E187E0D393AdDdA4e4416700D756376b'

const LockInfo = () => {
  const { chainId, account } = useActiveWeb3React()

  const ifMobile = useMobile()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const titile = t('Stats')
  const lockContract = useContract(lockContractAddr, LOCK_ABI, true)

  const [unlockTime, setUnlockTime] = useState<any>(0)
  const [startLockTime, setStartLockTime] = useState<any>(0)
  const [myUnlockTime, setMyUnlockTime] = useState<any>('-')
  const [lockedAmount, setLockedAmount] = useState<any>('-')
  const [totalLocked, setTotalLocked] = useState<any>('-')



  const { run: getLockTime, data: getLockTimeRes, error: getLockTimeError } = useRequest(() => lockContract?.lockTime(), {
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const { run: getStartLockTime, data: getStartLockTimeRes, error: getStartLockTimeError } = useRequest(() => lockContract?.startLockTime(account), {
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const { run: getLockedAmount, data: getLockedAmountRes, error: getLockedAmountError, loading: getUnlockedAmountLoading }
    = useRequest(() => lockContract?.balanceOf(account), {
      manual: true,
      pollingInterval: 3000,
      pollingWhenHidden: false
    })

  const { run: getTotalLocked, data: getTotalLockedRes, error: getTotalLockedError, loading: getTotalLockedLoading }
    = useRequest(() => lockContract?.totalSupply(), {
      manual: true,
      pollingInterval: 3000,
      pollingWhenHidden: false
    })

  useEffect(() => {
    // approveHCT()
    if (chainId && account) {
      // getLockTime()
      // getStartLockTime()
      getLockedAmount()
      getTotalLocked()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])


  useEffect(() => {
    if (getStartLockTimeRes) {
      // console.log('getStartLockTimeRes---info', getStartLockTimeRes, getStartLockTimeRes.toString())
      setStartLockTime(getStartLockTimeRes.toString())
    }
  }, [getStartLockTimeRes])

  useEffect(() => {
    if (getLockTimeRes) {
      // console.log('getLockTimeRes--info', getLockTimeRes, getLockTimeRes.toString())
      setUnlockTime(getLockTimeRes.toString())
    }
  }, [getLockTimeRes])

  useEffect(() => {
    if (unlockTime > 0 && startLockTime > 0) {
      setMyUnlockTime(moment((parseInt(startLockTime) + parseInt(unlockTime)) * 1000).format('YYYY-MM-DD HH:mm'))
    } else {
      setMyUnlockTime('-')
    }
  }, [unlockTime, startLockTime])


  useEffect(() => {
    if (getLockedAmountRes) {
      // console.log('getLockedAmountRes---info', getLockedAmountRes, formatEther(getLockedAmountRes))
      setLockedAmount(formatEther(getLockedAmountRes))
    }
  }, [getLockedAmountRes])

  useEffect(() => {
    if (getTotalLockedRes) {
      // console.log('getTotalLockedRes---info', getTotalLockedRes, new BigNumber(formatEther(getTotalLockedRes)).toFormat(2))
      setTotalLocked(formatEther(getTotalLockedRes))
    }
  }, [getTotalLockedRes])

  // APR = [每日HCT分发的数量  /  sHCT总锁仓数量] * 365 * 100%
  const apr = useMemo(() => {
    if (totalLocked > 0) {
      return `${((87896 / totalLocked) * 365 * 100).toFixed(2)}%`
    }
    return '--'
  }, [totalLocked])

  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Flex alignItems={ifMobile ? 'center' : 'flex-start'} width='100%' flexDirection='column' pt='30px' pl={ifMobile ? '0' : '21px'} pr={ifMobile ? '0' : '21px'} >
          <Text mb='20px' fontSize='32px'>{titile}</Text>

          <Flex width='100%' >
            <InfoBlock>
              <InfoBlockTitle>Your Unlocking Time</InfoBlockTitle>
              <InfoBlockContent>{myUnlockTime}</InfoBlockContent>
            </InfoBlock>
            <InfoBlock>
              <InfoBlockTitle>Your Locked sHCT</InfoBlockTitle>
              <InfoBlockContent>{new BigNumber(lockedAmount).toFormat(2)} sHCT</InfoBlockContent>
            </InfoBlock>
          </Flex>
          <Flex width='100%' >
            <InfoBlock>
              <InfoBlockTitle>Total Locked</InfoBlockTitle>
              <InfoBlockContent>{new BigNumber(totalLocked).toFormat(2)} sHCT</InfoBlockContent>
            </InfoBlock>
            <InfoBlock>
              <InfoBlockTitle>APR</InfoBlockTitle>
              <InfoBlockContent>{apr}</InfoBlockContent>
            </InfoBlock>
          </Flex>
          <Flex width='100%' >
            <InfoBlock>
              <InfoBlockTitle>Note</InfoBlockTitle>
              <InfoBlockTip>
                <ol >
                  <li>Users can only unlock the funds after the locking period ends.</li>
                  <li>Only when users unlock sHCT will they receive HCT rewards.</li>
                  <li>If you have sHCT locked (even the locking period ends but you haven’t unlocked it), then when you lock sHCT again, the locking period for all sHCT will restart timing.</li>
                </ol>
              </InfoBlockTip>
            </InfoBlock>
          </Flex>
          <Flex width='100%' >
            <InfoBlock>
              <InfoBlockTitle>E.g.</InfoBlockTitle>
              <InfoBlockTip>
                <BlodText>Assumptions:</BlodText>
                <ol >
                  <li>30 days per month.</li>
                  <li>User A locked 100 sHCT on Jan 1, and the estimated unlocking time is Jul 1. </li>
                </ol>
                <BlodText>Example 1:</BlodText>
                <div>If user A locks 10 sHCT on Feb 1, then the estimated unlocking time for the 110 sHCT (100+10) will be Aug 1.</div>
                <BlodText>Example 2:</BlodText>
                <div>If user A locks 20 sHCT on Jul 2 when the locking period for the earlier 100 sHCT has ended, but user A hasn’t unlocked the 100 sHCT, then the unlocking time for the 120 sHCT (100+20) will be Jan 2 next year.</div>
              </InfoBlockTip>
            </InfoBlock>
          </Flex>
        </Flex>
      </Card>
    </CardContainer>)
}

export default LockInfo
