
export type CoinCardColor = {
  light: string;
  dark: string;
}

export interface Config {
  symbol: string;
  contract: string;
  title: string;
  currency: string;
  chainIconUrl: string;
  coinIconUrl: string;
  chain: string;
  color: CoinCardColor
  address: string;
  subtitle?: string
  displayName?: string
}

const TestTokenList: Config[] = [
  {
    title: 'hct',
    symbol: 'hct',
    contract: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    currency: 'HCTIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-hct.png',
    chain: 'avax',
    address: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.25) 7.41%, rgba(196, 130, 248, 0.49) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.125) 7.41%, rgba(196, 130, 248, 0.245) 89.26%), #23122A'
    }
  },
  {
    title: 'avax',
    symbol: 'WETH',
    contract: '0x66f05c9882535569a29ab045c2e7776266ab5606',
    currency: 'AVAXIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-avax.png',
    chain: 'avax',
    address: '0x66f05c9882535569a29ab045c2e7776266ab5606',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.07) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #2A1219'
    }
  },
  {
    title: 'usdt',
    subtitle: '.e',
    symbol: 'aUSDT',
    contract: '0x160490B1962ed6F8A01d860d62F0Cc9872b31496',
    currency: 'USDTIcon',
    chainIconUrl: '',
    coinIconUrl: '',
    chain: 'bsc',
    address: '0x160490B1962ed6F8A01d860d62F0Cc9872b31496',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.11) 7.41%, rgba(156, 237, 188, 0.46) 89.26%), #FFFFFF;',
      dark: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.055) 7.41%, rgba(156, 237, 188, 0.23) 89.26%), #122A26'
    }
  },
  {
    title: 'weth',
    symbol: 'aWETH',
    contract: '0xDF97593FeD045B02BdDa3E331e8858757a06dEE6',
    currency: 'ETHIcon',
    chainIconUrl: '',
    coinIconUrl: '',
    chain: 'bsc',
    address: '0xDF97593FeD045B02BdDa3E331e8858757a06dEE6',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(203, 213, 255, 0.23) 7.41%, rgba(130, 153, 248, 0.46) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(203, 213, 255, 0.115) 7.41%, rgba(130, 153, 248, 0.23) 89.26%), #101216'
    }
  },
  {
    title: 'cake',
    symbol: 'aCAKE',
    contract: '0xDC74035a2819AE68978583D44C429b5c47207541',
    currency: 'CAKEIcon',
    chainIconUrl: '',
    coinIconUrl: '',
    chain: 'bsc',
    address: '0xDC74035a2819AE68978583D44C429b5c47207541',
    color:
    {
      light: 'linear-gradient(152.21deg, #FFFEF9 10.62%, #FBE6BC 95.44%), #FFFFFF;',
      dark: 'linear-gradient(152.21deg, rgba(255, 254, 249, 0.5) 10.62%, rgba(251, 230, 188, 0.5) 95.44%), #211D09'
    }
  },
  {
    title: 'mdx',
    symbol: 'aMDEX',
    contract: '0x8f27366D1Cc7828AA4214543CE5Df2b359AacF36',
    currency: 'MDXIcon',
    chainIconUrl: '',
    coinIconUrl: '',
    chain: 'bsc',
    address: '0x8f27366D1Cc7828AA4214543CE5Df2b359AacF36',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(115, 194, 232, 0.12) 7.41%, rgba(114, 192, 230, 0.21) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(115, 194, 232, 0.06) 7.41%, rgba(114, 192, 230, 0.105) 89.26%), #101216;'
    }
  },

  {
    title: 'xvs',
    symbol: 'xvs',
    contract: '0x4Bc183879703dB3Ed098D74C41b5F2b851cf22d7',
    currency: 'XVSIcon',
    chainIconUrl: '',
    coinIconUrl: '',
    chain: 'bsc',
    address: '0x4Bc183879703dB3Ed098D74C41b5F2b851cf22d7',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.065) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #23122A'
    }
  },
  {
    title: 'bnb',
    symbol: 'aUSDT',
    contract: '0x31EF1216757e33490D33183b5592CCc2E4b74E01',
    currency: 'BNBIcon',
    chainIconUrl: '',
    coinIconUrl: '',
    chain: 'bsc',
    address: '0x31EF1216757e33490D33183b5592CCc2E4b74E01',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.4) 7.41%, rgba(255, 238, 198, 0.6) 89.26%), #FFFFFF;',
      dark: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.2) 7.41%, rgba(255, 238, 198, 0.3) 89.26%), #282A12;'
    }
  }

];



const Maintokenlist: Config[] = [
  {
    title: 'hct',
    symbol: 'hct',
    displayName: 'HCT',
    contract: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    currency: 'HCTIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-hct.png',
    chain: 'avax',
    address: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.25) 7.41%, rgba(196, 130, 248, 0.49) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(217, 203, 255, 0.125) 7.41%, rgba(196, 130, 248, 0.245) 89.26%), #23122A'
    }
  },
  {
    title: 'avax',
    symbol: 'avax',
    displayName: 'AVAX',
    contract: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    currency: 'AVAXIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-avax.png',
    chain: 'avax',
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.07) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #2A1219'
    }
  },

  {
    title: 'qi',
    currency: 'BENQIIcon',
    symbol: 'qi',
    displayName: 'QI',
    contract: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-qi.png',
    chain: 'avax',
    address: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
    color:
    {
      light: ' linear-gradient(148.3deg, rgba(221, 221, 221, 0.3) 7.41%, rgba(193, 193, 193, 0.3) 89.26%), #FFFFFF',
      dark: ' linear-gradient(148.3deg, rgba(221, 221, 221, 0.2) 7.41%, rgba(193, 193, 193, 0.2) 89.26%), #0C222B'
    }
  },
  {
    title: 'wbtc',
    subtitle: '.e',
    symbol: 'wbtc.e',
    displayName: 'WBTC.e',
    contract: '0x50b7545627a5162f82a992c33b87adc75187b218',
    currency: 'BTCIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-wbtc.png',
    chain: 'avax',
    address: '0x50b7545627a5162f82a992c33b87adc75187b218',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.4) 7.41%, rgba(255, 238, 198, 0.6) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 245, 221, 0.2) 7.41%, rgba(255, 238, 198, 0.3) 89.26%), #282A12'
    }
  },
  {
    title: 'aave',
    subtitle: '.e',
    symbol: 'aave.e',
    displayName: 'AAVE.e',
    contract: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    currency: 'AAVEIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-aave.png',
    chain: 'avax',
    address: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(156, 207, 224, 0.06) 7.41%, rgba(156, 207, 224, 0.41) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(156, 207, 224, 0.03) 7.41%, rgba(156, 207, 224, 0.205) 89.26%), #12292A'
    }
  },
  {
    title: 'aCAKE',
    displayName: 'aCAKE',
    symbol: 'cake',
    contract: '0x7ed05cf8f77fb75a10947e0715c0876e074ff676',
    currency: 'aCAKEIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/56/logo.svg',
    coinIconUrl: '/images/icon/home-cake.png',
    chain: 'bsc',
    address: '0x7ed05cf8f77fb75a10947e0715c0876e074ff676',
    color:
    {
      light: 'linear-gradient(152.21deg, #FFFEF9 10.62%, #FBE6BC 95.44%), #FFFFFF;',
      dark: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.055) 7.41%, rgba(156, 237, 188, 0.23) 89.26%), #122A26'
    }
  },
  // {
  //   title: 'usdt',
  //   subtitle: '.e',
  //   symbol: 'usdt.e',
  //   contract: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  //   currency: 'USDTIcon',
  //   chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
  //   coinIconUrl: '/images/icon/home-usdt.png',
  //   chain: 'avax',
  //   address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
  //   color:
  //   {
  //     light: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.11) 7.41%, rgba(156, 237, 188, 0.46) 89.26%), #FFFFFF;',
  //     dark: 'linear-gradient(148.3deg, rgba(156, 237, 188, 0.055) 7.41%, rgba(156, 237, 188, 0.23) 89.26%), #122A26'
  //   }
  // },
  {
    title: 'link',
    subtitle: '.e',
    symbol: 'link.e',
    displayName: 'LINK.e',
    contract: '0x5947BB275c521040051D82396192181b413227A3',
    currency: 'LINKIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-link.png',
    chain: 'avax',
    address: '0x5947BB275c521040051D82396192181b413227A3',
    color:
    {
      light: 'linear-gradient(165.97deg, rgba(138, 176, 255, 0.17) 6.95%, rgba(134, 172, 255, 0.29) 90%), #FFFFFF',
      dark: 'linear-gradient(165.97deg, rgba(138, 176, 255, 0.085) 6.95%, rgba(134, 172, 255, 0.145) 90%), #121A2A'
    }
  },
  {
    title: 'xava',
    symbol: 'xava',
    displayName: 'XAVA',
    contract: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
    currency: 'XAVAIcon',
    chainIconUrl: 'https://assets.hurricaneswap.com/blockchains/43114/logo.png',
    coinIconUrl: '/images/icon/home-xava.png',
    chain: 'avax',
    address: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
    color:
    {
      light: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.13) 7.41%, rgba(248, 137, 130, 0.35) 89.26%), #FFFFFF',
      dark: 'linear-gradient(148.3deg, rgba(255, 203, 203, 0.065) 7.41%, rgba(248, 137, 130, 0.175) 89.26%), #23122A'
    }
  },

];

export const helps = [
  {
    label: "Migrate liquidity from V1 to V2",
    href: "https://hurricaneswap.gitbook.io/hurricaneswap/guide-to-hurricaneswap/migrate-liquidity-from-v1-to-v2",
  },
  {
    label: "How to Add Cross-Chain Liquidity via HurricaneStation",
    href: "https://hurricaneswap.gitbook.io/hurricaneswap/guide-to-hurricaneswap/how-to-add-cross-chain-liquidity-via-hurricanestation",
  },
  {
    label: "Whitepaper",
    href: "https://hurricanesam.github.io/Docs/HurricaneSwap%20Whitepaper.pdf",
  },

];

export const messages = [
  // {
  //   label: "The Winning List of Testnet Event",
  //   href: "https://hurricaneswap.medium.com/winners-of-the-whole-hurricaneswap-testnet-reward-program-dba7f5ec9a3a",

  // },
  // {
  //   label: "HurricaneSwap Public Sale",
  //   href: "https://hurricaneswap.medium.com/hurricaneswap-public-sale-announcement-tutorial-daec21b5d1d1",

  // },
  {
    label: "HurricaneSwap: A DEX with Same Experience as CEX",
    href: "https://www.coinspeaker.com/hurricaneswap-dex-with-same-experience-cex/"
  },
  {
    label: "HurricaneSwap: How to Enhance DeFi Security?",
    href: "https://www.newsbtc.com/press-releases/hurricaneswap-how-to-enhance-defi-security/"
  },
  {
    label: "HurricaneSwap is Integrating Chainlink Price Feeds & Proof of Reserve",
    href: "https://hurricaneswap.medium.com/hurricaneswap-is-integrating-chainlink-price-feeds-to-close-cross-chain-price-gaps-proof-of-85cc721641d",

  },

];
// 测试网暂时也用主网的配置，因为首页使用的主网的graph api 
const CoinToShow = process.env.REACT_APP_ENV === 'MAIN' ? Maintokenlist : Maintokenlist

export default CoinToShow;

