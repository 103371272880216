import { ChainId } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardBody, Flex, Text, ToastContainer } from '@hurricaneswap/uikit'
import { useLocation } from 'react-router-dom'
import { JsonRpcProvider } from '@ethersproject/providers'
import useRequest from '@ahooksjs/use-request'
import styled, { ThemeContext } from 'styled-components'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { useContract, useTokenContract } from 'hooks/useContract'
import { useDerivedMintInfo } from 'state/mint/hooks'
import { WrappedTokenInfo } from 'state/lists/hooks'
import Column, { AutoColumn, ColumnCenter } from 'components/Column'
import QuestionHelper from 'components/QuestionHelper'
import { BigNumber } from 'bignumber.js'

import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { AutoRow, RowBetween } from 'components/Row'
import { ArrowWrapper, BottomGrouping, IconDecoration, Wrapper } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import Loader from 'components/Loader'
import { useActiveWeb3React } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

import { useTotalSupply } from 'data/TotalSupply'
import { useTokenBalance } from 'data/Balance'
import { ApprovalState } from 'hooks/useApproveCallback'
import { useAddPopup } from 'state/application/hooks'
import { Field } from 'state/swap/actions'
import { tryParseAmount, useSwapActionHandlers } from 'state/swap/hooks'
import PageHeader from 'components/PageHeader'
import ConnectWalletButton from 'components/ConnectWalletButton'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import { injected } from '../../connectors'
import AppBody, { BodyGradiantWrapper, FixedPosition, BodyWrapper } from '../AppBody'

import '../../css/modal.css'
import { Dots } from '../../components/swap/styleds'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'
import hurricane from '../../assets/imgs/hurricane.png'
import useMobile from '../../hooks/useMobile'
import STAKE_JSON from '../../constants/abis/stake.json'
import { TYPE } from '../../components/Shared'

const { body: Body } = TYPE

const StakeInfoTitle = styled.div`
  font-family: GT Eesti Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: ${({ theme }) => (theme.colors.contrast)};  
  margin-bottom:10px;
  
`
const StakeInfoRow = styled(RowBetween)`
  padding-bottom:8px;
`
const StakeInfoRowTitle = styled(Body)`
  color: ${({ theme }) => (theme.colors.contrast)};
`
const AddToken2Wallet = styled.div`
    text-decoration: underline;
    color:#8A7F90;
    text-align:center;
    margin-bottom:48px;
    margin-right:26px;
    z-index:100;
    margin-top:26px;
    cursor:pointer;
`
// @ts-ignore
window.JsonRpcProvider = JsonRpcProvider

const maxAllowance = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

const hctBarAddress = process.env.REACT_APP_ENV === 'MAIN' ? '0x75B797a0ed87F77bB546F3A3556F18fC35a01140' : '0xe81D3f95C887ce9a89198312774d4274EeC7E43f'

const HCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x45C13620B55C35A5f539d26E88247011Eb10fDbd' : '0xeddeb2ff49830f3aa30fee2f7fabc5136845304a'
const xHCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x75B797a0ed87F77bB546F3A3556F18fC35a01140' : '0xe81D3f95C887ce9a89198312774d4274EeC7E43f'

const _chainId = process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET

const xHCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": xHCTAddr,
  "decimals": 18,
  "name": "xHCT",
  "symbol": "xHCT",
  "logoURI": "https://i.loli.net/2021/08/29/yVGFeJkSvUaMRHP.png"
}, [])

const HCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": HCTAddr,
  "decimals": 18,
  "name": "HCT",
  "symbol": "HCT",
  "logoURI": "https://assets.hurricaneswap.com/blockchains/43114/0x45c13620b55c35a5f539d26e88247011eb10fdbd/logo.png"
}, [])

const SwapIcon = () => {
  return (<svg style={{ position: 'relative', zIndex: 1 }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F1EEE6" />
    <path d="M20 13V27" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27 20L20 27L13 20" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

  )
}
const addXhct = () => {
  injected.getProvider().then(provider => {
    if (provider) {
      provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: xHCT?.address,
            symbol: xHCT?.symbol,
            decimals: xHCT?.decimals,
            image: 'https://i.loli.net/2021/10/14/lLkUNDhe436YM7t.png',
          },
        },
      }).catch((error: any) => {
        console.error(error)
      })
    }
  });
}



export default function Stake({ history }) {
  const { chainId, account } = useActiveWeb3React()
  const { search, pathname } = useLocation()
  const [ifSelectChainOpen, setSelectChain] = useState(true)
  const onlyOnAvax = useOnlyOnAvax()
  const login = useSwitchChain()
  const hctBarContract = useContract(hctBarAddress, STAKE_JSON, true)
  const xHCTTotalSupply = useTotalSupply(xHCT)
  const balance = useTokenBalance(HCT, [hctBarAddress])
  const HCTContract = useTokenContract(HCT?.address, true)
  const XHCTContract = useTokenContract(xHCT?.address, true)
  const theme = useContext(ThemeContext)
  const addTransaction = useTransactionAdder()


  const [rewards, setRewards] = useState('-')
  const [totalStake, setTotalStake] = useState('-')
  const [userStakeAmount, setUserStakeAmount] = useState('-')

  const { token0, token1, addr1, addr2, ButtonText, amountInContract, status } = useMemo(() => {
    if (pathname.includes('unstake')) {
      return {
        token0: xHCT,
        token1: HCT,
        addr1: xHCTAddr,
        addr2: HCTAddr,
        ButtonText: 'Unstake',
        amountInContract: XHCTContract,
        status: 'leave'
      }
    }
    return {
      token0: HCT,
      token1: xHCT,
      addr1: HCTAddr,
      addr2: xHCTAddr,
      ButtonText: 'Stake',
      amountInContract: HCTContract,
      status: 'enter'
    }
  }, [pathname, HCTContract, XHCTContract])

  const { run: getHCTAllowance, data: HCTAllowanceData, error: HCTAllowanceError, loading: HCTAllowanceLoading }
    = useRequest(() => amountInContract?.allowance(account, hctBarAddress), { manual: true })

  const { run: approveHCT, data: approveHCTData, error: approveHCTEror, loading: HCTApproveLoading }
    = useRequest(() => amountInContract?.approve(hctBarAddress, maxAllowance), { manual: true })
  const [approval, setApproval] = useState(0)

  const { run: getTotalSupply, data: totalSupplyData, error: getTotalSupplyError } = useRequest(() => XHCTContract?.totalSupply(), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const { run: getBalance, data: getBalanceRes, error: getUserStakeError } = useRequest(() => XHCTContract?.balanceOf(account), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })
  useEffect(() => {
    // approveHCT()
    if (chainId && account) {
      setApproval(ApprovalState.UNKNOWN)
      getHCTAllowance()
      getTotalSupply()
      getBalance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])



  useEffect(() => {
    if (totalSupplyData) {
      // console.log('totalStakeData', totalSupplyData)
      setTotalStake((new BigNumber(totalSupplyData.toString()).div(10 ** xHCT.decimals)).toFormat(2))
    }
  }, [totalSupplyData])

  useEffect(() => {
    if (getBalanceRes) {
      // console.log('getBalanceRes', getBalanceRes)
      setUserStakeAmount((new BigNumber(getBalanceRes.toString()).div(10 ** xHCT.decimals)).toFormat(2))
    }
  }, [getBalanceRes])

  useEffect(() => {
    if (HCTApproveLoading) {
      setApproval(ApprovalState.PENDING)
    }
  }, [HCTApproveLoading])

  useEffect(() => {
    if (approveHCTData) {
      // todo 判断 approveHCTData?.hash===1 才成功
      approveHCTData.wait().then(res => {
        getHCTAllowance()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveHCTData])

  useEffect(() => {
    if (approveHCTEror) {
      setApproval(ApprovalState.NOT_APPROVED)
    }
  }, [approveHCTEror])


  useEffect(() => {
    if (HCTAllowanceLoading) {
      setApproval(ApprovalState.PENDING)
    }
  }, [HCTAllowanceLoading])

  useEffect(() => {
    if (HCTAllowanceData) {
      if (HCTAllowanceData.toString() > 2000000) {
        setApproval(ApprovalState.APPROVED)
      } else {
        setApproval(ApprovalState.NOT_APPROVED)
      }
    }
  }, [HCTAllowanceData])


  /**
   * 获取比例
   * xHCTTotalSupply / bar里面的HCT
   */
  const tokenProportion = useMemo(() => {
    if (xHCTTotalSupply && balance && Number(xHCTTotalSupply?.toSignificant()) > 0 && Number(balance?.toSignificant()) > 0) {
      return Number(xHCTTotalSupply?.toSignificant()) / Number(balance?.toSignificant())
    }
    return 0
  }, [xHCTTotalSupply, balance])

  const { currencyBalances } = useDerivedMintInfo(token0, token1)

  const [inputAmount, setInputAmount] = useState({
    [Field.INPUT]: '',
    [Field.OUTPUT]: ''
  })
  const maxAmountInput: any = maxAmountSpend(currencyBalances.CURRENCY_A)
  // const { onCurrencySelection, onUserInput } = useSwapActionHandlers()

  const error = useMemo(() => {
    if (!account) {
      return 'Connect Wallet'
    }
    if (!inputAmount.INPUT) {
      return 'Enter an amount'
    }
    if (+inputAmount.INPUT === 0) {
      return 'Enter an amount'
    }
    if (!currencyBalances || (currencyBalances && new BigNumber(currencyBalances?.CURRENCY_A?.toExact()).lte(0))) {
      return 'Insufficient balance'
    }
    if (currencyBalances && (new BigNumber(inputAmount.INPUT).gt(currencyBalances?.CURRENCY_A?.toExact()))) {
      return 'Insufficient balance'
    }
    return null
  }, [inputAmount.INPUT, account, currencyBalances])

  const handleTypeInput = useCallback(
    (value: string) => {
      setInputAmount(old => ({
        [Field.INPUT]: value,
        [Field.OUTPUT]: +value > 0 ? (status === 'enter' ? +value * tokenProportion : +value / tokenProportion).toString() : ''
      }))
      // onUserInput(Field.INPUT, value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenProportion, tokenProportion])

  const handleTypeOutput = useCallback(
    (value: string) => {
      setInputAmount(old => ({
        [Field.INPUT]: +value > 0 ? (status === 'enter' ? +value / tokenProportion : +value * tokenProportion).toString() : '',
        [Field.OUTPUT]: value
      }))
      // onUserInput(Field.OUTPUT, value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenProportion, tokenProportion])

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      handleTypeInput(maxAmountInput.toExact())
    }
  }, [maxAmountInput, handleTypeInput])

  const addPopup = useAddPopup()


  const handleStake = async () => {
    if (!hctBarContract) return
    if (!inputAmount.INPUT) return
    if (+inputAmount.INPUT === 0) return

    const amountIn = tryParseAmount(inputAmount.INPUT, token0)
    const amountOut = tryParseAmount(inputAmount.OUTPUT, token1)

    try {
      const res = await hctBarContract[status](amountIn?.raw.toString())
      // console.log('res', res)
      let action = 'Stake'
      if (status !== 'enter') {
        action = 'Unstake'
      }
      const tip = `${action}  ${amountIn.toExact()} ${token0?.symbol} to  ${amountOut.toExact()} ${token1?.symbol}`
      addTransaction(res, {
        summary: tip
      })
      onOpen()
      setSwapState(old => ({
        ...old,
        txHash: res?.hash,
        // attemptingTxn: true,
        // pendingText: `${ status === 'enter' ? 'Staking' : 'Unstaking'} ${amountIn?.toSignificant(6)} HCT`
      }))

      const _ = await res.wait()
      if (_.status === 1) {
        // console.log('__', _)
        // setSwapState(old => ({
        //     ...old,
        //     attemptingTxn: false,
        //     txHash: _?.transactionHash,
        // }))

        // addPopup(
        //   {
        //     txn: {
        //       hash: _?.transactionHash,
        //       success: _.status === 1
        //     },
        //   }, _.hash)
      }
    } catch (e: any) {
      setSwapState(old => ({
        attemptingTxn: false,
        pendingText: '',
        txHash: undefined
      }))
      // todo 报错弹窗
      const errorToast = {
        id: `id-${e?.data?.code}`,
        title: `Error`,
        description: <Text>{e?.message || e?.data?.message}</Text>,
        type: 'danger',
      };

      setToasts([errorToast])
    }
  }

  useEffect(() => {
    if (chainId !== 43113 && chainId !== 43114) {
      login(process.env.REACT_APP_ENV === 'MAIN' ? 43114 : 43113)
    }
  }, [login, chainId, account])

  // modal and loading
  const [{ attemptingTxn, txHash, pendingText }, setSwapState] = useState({
    attemptingTxn: false,
    pendingText: '',
    txHash: undefined
  })

  const [isOpen, setIsOpen] = useState(false)
  const onDismiss = () => {
    setIsOpen(false)
    setSwapState(old => ({
      attemptingTxn: false,
      pendingText: '',
      txHash: undefined
    }))
  }
  const onOpen = () => {
    setIsOpen(true)
  }

  const [toasts, setToasts]: any = useState([]);
  const onRemove = () => { setToasts([]) }

  return (
    <>
      <AppBody>
        <Wrapper id="stake-page">
          <TransactionConfirmationModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            pendingText={pendingText}
            content={() => null}
          />
          <PageHeader title="Stake" showSettings={false} />
          <CardBody p='24px 24px 48px'>

            <AutoColumn gap="md">
              <CurrencyInputPanel
                portalId="stake-page"
                dir="From"
                disableCurrencySelect={Boolean(true)}
                value={inputAmount[Field.INPUT]}
                showMaxButton
                onMax={handleMaxInput}
                currency={token0}
                onUserInput={handleTypeInput}
                id="bridge-currency-input"
              />

              <AutoColumn justify="space-between">
                <AutoRow justify='flex-end'>
                  {/* eslint-disable-next-line */}
                  <IconDecoration />

                  <ArrowWrapper clickable={false}>
                    <SwapIcon />
                  </ArrowWrapper>
                </AutoRow>
              </AutoColumn>

              <CurrencyInputPanel
                defaultBalanceValue=' '
                portalId="stake-page"
                disableCurrencySelect={Boolean(true)}
                value={inputAmount[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                dir="To"
                showMaxButton={false}
                currency={token1}
                id="bridge-currency-output"
              />
              <StakeInfoRow style={{ paddingTop: "10px" }}>
                <StakeInfoRowTitle>Price</StakeInfoRowTitle>
                <Body style={{ color: "rgba(100, 37, 197, 1)" }}>
                  1 xHCT≈ {1 / tokenProportion} HCT
                </Body>
              </StakeInfoRow>
            </AutoColumn>
            <BottomGrouping>
              {
                !account ? (
                  <ConnectWalletButton />
                ) : (
                  onlyOnAvax
                    ? (
                      <Button id="switch-network-button" onClick={login} style={{ width: '100%' }}>
                        <TranslatedText translationId={100}>Switch Network</TranslatedText>
                      </Button>)
                    : (
                      <Column>
                        {HCTAllowanceData && !HCTAllowanceLoading && approval !== ApprovalState.APPROVED && <Button
                          mb='16px'
                          onClick={approveHCT}
                          disabled={approval !== ApprovalState.NOT_APPROVED}
                          style={{ width: '100%' }}
                          variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}

                        >

                          {approval === ApprovalState.PENDING ? (
                            <AutoRow gap="6px" justify="center">
                              <Dots>Approving</Dots>
                            </AutoRow>
                          ) : approval === ApprovalState.APPROVED ? (
                            'Approved'
                          ) : (
                            `Approve ${token0?.symbol}`
                          )}
                        </Button>}
                        <Button
                          onClick={handleStake}
                          id="stake-button"
                          disabled={!!error || approval !== ApprovalState.APPROVED}
                          variant={
                            error || approval !== ApprovalState.APPROVED
                              ? 'danger'
                              : 'tertiary'
                          }
                          width='100%'
                        >
                          {
                            (HCTAllowanceLoading || !currencyBalances || !currencyBalances?.CURRENCY_A?.raw.toString())
                              ? <Dots>Loading</Dots>
                              : (
                                <TranslatedText translationId={100}>
                                  {error ?? ButtonText}
                                </TranslatedText>
                              )
                          }
                        </Button>
                      </Column>
                    )
                )
              }
              {/* {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />} */}
              {/* {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
            </BottomGrouping>
          </CardBody>
        </Wrapper>

      </AppBody>

      <ToastContainer toasts={toasts} onRemove={onRemove} />
      <BodyGradiantWrapper style={{ marginTop: "16px" }}>
        <FixedPosition>
          <BodyWrapper style={{ padding: "24px" }}>
            <StakeInfoTitle>Your staked</StakeInfoTitle>
            <StakeInfoRow>
              <StakeInfoRowTitle>Total staked in pool:</StakeInfoRowTitle>
              <Body>{totalStake} HCT</Body>
            </StakeInfoRow>
            <StakeInfoRow>
              <StakeInfoRowTitle>Your staked:</StakeInfoRowTitle>
              <Body>{userStakeAmount} HCT</Body>
            </StakeInfoRow>
          </BodyWrapper>
        </FixedPosition>
      </BodyGradiantWrapper>
      <AddToken2Wallet onClick={addXhct}>Add xHCT to Wallet</AddToken2Wallet>
    </>
  )
}
