import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AddLiquidity from './index'
import { AppState } from '../../state'

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40})-(0x[a-fA-F0-9]{40})$/
export function RedirectStationOldAddLiquidityPathStructure(props: RouteComponentProps<{ currencyIdA: string }>) {
  const {
    match: {
      params: { currencyIdA },
    },
  } = props
  const match = currencyIdA.match(OLD_PATH_STRUCTURE)
  const {stationChainId} = useSelector<AppState, AppState['station']>((state) => state.station)

  if (match?.length) {
    return <Redirect to={`/stationadd/${stationChainId}/${match[1]}/${match[2]}`} />
  }

  return <AddLiquidity {...props} />
}

export function RedirectStationDuplicateTokenIds(props: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const {
    match: {
      params: { currencyIdA, currencyIdB },
    },
  } = props
  const {stationChainId} = useSelector<AppState, AppState['station']>((state) => state.station)

  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/stationadd/${stationChainId}/${currencyIdA}`} />
  }
  return <AddLiquidity {...props} />
}
