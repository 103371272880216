import { TokenAmount, Pair, Currency, Token, ETHER, AVAX, BNB } from '@glhf-libs/sdk'
import { useMemo, useRef } from 'react'
import { ethers } from 'ethers'
import { getContract } from 'utils'
import { baseRpc } from 'constants/index'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'

const PAIR_INTERFACE = new Interface(IUniswapV2PairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [tokens]
  )
  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}


export function useCrossChainPairs(currencies: [Currency | undefined, Currency | undefined][], crossChain: number | boolean | undefined = undefined): [PairState, Pair | null][] {
  const { chainId, library } = useActiveWeb3React()
  const provider = new ethers.providers.JsonRpcProvider(baseRpc)

  const tokens = useMemo(
    () => {
      return currencies.map(([currencyA, currencyB]) => {
        return (
          [
            wrappedCurrency(currencyA, chainId),
            wrappedCurrency(currencyB, chainId)
          ]
        )
      })
    },
    [chainId, currencies]
  )

  const crossChainTokens = useMemo(() => {
    if (currencies) {
      return currencies.map(([currencyA, currencyB]) => {
        if (crossChain && currencyA && currencyB) {
          const _A = currencyA === ETHER ? wrappedCurrency(currencyA, chainId) : currencyA
          const _B = currencyB === ETHER ? wrappedCurrency(currencyB, chainId) : currencyB

          // @ts-ignore
          const tokenA = new Token(+crossChain, _A?.wrappedFrom[crossChain], _A?.decimals, _A?.symbol, _A?.name)
          // @ts-ignore
          const tokenB = new Token(+crossChain, _B?.wrappedFrom[crossChain], _B?.decimals, _B?.symbol, _B?.name)
          return (
            [
              wrappedCurrency(tokenA, +crossChain),
              wrappedCurrency(tokenB, +crossChain)
            ]
          )
        }
        return [undefined, undefined]
      }).filter(i => i)
    }
    return [undefined, undefined]

  }, [crossChain, currencies, chainId])

  const crossChainPairAddresses = useMemo(
    () => {
      if (crossChainTokens && crossChainTokens.length > 0) {
        return crossChainTokens.map(([tokenA, tokenB]) => {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
        })
      }
      return null
    },
    [crossChainTokens]
  )

  const pairBalance = useRef([{
    error: false,
    loading: true,
    result: undefined,
    syncing: true,
    valid: true
  }])
  const results = useMemo(() => {
    if (crossChainPairAddresses && crossChainPairAddresses.length > 0 && crossChainPairAddresses[0]) {
      const contract = getContract(crossChainPairAddresses[0], PAIR_INTERFACE, library, undefined)
      contract.connect(provider).getReserves().then((_res) => {
        pairBalance.current = [{
          error: false,
          loading: false,
          result: _res,
          syncing: false,
          valid: true
        }]
      }).catch(err => {
        // console.log('err', err)
        pairBalance.current = [{
          error: true,
          loading: false,
          result: undefined,
          syncing: false,
          valid: false
        }]
      })
    }
    return pairBalance.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crossChainPairAddresses])

  // const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results && results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]
      const wrappedTokenA = crossChainTokens[i][0]
      const wrappedTokenB = crossChainTokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = wrappedTokenA.sortsBefore(wrappedTokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens, crossChainTokens])
}

export function useCrossChainPair(tokenA?: Currency, tokenB?: Currency, crossChain: number | boolean | undefined = undefined): [PairState, Pair | null] {
  return useCrossChainPairs([[tokenA, tokenB]], crossChain)[0]
}

export function useStationPairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveWeb3React()
  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const pairAddresses = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
      }),
    [tokens]
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves = {
        reserve0: BigNumber.from(0),
        reserve1: BigNumber.from(0)
      }, loading }: any = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]

      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.symbol ==='USDT' ? [tokenB, tokenA] : [tokenA, tokenB]

      if (loading) return [PairState.LOADING,
      new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
      // if (!reserves) return [PairState.NOT_EXISTS, null]
      return [
        PairState.EXISTS,
        new Pair(new TokenAmount(token0, reserve0.toString()), new TokenAmount(token1, reserve1.toString()))
      ]
    })
  }, [results, tokens])
}