import { Trade, ChainId } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardBody, Flex, Text, ToastContainer, Skeleton } from '@hurricaneswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import useRequest from '@ahooksjs/use-request'
import { parseEther, formatEther } from '@ethersproject/units'

import { useTranslation } from 'react-i18next'
import { useContract } from 'hooks/useContract'

import axios from "axios"
import { WrappedTokenInfo } from 'state/lists/hooks'
import { TYPE } from 'components/Shared'
import '../../css/modal.css'
import { useTokenBalance } from 'data/Balance'
import hurricane from '../../assets/imgs/hurricane.png'
import useMobile from '../../hooks/useMobile'

import xHct_ABI from '../../constants/abis/stake.json'
import sHct_ABI from '../../constants/abis/stake-v2.json'

const Star = styled.div`
    &::before {
        content: '';
        position: absolute;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgBjZDBDcIwDEVtN+XCodmAIIHEsaMwQiUYhFHYBCSQuDJC2CAXDrTUxlU5OlJ8iWLn5ft/AKO6cPGQKbKazrk2B5mAiPglNk0xgEjhU73XxYBqeBYK1sSZzxEDgSRTfTJX14swwtiSYNB1VgKyR8AJuIrwSz09iao4DH3UlbyaZI8M84+iDUA95jsrKAT/xDJpH7b383FzOxV7AJbEQLEYQMSIwCZgxsrISc2nYgVNKw7fPkJpdbtHyM1+MbVFVwJHUAQAAAAASUVORK5CYII=') no-repeat;
        width: 16px;
        height: 16px;
        display:block;
        left:36px;

`

const UlGrid = styled.ul`
  display: grid;
  margin-top:30px;
  grid-template-columns: repeat(2, 50%);
  justify-items: start;
  align-items: center;
  border-top: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }}; 
  li{
    border-left: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    width: 100%;
    // height: 100%;
    padding: 10px 15px;
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    &:nth-of-type(2n-1){
      padding-left: 0;
      padding-right: 0;
      border-left: none;
    }
  }
`

const CardContainer = styled.div<{ ifMobile: boolean }>`
  max-width: 710px;
  width: 100%;
  padding: ${({ ifMobile }) => (ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  // height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => (theme.colors.swapContainer)};
  // border:${({ theme }) => (theme.isDark ? '1px solid #ffffff47' : 'none')}; 
  border-radius: 20px;
`
const Card = styled.div<{ ifMobile?: boolean }>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px;
  background-image: url(${({ ifMobile }) => (!ifMobile ? hurricane : null)});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
`
const Circle = styled.div`
  max-width: 332px;
  max-height: 332px;
  position: relative;
  ::after{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid black;  

  }
`

const { main: Main } = TYPE


const Fixed = styled.section`
  position: fixed;
  top: var(--modalTop);
  right: var(--modalRight);
`

const ZIndex = styled.section`
  position: relative;
  z-index: 0;
`


const hctBarAddress = '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'

const HCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x45C13620B55C35A5f539d26E88247011Eb10fDbd' : '0xeddeb2ff49830f3aa30fee2f7fabc5136845304a'
const xHCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x75B797a0ed87F77bB546F3A3556F18fC35a01140' : '0xe81D3f95C887ce9a89198312774d4274EeC7E43f'
const sHCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0xE4aE2E8648B8E84c4A487a559b04e884B822a350' : '0xF7d12EA4fdA47759d2138066A9AbDE6aF3a447e7'

const _chainId = process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET

const xHCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": xHCTAddr,
  "decimals": 18,
  "name": "xHCT",
  "symbol": "xHCT",
  "logoURI": "https://i.loli.net/2021/10/14/lLkUNDhe436YM7t.png"
}, [])

const HCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": HCTAddr,
  "decimals": 18,
  "name": "HCT",
  "symbol": "HCT",
  "logoURI": "https://i.loli.net/2021/08/29/yVGFeJkSvUaMRHP.png"
}, [])


const numberWithCommas = (x: any) => {
  return (typeof x === "string") ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
}
const StakeInfo = () => {
  const ifMobile = useMobile()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const titile = t('Stake HCT')
  const des1 = t('Currently, for every trade on HurricaneSwap, a 0.1% fee is collected to repurchase HCT & WAVAX and distribute to users who staked HCT on "Stake". The repurchase & distribution will happen every 2 days.')
  const des2 = t('After you stake HCT, you will receive the xHCT (choose HCT) or sHCT (choose WAVAX) and can deposit in "Earn-Pool" to receive the HCT yield farming reward.')
  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Flex>
          <Flex alignItems={ifMobile ? 'center' : 'flex-start'} flexDirection='column' pt='30px' pl='21px' pr='21px' style={{ boxSizing: 'content-box' }}>
            <Text mb='20px' fontSize='32px'>{titile}</Text>

            <Star />
            {/* @ts-ignore */}
            <Text mb='10px' lineHeight='24px' color={() => theme.isDark ? '#e4e2e7' : 'rgba(77, 60, 85, 0.65);'} style={{ 'textIndent': '2em' }}>{des1}</Text>

            <Star />
            {/* @ts-ignore */}
            <Text mb='25px' lineHeight='30px' color={() => theme.isDark ? '#e4e2e7' : 'rgba(77, 60, 85, 0.65);'} style={{ 'textIndent': '2em' }}>{des2}</Text>

          </Flex>
        </Flex>
      </Card>
    </CardContainer>

  )
}

// 数据要从外层接！！！！
const StakeData = () => {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const [totalStaked, setTotalStaked] = useState('')
  const [totalSHCT, setTotalSHCT] = useState('')
  const [totalXHCT, setTotalXHCT] = useState('')
  const xhctContract = useContract(xHCTAddr, xHct_ABI, true)
  const shctContract = useContract(sHCTAddr, sHct_ABI, true)

  // const xhctTotalSupply = xhctContract.totalSupply()
  const { run: getxHCTTotalSupply, data: xhctTotalSupply, error: xhctTotalSupplyError }
    = useRequest(() => xhctContract?.totalSupply(), { manual: true })
  const { run: getsHCTTotalSupply, data: shctTotalSupply, error: shctTotalSupplyError }
    = useRequest(() => shctContract?.totalSupply(), { manual: true })

  useEffect(() => {
    getxHCTTotalSupply()
  }, [getxHCTTotalSupply])
  useEffect(() => {
    getsHCTTotalSupply()
  }, [getsHCTTotalSupply])

  useEffect(() => {
    if (shctTotalSupply) {
      setTotalSHCT(formatEther(shctTotalSupply).toString())
    }
  }, [shctTotalSupply])
  useEffect(() => {
    if (xhctTotalSupply) {
      setTotalXHCT(formatEther(xhctTotalSupply).toString())
    }
  }, [xhctTotalSupply])

  const [fees, setFees] = useState(0)
  const stakeValue = useTokenBalance(HCT, [hctBarAddress])?.toSignificant() || 0
  //  console.log(stakeValue,'22323',useTokenBalance(HCT, [hctBarAddress]))
  //   const averageApr = 6.12
  const [apr, setApr] = useState(0)
  const [hct, setHct] = useState(0)
  const Fees = async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      // 之后换成每天的   
      query: `{
            pancakeDayDatas(first:1,orderBy:id,orderDirection:desc) {
              id
              dailyVolumeUSD
            }
          }`,
      variables: null
    });

    if (res?.data?.data?.pancakeDayDatas) {
      const result = res?.data?.data?.pancakeDayDatas[0]?.dailyVolumeUSD
      //        console.log(result,'2222')
      setFees(result)
    }
  };
  useEffect(() => {

    Fees()
  }, [])



  const hctPrice = async () => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange-v2", {
      // 之后换成每天的   
      query: `{
                tokens(where:{
                  id:"0x45c13620b55c35a5f539d26e88247011eb10fdbd"
                }){
                  id
                  symbol
                  derivedUSD
                 
                }
              
              }`,
      variables: null
    });
    if (res?.data?.data?.tokens) {

      const result = res?.data?.data?.tokens[0]?.derivedUSD
      // console.log(res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'],'2222')
      setHct(result)
    }
  };

  const getApr = async (thefees, HctPrice) => {
    // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
    const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/bar", {
      // 之后换成每天的   
      query: `{
                  bars(first: 5) {
                    id
                    totalSupply
                    ratio
                  }
                }`,
      variables: null
    });
    //   console.log(res,'1211111')
    if (res?.data?.data?.bars) {
      const totalSupply = res?.data?.data?.bars[0]?.totalSupply
      const ratio = res?.data?.data?.bars[0]?.ratio
      const result = (365 * Number(thefees) * 0.001) / Number(totalSupply) / (Number(ratio) * Number(HctPrice))
      // console.log((365 * Number(thefees) * 0.001)/ Number(totalSupply) / (Number(ratio) * 1),1,0.05,totalSupply,ratio,HctPrice)
      // console.log(res?.data?.data?.pancakeFactories[0]['totalVolumeUSD'],'2222')
      if (!Number.isNaN(result)) {
        setApr(result * 50) // result * 100 -> result * 50  shct暂时没加进来，apr直接5折显示
      }
    }
  };


  useEffect(() => {

    Fees()
    hctPrice()
    getApr(fees, hct)

  }, [hct, fees])

  return (
    <UlGrid theme={theme}>
      <li>
        <Text mb='4px' fontSize='12px' color={theme.colors.text}>{t("Total Staked in xHCT")}</Text>
        {stakeValue === 0 ?
          <Skeleton animation="pulse" variant="rect" />
          :
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{`${numberWithCommas(Number(totalXHCT).toFixed(2)).toLocaleString()}`}  HCT</Text>
        }

      </li>
      <li>
        <Text mb='4px' fontSize='12px' color={theme.colors.text}>{t("Volume(24h)")}</Text>
        {
          fees === 0 ?
            <Skeleton animation="pulse" variant="rect" />
            : <Text style={{ wordBreak: 'break-all' }}
              fontSize='16px'>{`$ ${numberWithCommas(Number(fees).toFixed(2)).toLocaleString()}`}</Text>
        }
      </li>
      <li>
        <Text mb='4px' fontSize='12px' color={theme.colors.text}>{t("Total Staked in sHCT")}</Text>
        {/*   <Text style={{ wordBreak: 'break-all' }}
           fontSize='16px'>{`${ numberWithCommas(apr) || '--'} `} %</Text> */}
        {apr === 0 ?
          <Skeleton animation="pulse" variant="rect" /> :
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{`${numberWithCommas(Number(totalSHCT).toFixed(2)).toLocaleString()}`} HCT</Text>
        }
      </li>
      <li>
        <Text mb='4px' fontSize='12px' color={theme.colors.text}>HCT {t("Price")}</Text>
        {
          hct === 0 ?
            <Skeleton animation="pulse" variant="rect" />
            : <Text style={{ wordBreak: 'break-all' }}
              fontSize='16px'>{`$ ${numberWithCommas(Number(hct).toFixed(3))}`}</Text>
        }
      </li>

    </UlGrid>)

}



const StakeDec = ({ history }) => {
  // console.log('stake !!!')
  return (

    <Flex flexDirection='column'>
      <StakeInfo />
      <StakeData />
    </Flex>
  )


}

export default StakeDec