export default [
  {
    lpSymbol: 'CAKE/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/cake-usdt.png',
    lpAddresses: {
      43114: '0xa5c7bf4d872f24f89120afeac969349a886c726f',
      56: '0x5d6d05765d60045667f59861a6E9cf65952f63a8'
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'CAKE',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x7ed05cf8f77fb75a10947e0715c0876e074ff676/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    },
  },
  {
    lpSymbol: 'XVS/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/xvs-usdt.png',
    lpAddresses: {
      43114: '0x03706Cc5777082EDF48c59F2F02519e1Fc0aF273',
      56: '0xc17DfB3045B51196DC6d8c6B996c3d45bd5dDb2c'
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'XVS',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x447277000223f029ce8752cd2b06255fc1e29107/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    },
  },
  {
    lpSymbol: 'MBOX/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/mbox-usdt.png',
    lpAddresses: {
      43114: '0x633883ac68412f9456a8f017d05e9030ddba96e4',
      56: '0x58cb5b976cb95dd2ad1b3cefabb78a81375f2c50'
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'MBOX',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x0cd70a6aa778c98f305112e9002dd6c3361c5920/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    },
  },
  {
    lpSymbol: 'ALPACA/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/alpaca-usdt.png',
    lpAddresses: {
      43114: '0x794420f75ddb2c780659b575724d47f90b788687',
      56: '0x4a8f3e4a0ed8084373f945aa12f15c199ba2368b'
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'ALPACA',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x2441421119da997ecaf08337846f8feda0f1ed87/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    },
  },
  {
    lpSymbol: 'ADA/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/ada-usdt.png',
    lpAddresses: {
      43114: '0x662e5f532956880f9fccd5ab39f879e9c0f4a203',
      56: '0xaaa2c72d8701377145628553a66b02a2bcd99795'
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'ADA',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x6cf0052988a0dc94eda667851cacef70f2812ce6/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    }
  },
  {
    lpSymbol: 'DOT/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/dot-usdt.png',
    lpAddresses: {
      43114: '0xff76e179ed4ab2cfa4bc9a17c3201921a575064e', // bHc-DOT/USDT
      56: '0x17ffde46e7a3fa8edd41723ab1fc8eb554179890' // bsc DOT/USDT
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'DOT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x63bc41817ef8e111b4a3ed97abce64f8f8fc7fef/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    }
  },
  {
    lpSymbol: 'ONE/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/one-usdt.png',
    lpAddresses: {
      43114: '0x929572a42caea7992ebc444e30172f6c3f1be241', // bHc-ONE/USDT
      56: '0xc60a79b38f3a064a936f95bf35eed90f83a2a39c' // bsc ONE/USDT
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'ONE',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xfcbad95e80a5b2df70e319013167d98ea9929745/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    }
  },
  {
    lpSymbol: 'MATIC/USDT',
    lpLogo: 'https://assets.hurricaneswap.com/blockchains/Lp-token/matic-usdt.png',
    lpAddresses: {
      43114: '0x6ed441435e87c4b83ca2e99ff0f4e49f40983748', // bHc-MATIC/USDT
      56: '0x158c3e8F162423CA124ff283f5C5EBb87EeDf38b' // bsc MATIC/USDT
    },
    prefix: {
      43114: 'bHc-',
      56: ''
    },
    from: 56,
    token: {
      symbol: 'MATIC',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x9c9d7b3d0b21c4c31dbc68477de2ddf633b71565/logo.png"
    },
    quoteToken: {
      symbol: 'USDT',
      logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png"
    }
  }
]


