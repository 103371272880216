// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Text } from '@hurricaneswap/uikit'
import chainlink from './investor/chainlink.svg'
import peckshield from './investor/peckshield.svg'
import wanchain from './investor/wanchain.svg'
import huobiVentures from './investor/huobiventures.svg'
import mexc from './investor/mexc.svg'
import zb from './investor/zb.svg'
import duck from './investor/duckstarter.svg'
import westarter from './investor/westarter.svg'
import kucoin from './investor/kucoin.svg'
import gate from './investor/gate.svg'
import kine from './investor/kine.svg'
import ld from './investor/ld.svg'
import aTCapital from './investor/capital.svg'
import redLine from './investor/redline.svg'
import spark from './investor/spark.svg'
import jrr from './investor/jrr.svg'
import kernel from './investor/kernel.svg'
import hive from './investor/bive.svg'
import daoWell from './investor/dao.svg'
import limeStone from './investor/limestone.svg'
import winkrypto from './investor/winerypto.svg'
import clockCapital from './investor/7ClockCapital.svg'
import avalaunche from './investor/avalaunche.svg'
import avatar from './investor/avatar.svg'
// import avalaunche2 from './investor/avalaunche-2.svg'
import benqi from './investor/benqi.svg'
import avalaunch from './investor/avalaunch.svg'
import cmc from './investor/cmc.svg'
import blockBeats from './investor/blockBeats.svg'
import chainNews from './investor/chainNews.svg'
import duckDao from './investor/duckDao.svg'
import mirana from './investor/mirana.svg'
import primeblock from './investor/primeblock.svg'
import duckstart from './investor/duckstart.svg'
import useMobile from '../../hooks/useMobile'


const Title = styled(Text)`
  font-family: GTTextBold;
  font-size: 2.5em;
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text};
`
export const Image = styled.img`
    display: inline-block;
    width: 100%;
    object-fit: contain;
`

const Link = styled.a`
    :hover {
        color:#6425C5;
        text-decoration:underline
    }
`



/* const ./investor = [
    {
        title: 'Partners',
        children: [
            {
                src: chainlink,
                url: 'https://chain.link/',
            },
            {
                src: peckshield,
                url: 'https://peckshield.cn/en',
            },
            {
                src: wanchain,
                url: 'https://www.wanchain.org/',
            }
        ]
    },
    {
        title: 'Exchanges',
        children: [
            {
                src: huobiVentures,
                url: 'https://www.huobi.com/',
            },
            {
                src: mexc,
                url: 'https://www.mexc.com/',
            },
            {
                src: zb,
                url: 'https://www.zb.com/',
            },
            {
                src: duck,
                url: 'https://duckstarter.io/',
            },
            {
                src: westarter,
                url: 'https://www.westarter.org/',
            },
            {
                src: kucoin,
                url: 'https://www.kucoin.com/',
            },
            {
                src: gate,
                url: 'https://www.gate.io/',
            },
            {
                src: kine,
                url: 'https://kine.io/',
            }
        ]
    },
    {
        title: 'Funds',
        children: [
            {
                src: ld,
                url: 'https://ldcap.com/',
            },
            {
                src: aTCapital,
                url: 'https://capitalant.com/',
            },
            {
                src: redLine,
                url: 'http://www.honglianziben.com',
            },
            {
                src: spark,
                url: 'https://sparkdigitalcapital.com/',
            },
            {
                src: jrr,
                url: 'https://jrr.group/',
            },
            {
                src: kernel,
                url: 'https://www.kernel-ventures.com/',
            },
            {
                src: hive,
                url: 'https://kine.io/',
            },
            {
                src: daoWell,
                url: '',
            },
            {
                src: limeStone,
                url: 'https://www.theblockbeats.com/',
            },
            {
                src: winkrypto,
                url: 'https://www.chainnews.com/',
            },
            {
                src: clockCapital,
                url: 'https://7oclockcapital.com/',
            }
        ]
    },
    {
        title: 'AVAX Ecosystem',
        children: [
            {
                src: avalaunche,
                url: '',
            },
            {
                src: avatar,
                url: 'https://www.avaxavatar.com/',
            },
            {
                src: avalaunche2,
                url: '',
            }, 
            {
                src: benqi,
                url: 'https://benqi.fi/',
            },
            {
                src: avalaunch,
                url: 'https://avalaunch.app/',
            }
        ]
    },
    {
        title: 'Media & Community',
        children: [
            {
                src: cmc,
                url: 'https://coinmarketcap.com/',
            },
            {
                src: blockBeats,
                url: 'https://www.theblockbeats.com/',
            },
            {
                src: chainNews,
                url: 'https://www.chainnews.com/',
            },
            {
                src: duckDao,
                url: 'https://duckdao.io/',
            },
            {
                src: clockCapital,
                url: 'https://7oclockcapital.com/',
            }
        ]
    }
] */
const ecosystom1 =
  [
    {
      name: 'avalaunche',
      src: avalaunche,
      url: 'https://www.avax.network/',
    },
    {
      name: 'avatar',
      src: avatar,
      url: 'https://www.avaxavatar.com/',
    },
    {
      name: 'avalaunch',
      src: avalaunch,
      url: 'https://avalaunch.app/',
    }
  ]

const ecosystom2 =
  [

    /* {
      name: 'avalaunche2',
      src: avalaunche2,
      url: 'https://twitter.com/Tederminant',
    }, */
    {
      name: 'benqi',
      src: benqi,
      url: 'https://benqi.fi/',
    },

  ]
const investor = [
  {
    name: 'huobiVentures',
    src: huobiVentures,
    url: 'https://www.huobi.com/',
  },
  {
    name: 'kucoin',
    src: kucoin,
    url: 'https://www.kucoin.com/',
  },
  {
    name: 'gate',
    src: gate,
    url: 'https://www.gate.io/',
  },
  {
    name: 'mexc',
    src: mexc,
    url: 'https://www.mexc.com/',
  },
  {
    name: 'zb',
    src: zb,
    url: 'https://www.zb.com/',
  },
  {
    name: 'aTCapital',
    src: aTCapital,
    url: 'https://capitalant.com/',
  },
  {
    name: 'ld',
    src: ld,
    url: 'https://ldcap.com/',
  },
  {
    name: 'jrr',
    src: jrr,
    url: 'https://jrr.group/',
  },
  {
    name: 'spark',
    src: spark,
    url: 'https://sparkdigitalcapital.com/',
  },
  {
    name: 'redLine',
    src: redLine,
    url: 'http://www.honglianziben.com',
  },
  {
    name: 'mirana',
    src: mirana,
    url: 'https://www.bybit.com/',
  },
  {
    name: 'primeblock',
    src: primeblock,
    url: 'https://www.mexc.com/',
  },
  {
    name: 'kernel',
    src: kernel,
    url: 'https://www.kernel-ventures.com/',
  },
  {
    name: 'hive',
    src: hive,
    url: 'https://kine.io/',
  },
  {
    name: 'limeStone',
    src: limeStone,
    url: 'https://www.theblockbeats.com/',
  },
  {
    name: 'duckstart',
    src: duckstart,
    url: 'https://duckstarter.io/',
  },
  {
    name: 'westarter',
    src: westarter,
    url: 'https://www.westarter.org/',
  },
  {
    name: 'winkrypto',
    src: winkrypto,
    url: 'https://www.chainnews.com/',
  },
  {
    name: 'clockCapital',
    src: clockCapital,
    url: 'https://7oclockcapital.com/',
  },
  {
    name: 'daoWell',
    src: daoWell,
    url: '',
  },
  {
    name: 'chainlink',
    src: chainlink,
    url: 'https://chain.link/',
  },
  {
    name: 'cmc',
    src: cmc,
    url: 'https://coinmarketcap.com/',
  },
  {
    name: 'blockBeats',
    src: blockBeats,
    url: 'https://www.theblockbeats.com/',
  },
  {
    name: 'chainNews',
    src: chainNews,
    url: 'https://www.chainnews.com/',
  },
  {
    name: 'duckDao',
    src: duckDao,
    url: 'https://duckdao.io/',
  }


]

const TitleContainer = styled.div<{ ifMobile: boolean }>`
    width: ${({ ifMobile }) => ifMobile ? 'calc(100% - 40px)' : '100%'};
    margin-bottom: ${({ ifMobile }) => ifMobile ? '61px' : '104px'};
    height: ${({ ifMobile }) => ifMobile ? '82px' : '118px'};
    margin-right: ${({ ifMobile }) => ifMobile ? 'auto' : '0'};
    color='#fff';
    font-weight='bold';
    font-size: ${({ ifMobile }) => ifMobile ? '25px' : '32px'};
`
const PicContainer = styled.div<{ ifMobile: boolean }>`
    margin: ${({ ifMobile }) => ifMobile ? '10px' : '10px 40px'};
    width: 148px;
    height:50px;
    transition: all linear 0.2s;
    & a{
    filter: ${({ theme }) => (theme.isDark ? 'contrast(0.8) opacity(1)' : ' opacity(1) ')};

    :hover{
        filter: ${({ theme }) => (theme.isDark ? 'contrast(0.3) opacity(0.8)' : ' opacity(0.6) ')};
  }
}
`
const LineContainer = styled.div<{ ifMobile: boolean }>`
    max-width: ${({ ifMobile, w }) => ifMobile ? '200px' : w};
    display:flex;
    flex-derection:row;
    flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    margin:0 auto

`


export default () => {
  const ifMobile = useMobile()
  const { t } = useTranslation()

  return (
    <>
      <Title>
        {t("AVAX Eco Strategic Partners")}
      </Title>
      <LineContainer w='800px'>
        {ecosystom1.map(i => (
          <PicContainer key={i.url + i.name} m={ifMobile ? '10px' : '10px 65px'} ifMobile={ifMobile}>
            <a href={i.url} target="_blank" rel="noreferrer">
              <Image src={i.src} />
            </a>
          </PicContainer>
        )
        )

        }
      </LineContainer>
      <LineContainer w='500px'>
        {ecosystom2.map(i => (
          <PicContainer key={i.url + i.name} ifMobile={ifMobile} >
            <a href={i.url} target="_blank" rel="noreferrer">
              <Image src={i.src} />
            </a>
          </PicContainer>
        )
        )

        }
      </LineContainer>


      <Title mt='70px'>
        {t("Investors & Partners")}
      </Title>
      <LineContainer w='100vw' style={{ marginBottom: '30px' }}>
        {
          investor.map(i => (
            <PicContainer key={i.url + i.name} ifMobile={ifMobile}>
              <a href={i.url} target="_blank" rel="noreferrer" >
                <Image src={i.src} />
              </a>
            </PicContainer>
          )
          )

        }
      </LineContainer>


    </>
  )
}

