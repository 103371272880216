/* eslint-disable */
const display = process.env.REACT_APP_ENV === 'MAIN' ? false : true

export const config = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Convert",
    href: "/convert",
    // children: [
    //   {
    //     label: "1:1兑换",
    //     href: "/convert",
    //   },
    //   {
    //     label: "比例兑换",
    //     href: "/convert-2"
    //   }
    // ]
  },
  {
    label: "Swap",
    href: "/swap",
  },
  {
    label: "Liquidity",
    href: "/liquidity"
  },
  {
    label: "Earn",
    href: 'https://farm.hurricaneswap.com/',
    children: [
      {
        label: "Stake",
        href: "/stake",
      },
      {
        label: "Lock sHCT",
        href: "/lock"
      },
      {
        label: "Farm & Pool",
        href: "https://farm.hurricaneswap.com/",
      }
    ]
  },
  {
    label: "Transfer",
    children: [
      {
        label: "Station",
        href: "/stationpool",
      },
      {
        label: "Bridge",
        href: "/deposit",
        // disabled: true
      },
    ]
  },
  {
    label: "More...",
    children: [
      {
        label: "Info",
        href: "/info/overview"
      },
      {
        label: "Vote",
        href: "https://snapshot.org/#/hurricanedao.eth",
        target: "_blank"
      }
    ]
  },
  // {
  //   label: "Info",
  //   disabled: true
  // },
];

export default config
