
export const config = [
  {
    token: '0x8EeE49d7368b1FFDD252baAf5099df902FE89d61',
    data: '',
    chainId: '97'
  },
  {
    token: '0xDdaf411a78d804EFd047EC9C2088403Fdb5BeAB9',
    data: '',
    chainId: '97'
  },
  {
    token: '0xE3bCcd2c46da68F3e00C97881Ad75D10992144Eb',
    data: '',
    chainId: '97'
  },
  {
    token: '0x267C3d338B1b7275D8B306baEf007e0BE2fca463',
    data: '',
    chainId: '97'
  },
  {
    token: '0x12B587b9cc0cb621dB7CC2a4a2Bcb4944d1EaCB5',
    data: '',
    chainId: '97'
  },
  {
    token: "0xB4c1E96E648E763D3eB5Cd1BB2EC9c93293dE49f",
    data: '',
    chainId: '97'
  },
  {
    token: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    chainId: '56'
  },
  {
    token: "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
    chainId: '56',
    name: 'XVS'
  },
  {
    token: "0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
    chainId: '56',
    name: 'MBOX'
  },
  {
    token: "0x8f0528ce5ef7b51152a59745befdd91d97091d2f",
    chainId: '56',
    name: 'ALPACA'
  },
  {
    token: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    chainId: '56',
    name: 'ADA'
  },
  {
    token: "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
    chainId: '56',
    name: 'DOT'
  },
  {
    token: "0x03ff0ff224f904be3118461335064bb48df47938",
    chainId: '56',
    name: 'ONE'
  },
  {
    token: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    chainId: '56',
    name: 'MATIC'
  }
];



export default config
