import React from 'react'
import { Currency, Percent, Price } from '@glhf-libs/sdk'
import { Text } from '@hurricaneswap/uikit'
import { useActiveWeb3React } from 'hooks'
import {useBaseCoin} from 'hooks/useBaseCoin'
import { AutoColumn } from '../../components/Column'
import { AutoRow } from '../../components/Row'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../components/Shared'

const { black: Black } = TYPE

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: Percent
  price?: Price
}) {

  const {chainId} = useActiveWeb3React()
  const currencyA = useBaseCoin(currencies[Field.CURRENCY_A], chainId)
  const currencyB = useBaseCoin(currencies[Field.CURRENCY_B], chainId)

  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
        <AutoColumn justify="center">
          <Black>{price?.toSignificant(6) ?? '-'}</Black>
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {currencyA} per {currencyB}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Black>{price?.invert()?.toSignificant(6) ?? '-'}</Black>
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {currencyB} per {currencyA}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Black>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </Black>
          <Text fontSize="14px" color="textSubtle" pt={1}>
            Share of Pool
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  )
}

export default PoolPriceBar
