import React, { useState, useRef, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { ArrowDropDownIcon, Flex, Text, ChevronDownIcon } from '@hurricaneswap/uikit'
import { StyledBnbLogo } from 'components/CurrencyLogo'
import { useTokenBalance } from 'state/wallet/hooks'
import { useActiveWeb3React } from 'hooks'
import Loader from 'components/Loader'

const DropDownHeader = styled.div<{ disable: boolean; }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px;
  // box-shadow: ${({ theme, disable }) => disable ? 'None' : theme.shadows.inset};
  // border: 1px solid ${({ theme, disable }) => disable ? 'None' : (theme.isDark ? '#5723A6' : 'rgba(255, 255, 255, 0.6)')};
  border-radius: 6px;
  // background: #FFFFFF;
  // background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,0.25)'};

  & li:hover{
      background:transparent
  }
  @media (max-width: 320px) { 
    padding: 0px 0px 0px 0px;
}

  transition: border-radius 0.15s;
`
const TrigerFlex = styled(Flex)`
    @media (max-width: 375px) { 
        display: None;
    }
`

const DropDownListContainer = styled.div`
  min-width: 136px;
  height: 0;
  position: absolute;
  overflow: hidden;
  // background: #FFFFFF;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  width: 100%;
  opacity: 0;


  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number; disable: boolean }>`
  cursor: pointer;
  width: ${({ width }) => width}px;
  position: relative;
  // background: #FFFFFF;
  // background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  border-radius: 6px;
  height: 40px;
  min-width: 136px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }
  ${DropDownHeader}{
    background: ${({ theme, disable }) => (disable ? 'none' : theme.isDark ? 'rgba(255,255,255,0.25)' : 'rgba(76, 35, 118, 0.04)')};
    // border: 1px solid ${({ theme, disable }) => disable ? 'None' : (theme.isDark ? '#8D80D5' : 'rgba(0, 0, 0, 0.12)')};  
  }

  ${(props) =>
    props.isOpen &&
    css`
      
      ${DropDownHeader}{
        // background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(76, 35, 118, 0.04)'};
        // border: 1px solid #5723A6;
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border: 0px solid ${({ theme }) => theme.colors.textSubtle};
        border-top-width: 0;
        border-radius: 6px;
       // box-shadow: 0 20px 20px rgba(159, 56, 140, 0.12);
        margin-top:1px;
          border: 1px solid #5723A6;
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  padding: 8px 16px;
  @media (max-width: 320px) { 
    padding: 8px 0px;
    font-size:12px;
    }
  list-style: none;
  &:hover {
    background: ${({ theme }) => (theme.isDark ? 'rgba(255,255,255,0.2)' : 'rgba(141, 128, 213, 0.1)')};
  }
`

const StyledText = styled(Text)`
@media (max-width: 320px) { 
  font-size: 14px;
  margin-left: 5px
}
`


const PairLogos = ({ baseLogo, quoteLogo }) => {
  return (
    <TrigerFlex>
      <StyledBnbLogo src={baseLogo} size='20px' />
      <StyledBnbLogo src={quoteLogo} size='20px' />
    </TrigerFlex>
  )
}



const OptionItem = ({ balance, style = {}, loading, option, onOptionClicked, showLogo }) => {

  const { account } = useActiveWeb3React()
  // const userPoolBalance = useTokenBalance(account ?? undefined, option?.liquidityToken ?? null)

  const token0Logo = useMemo(() => {
    if (option && option?.token0Logo) {
      return option?.token0Logo
    }
    return null
  }, [option])
  const token1Logo = useMemo(() => {
    if (option && option?.token1Logo) {
      return option?.token1Logo
    }
    return null
  }, [option])

  const lptokenLogo = useMemo(() => {
    if (option && option?.lpLogo) {
      return option?.lpLogo
    }
    console.log(option)
    return null
  }, [option])
  // console.log(999,option)
  return (
    option ? (
      <ListItem onClick={onOptionClicked(option)} key={option?.label + option?.value} style={style}>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex alignItems='center'>
            {/* showLogo &&  */}
            {showLogo && (option?.label.includes('Hc-') ?
              <StyledBnbLogo src={lptokenLogo} size='24px' /> :
              <PairLogos baseLogo={token0Logo} quoteLogo={token1Logo} />)
            }
            <StyledText ml='10px'>{option?.label}</StyledText>
          </Flex>
          {/* 余额 放不下了
                <Flex alignItems='center'>
                    {
                        loading
                            // @ts-ignore
                            ? <Loader style={{ position: 'static' }} />
                            : (balance)
                                ? balance.toSignificant(4)
                                : '-'}
                </Flex> */}

        </Flex>
      </ListItem>) :
      (
        <div>Select LP-token</div>
      )

  )
}

export interface SelectProps {
  options: OptionProps[]
  onChange?: (option: OptionProps) => void
  showLogo: boolean
  loading: boolean
  disabled?: boolean
  v2PairsBalances?: any
}

export interface OptionProps {
  label: string
  value: any
  balance?: any
  src?: string
}

const LpTokenSelect: React.FunctionComponent<SelectProps> = ({ v2PairsBalances, disabled = false, loading = true, options, onChange, showLogo = false }) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const { chainId } = useActiveWeb3React()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      setIsOpen(!isOpen)
      event.stopPropagation()
    }
  }

  const onOptionClicked = (option: OptionProps) => () => {
    setSelectedOption(option)
    setIsOpen(false)

    if (onChange) {
      onChange(option)
    }
  }

  useEffect(() => {
    setContainerSize({
      width: dropdownRef?.current?.offsetWidth || '100%', // Consider border
      height: dropdownRef?.current?.offsetHeight || '100%',
    })
  }, [])

  // init
  useEffect(() => {
    if (options) {
      // 需要select-lp的时候 再注释掉
      setSelectedOption(options[0])
      if (onChange) {
        onChange(options[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])

  useEffect(() => {
    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize} disable={disabled}>
      {containerSize.width !== 0 && (
        <DropDownHeader onClick={toggling} className={isOpen ? 'select' : ''} disable={disabled}>
          <OptionItem style={{ width: '90%' }} loading={loading}
            option={selectedOption}
            balance={v2PairsBalances[selectedOption.value]}
            showLogo={showLogo}
            onOptionClicked={onOptionClicked} />
        </DropDownHeader>
      )}

      {!disabled && (<>
        <ChevronDownIcon color="text" onClick={toggling} />
        <DropDownListContainer>
          <DropDownList ref={dropdownRef}>
            {options && options.map((option) =>
              // option?.label !== selectedOption?.label ? (
              <OptionItem
                loading={loading}
                balance={v2PairsBalances[option.value]}
                option={option}
                showLogo={showLogo}
                onOptionClicked={onOptionClicked} />
              // ) : null,
            )}
          </DropDownList>
        </DropDownListContainer>
      </>)}


    </DropDownContainer>
  )
}

export default LpTokenSelect
