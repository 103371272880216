// @ts-nocheck
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Navbar } from '@hurricaneswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import { injected } from '../../connectors'
import { HCT } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import links from './config'
import useAuth from '../../hooks/useAuth'
import UserTokens from '../UserTokens'
import SearchTokens from '../SearchTokens'
import useMobile from '../../hooks/useMobile'
import i18next from '../../i18n'




// {
//   account: "0xbdda50183d817c3289f895a4472eb475967dc980",
//     login: noop,
//   logout: noop,
//   isDark: false,
//   toggleTheme: noop,
//   langs,
//   setLang: noop,
//   currentLang: "EN",
//   cakePriceUsd: 0.023158668932877668,
//   links,
//   profile: null,
// }
export const getNetwork = (chainId) => {
  let network
  if (chainId === 97) {
    network = 'Test-BSC'
  } else if (chainId === 56) {
    network = 'BSC'
  } else if (chainId === 256) {
    network = 'TEST-HECO'
  } else if (chainId === 128) {
    network = 'HECO'
  } else if (chainId === 43113) {
    network = 'FUJI'
  } else {
    network = 'AVAX'
  }
  return network
}

const AddHctIcon = () => {
  return (
    <svg width="96" height="28" viewBox="0 0 96 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="26" cy="14" r="14" fill="white" />
      <path d="M29.4525 21.7647C27.655 21.8826 26.3491 20.5541 26.3281 18.9624C26.3231 18.5895 26.3889 18.2263 26.5147 17.8909C26.7287 17.319 27.1187 16.829 27.6344 16.5111C28.0944 16.2274 28.655 16.081 29.281 16.1364C28.9573 16.0705 28.6075 16.0357 28.2334 16.0307C25.9509 15.9987 24.0272 17.8507 24.057 19.721C24.0689 20.4672 24.3624 21.1141 24.859 21.6178V21.6183C25.5732 22.3421 26.7076 22.7707 28.0323 22.7712C28.1822 22.7368 28.3304 22.6989 28.4771 22.6568C29.6623 22.3196 30.7483 21.7454 31.6797 20.9892L31.9225 20.7755C31.8269 20.8524 30.778 21.6773 29.4525 21.7647Z" fill="url(#paint0_radial)" />
      <path d="M29.949 13.2554C28.7104 12.2412 27.9404 12.4237 27.0908 12.1821C25.9523 12.2132 24.4635 12.5692 22.9002 13.8031C22.9349 13.7862 23.6199 13.4545 24.7218 13.5968C24.8526 13.6141 24.9898 13.6375 25.132 13.669C23.1452 14.5781 21.71 16.6273 21.71 18.6385C21.71 19.5563 21.993 20.415 22.4859 21.149C22.9582 21.8535 23.6235 22.4432 24.4168 22.8596C24.9331 22.952 25.4648 23.0001 26.0081 23.0001C26.0753 23.0001 26.142 22.9991 26.2083 22.9977H26.2088C26.8343 22.984 27.4443 22.9067 28.0323 22.7712C26.7076 22.7707 25.5732 22.3421 24.859 21.6183V21.6178C24.3624 21.1141 24.0689 20.4672 24.057 19.721C24.0273 17.8507 25.9509 15.9987 28.2335 16.0307C28.6075 16.0358 28.9573 16.0705 29.281 16.1364C29.6546 16.2114 30.7579 16.6168 30.655 17.0743C30.655 17.0743 30.8301 17.0267 31.0103 16.7989C31.6193 16.0303 31.4131 14.4541 29.949 13.2554Z" fill="url(#paint1_radial)" />
      <path d="M29.949 13.2554C28.7104 12.2412 27.9404 12.4237 27.0908 12.1821C25.9523 12.2132 24.4635 12.5692 22.9002 13.8031C22.9349 13.7862 23.6199 13.4545 24.7218 13.5968C24.8526 13.6141 24.9898 13.6375 25.132 13.669C23.1452 14.5781 21.71 16.6273 21.71 18.6385C21.71 19.5563 21.993 20.415 22.4859 21.149C22.9582 21.8535 23.6235 22.4432 24.4168 22.8596C24.9331 22.952 25.4648 23.0001 26.0081 23.0001C26.0753 23.0001 26.142 22.9991 26.2083 22.9977H26.2088C26.8343 22.984 27.4443 22.9067 28.0323 22.7712C26.7076 22.7707 25.5732 22.3421 24.859 21.6183V21.6178C24.3624 21.1141 24.0689 20.4672 24.057 19.721C24.0273 17.8507 25.9509 15.9987 28.2335 16.0307C28.6075 16.0358 28.9573 16.0705 29.281 16.1364C29.6546 16.2114 30.7579 16.6168 30.655 17.0743C30.655 17.0743 30.8301 17.0267 31.0103 16.7989C31.6193 16.0303 31.4131 14.4541 29.949 13.2554Z" fill="url(#paint2_radial)" />
      <path d="M26.0077 5C21.0329 5 17 9.00918 17 13.9869C17 16.5256 18.0489 18.8191 19.7371 20.457C20.2784 20.9827 20.8852 21.4403 21.5441 21.8172V21.8167C21.4796 21.7453 21.417 21.6727 21.3557 21.5986C21.4938 21.7202 21.6392 21.8382 21.791 21.9531C22.2739 22.2098 22.7832 22.4238 23.3136 22.5895C23.6721 22.702 24.0402 22.7927 24.4165 22.8594C23.6232 22.4431 22.9579 21.8533 22.4856 21.1488C21.9926 20.4149 21.7096 19.5562 21.7096 18.6384C21.7096 16.6272 23.1449 14.578 25.1316 13.6689C24.9894 13.6374 24.8523 13.614 24.7215 13.5966C23.6195 13.4544 22.9346 13.786 22.8998 13.803C24.4631 12.5691 25.9519 12.2131 27.0905 12.182C28.3525 12.1472 29.1833 12.5119 29.208 12.5224C28.6904 11.2725 27.9629 11.348 27.9629 11.348C28.2962 11.2784 28.5578 11.2876 28.9112 11.2876C31.7397 11.2876 34.1161 13.577 34.1161 16.4575C34.1161 17.485 33.6556 18.6352 33.1521 19.4427C33.5065 18.9747 33.8188 18.4691 34.0804 17.9334C34.6648 16.7397 34.9998 15.3965 34.9998 13.9869C34.9998 9.00918 30.9825 5 26.0077 5Z" fill="url(#paint3_radial)" />
      <path opacity="0.19" d="M26.0077 5C21.0329 5 17 9.00918 17 13.9869C17 16.5256 18.0489 18.8191 19.7371 20.457C20.2784 20.9827 20.8852 21.4403 21.5441 21.8172V21.8167C21.4796 21.7453 21.417 21.6727 21.3557 21.5986C21.4938 21.7202 21.6392 21.8382 21.791 21.9531C22.2739 22.2098 22.7832 22.4238 23.3136 22.5895C23.6721 22.702 24.0402 22.7927 24.4165 22.8594C23.6232 22.4431 22.9579 21.8533 22.4856 21.1488C21.9926 20.4149 21.7096 19.5562 21.7096 18.6384C21.7096 16.6272 23.1449 14.578 25.1316 13.6689C24.9894 13.6374 24.8523 13.614 24.7215 13.5966C23.6195 13.4544 22.9346 13.786 22.8998 13.803C24.4631 12.5691 25.9519 12.2131 27.0905 12.182C28.3525 12.1472 29.1833 12.5119 29.208 12.5224C28.6904 11.2725 27.9629 11.348 27.9629 11.348C28.2962 11.2784 28.5578 11.2876 28.9112 11.2876C31.7397 11.2876 34.1161 13.577 34.1161 16.4575C34.1161 17.485 33.6556 18.6352 33.1521 19.4427C33.5065 18.9747 33.8188 18.4691 34.0804 17.9334C34.6648 16.7397 34.9998 15.3965 34.9998 13.9869C34.9998 9.00918 30.9825 5 26.0077 5Z" fill="url(#paint4_radial)" />
      <path d="M34.7185 14.9475C34.7185 14.7759 34.7126 14.6057 34.7007 14.4369C34.4373 10.6958 31.3011 7.7417 27.4712 7.7417C25.4434 7.7417 23.6098 8.56979 22.2943 9.90481C22.2943 9.90481 22.7497 9.75246 23.4575 9.74103C23.72 9.73645 24.0172 9.75154 24.3391 9.80096C21.5152 11.0189 19.5486 13.7383 19.3977 16.9935C19.3085 18.9278 20.0127 20.4193 21.3561 21.5983C21.4941 21.72 21.6395 21.8381 21.7914 21.9528C22.2742 22.2095 22.7836 22.4237 23.314 22.5893C23.6725 22.7019 24.0405 22.7924 24.4169 22.8593C23.6235 22.4429 22.9582 21.8532 22.4859 21.1486C21.993 20.4148 21.71 19.556 21.71 18.6382C21.71 16.627 23.1452 14.5778 25.132 13.6688C24.9898 13.6372 24.8526 13.6139 24.7218 13.5965C23.6199 13.4542 22.9349 13.7859 22.9002 13.8028C24.4635 12.5689 25.9523 12.213 27.0908 12.1818C28.3528 12.1471 29.1836 12.5117 29.2083 12.5222C28.6907 11.2723 27.9632 11.3478 27.9632 11.3478C28.2966 11.2783 28.5581 11.2874 28.9116 11.2874C31.7401 11.2874 34.1163 13.5768 34.1163 16.4573C34.1163 17.4849 33.6559 18.635 33.1525 19.4425C33.5069 18.9745 33.8192 18.469 34.0807 17.9332L34.071 17.9286C34.4873 17.02 34.7185 16.0108 34.7185 14.9475Z" fill="url(#paint5_radial)" />
      <path d="M25.3446 20.2093C25.3446 21.5247 26.282 22.5427 28.4768 22.6569C28.33 22.699 28.1819 22.737 28.0319 22.7714C26.7072 22.7709 25.5728 22.3422 24.8586 21.6184V21.6179C24.5852 21.1293 24.4302 20.5721 24.4302 19.9805C24.4302 18.2045 25.8257 16.7372 27.6341 16.5112C27.1183 16.8292 26.7283 17.3192 26.5143 17.8911C25.8321 18.3879 25.3446 19.3025 25.3446 20.2093Z" fill="url(#paint6_radial)" />
      <path d="M29.281 16.1364C28.655 16.081 28.0944 16.2274 27.6344 16.5111C25.8261 16.7371 24.4305 18.2043 24.4305 19.9804C24.4305 20.5719 24.5855 21.1292 24.859 21.6178C24.3624 21.1141 24.0689 20.4672 24.057 19.721C24.0272 17.8507 25.9509 15.9987 28.2334 16.0307C28.6075 16.0357 28.9573 16.0705 29.281 16.1364Z" fill="url(#paint7_radial)" />
      <path opacity="0.25" d="M30.5688 17.1095C30.6717 16.652 30.2968 16.4864 30.2968 16.4864C29.9936 16.3281 29.6543 16.2114 29.2808 16.1364C28.957 16.0705 28.6072 16.0357 28.2332 16.0307C25.9507 15.9987 24.027 17.8507 24.0568 19.721C24.0686 20.4672 24.3622 21.114 24.8588 21.6179V21.6182C25.573 22.342 26.7074 22.7707 28.032 22.7711C27.444 22.9066 26.834 22.9839 26.2085 22.9976H26.2081C24.7275 22.3946 23.6987 21.0656 23.6987 19.5229C23.6987 17.4128 25.6233 15.7026 27.9968 15.7026C29.1706 15.7026 30.2346 16.1208 31.0101 16.7988C30.8418 17.0358 30.5688 17.1095 30.5688 17.1095Z" fill="url(#paint8_radial)" />
      <path d="M21.9614 19.134C21.9614 19.8638 22.1512 20.5409 22.4923 21.1443L22.4859 21.149C21.993 20.4151 21.71 19.5563 21.71 18.6385C21.71 16.6273 23.1452 14.5781 25.132 13.669C24.9898 13.6375 24.8526 13.6141 24.7218 13.5968C23.6199 13.4545 22.9349 13.7862 22.9002 13.8031C24.4635 12.5692 25.9523 12.2132 27.0908 12.1821C27.9404 12.4237 28.5942 12.7151 29.0967 13.023L29.0944 13.0262C27.7227 12.2485 25.6423 13.0491 25.6423 13.0491C27.517 13.255 27.7227 13.7788 27.7227 13.7788C24.5266 14.0268 21.9614 16.453 21.9614 19.134Z" fill="url(#paint9_radial)" />
      <path d="M21.9614 19.134C21.9614 19.8638 22.1512 20.5409 22.4923 21.1443L22.4859 21.149C21.993 20.4151 21.71 19.5563 21.71 18.6385C21.71 16.6273 23.1452 14.5781 25.132 13.669C24.9898 13.6375 24.8526 13.6141 24.7218 13.5968C23.6199 13.4545 22.9349 13.7862 22.9002 13.8031C24.4635 12.5692 25.9523 12.2132 27.0908 12.1821C27.9404 12.4237 28.5942 12.7151 29.0967 13.023L29.0944 13.0262C27.7227 12.2485 25.6423 13.0491 25.6423 13.0491C27.517 13.255 27.7227 13.7788 27.7227 13.7788C24.5266 14.0268 21.9614 16.453 21.9614 19.134Z" fill="url(#paint10_radial)" />
      <path opacity="0.29" d="M21.3208 18.5163C21.3208 16.3331 22.7168 14.455 24.7181 13.6218L24.7213 13.5967C24.8521 13.6141 24.9893 13.6374 25.1315 13.669C23.1447 14.578 21.7095 16.6272 21.7095 18.6385C21.7095 19.5562 21.9925 20.415 22.4854 21.1489C22.9577 21.8534 23.623 22.4431 24.4163 22.8595C24.04 22.7926 23.6719 22.7021 23.3135 22.5895L23.3162 22.5803C22.0945 21.5985 21.3208 20.1418 21.3208 18.5163Z" fill="url(#paint11_radial)" />
      <path d="M23.4575 9.74103C22.7497 9.75246 22.2943 9.90481 22.2943 9.90481C23.6098 8.56979 25.4434 7.7417 27.4712 7.7417C31.3011 7.7417 34.4373 10.6958 34.7007 14.4369C33.506 11.8977 30.7547 10.1208 27.5513 10.1208C23.2518 10.1208 19.7667 13.3215 19.7667 17.2694C19.7667 18.8963 20.3588 20.3969 21.3561 21.5983C20.0127 20.4193 19.3085 18.9278 19.3977 16.9935C19.5486 13.7383 21.5152 11.0189 24.3391 9.80096C24.0172 9.75154 23.72 9.73645 23.4575 9.74103Z" fill="url(#paint12_radial)" />
      <path opacity="0.53" d="M23.4575 9.74103C22.7497 9.75246 22.2943 9.90481 22.2943 9.90481C23.6098 8.56979 25.4434 7.7417 27.4712 7.7417C31.3011 7.7417 34.4373 10.6958 34.7007 14.4369C33.506 11.8977 30.7547 10.1208 27.5513 10.1208C23.2518 10.1208 19.7667 13.3215 19.7667 17.2694C19.7667 18.8963 20.3588 20.3969 21.3561 21.5983C20.0127 20.4193 19.3085 18.9278 19.3977 16.9935C19.5486 13.7383 21.5152 11.0189 24.3391 9.80096C24.0172 9.75154 23.72 9.73645 23.4575 9.74103Z" fill="url(#paint13_radial)" />
      <path opacity="0.25" d="M23.4575 9.7415C23.72 9.73692 24.0172 9.75203 24.3391 9.80143C21.5151 11.0193 19.5485 13.7388 19.3976 16.994C19.3085 18.9283 20.0126 20.4198 21.356 21.5988C21.4173 21.673 21.4799 21.7456 21.5444 21.817V21.8175C20.8855 21.4404 20.2787 20.983 19.7374 20.4573C19.1845 19.3877 18.875 18.1904 18.875 16.9267C18.875 13.8376 20.7236 11.147 23.4575 9.7415Z" fill="url(#paint14_radial)" />
      <path d="M44 18L48 14L44 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M48 18L52 14L48 10" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="70" cy="14" r="14" fill="white" />
      <path d="M74.6519 15.4336C74.0644 15.4336 73.5822 14.9265 73.5822 14.3086C73.5822 13.6889 74.0626 13.1836 74.6519 13.1836H78.0002V12.2478C78.0002 11.6442 77.5323 11.1514 76.9591 11.1514H65.3053C64.732 11.1514 64.2642 11.6442 64.2642 12.2478V19.8194C64.2642 20.423 64.732 20.9159 65.3053 20.9159H76.9591C77.5323 20.9159 78.0002 20.423 78.0002 19.8194V15.4336H74.6519Z" fill="#8D80D5" />
      <path d="M73.9409 14.3088C73.9409 14.503 74.0181 14.6892 74.1554 14.8265C74.2927 14.9638 74.4789 15.041 74.6731 15.041C74.8673 15.041 75.0535 14.9638 75.1908 14.8265C75.3281 14.6892 75.4052 14.503 75.4052 14.3088C75.4052 14.1146 75.3281 13.9284 75.1908 13.7911C75.0535 13.6538 74.8673 13.5767 74.6731 13.5767C74.4789 13.5767 74.2927 13.6538 74.1554 13.7911C74.0181 13.9284 73.9409 14.1146 73.9409 14.3088Z" fill="#8D80D5" />
      <path d="M63.6139 11.5997C63.6139 10.9961 64.1068 10.5032 64.7104 10.5032H76.0052L75.0588 7.74067C74.8642 7.17101 74.2374 6.86386 73.6659 7.05851L62.7407 10.805C62.171 10.9997 61.8639 11.6265 62.0585 12.1979L63.6139 16.7355V11.5997Z" fill="#8D80D5" />
      <defs>
        <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.6416 21.5961) scale(7.92748 8.78482)">
          <stop stopColor="#867BC4" />
          <stop offset="1" stopColor="#5F2E86" />
        </radialGradient>
        <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28.568 17.2353) scale(11.4611 11.4678)">
          <stop stopColor="#998FDF" />
          <stop offset="0.4863" stopColor="#6E5CBE" />
          <stop offset="1" stopColor="#000009" />
        </radialGradient>
        <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.8763 18.7456) rotate(120.104) scale(11.0123 22.0884)">
          <stop stopColor="#9564AC" />
          <stop offset="0.6435" stopColor="#5C3B9C" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.1407 11.0456) scale(19.5057 19.5171)">
          <stop stopColor="#998FDF" />
          <stop offset="0.4275" stopColor="#6E5CBE" />
          <stop offset="0.8446" stopColor="#8C4192" />
        </radialGradient>
        <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.3554 10.8298) rotate(49.3152) scale(4.17225 5.86023)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint5_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.7616 21.0781) scale(17.2696 17.2797)">
          <stop stopColor="#51339D" />
          <stop offset="0.4171" stopColor="#9E84D5" stopOpacity="0.5725" />
          <stop offset="0.9259" stopColor="#9489DB" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.608 18.1962) scale(5.003 5.00593)">
          <stop offset="0.0181" stopColor="#4B338C" />
          <stop offset="1" stopColor="#4B338C" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint7_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.2426 16.7778) scale(6.21078 6.21441)">
          <stop stopColor="#352086" />
          <stop offset="1" stopColor="#352086" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint8_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.8706 20.9412) scale(4.94861 4.95148)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint9_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.7557 19.2027) scale(7.0638 7.06793)">
          <stop stopColor="#422085" />
          <stop offset="1" stopColor="#422085" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint10_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.8988 10.7844) scale(8.16639 8.17116)">
          <stop offset="0.0829" stopColor="#422085" />
          <stop offset="0.7132" stopColor="#422085" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint11_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.6317 19.097) scale(3.53943 3.92079)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint12_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.5152 18.5161) scale(14.296 14.3044)">
          <stop stopColor="#4C2376" />
          <stop offset="0.6989" stopColor="#301C86" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint13_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.0063 6.34629) scale(11.5136 11.5203)">
          <stop stopColor="#4C2376" />
          <stop offset="1" stopColor="#301C86" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint14_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.4296 20.9414) scale(8.89278 8.89798)">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
const Wrapper = styled.div`
  background: #8D80D5;
  opacity: 0.8;
  border-radius: 100px;
  margin: 0px 10px;
  width: 96px;
  height:44px;
  display:flex;
  flex-direction:row;
  align-items:center

`
const AddHct = () => {
  // const { account, chainId } = useActiveWeb3React()
  // const hct = chainId ? HCT[chainId] : undefined
  return (
    <Wrapper onClick={() => {
      injected.getProvider().then(provider => {
        if (provider) {
          provider.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: HCT?.address,
                symbol: HCT?.symbol,
                decimals: HCT?.decimals,
                image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11885.png',
              },
            },
          }).catch((error: any) => {
            console.error(error)
          })
        }
      });
    }


    }>
      <AddHctIcon />
    </Wrapper>
  )
}
const VersionIcon = () => {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4C0 1.79086 1.79086 0 4 0H28V16C28 18.2091 26.2091 20 24 20H0V4Z" fill="url(#paint0_linear_3367_19522)" />
      <path d="M13.432 8.456L10.552 14.12H9.904L7.012 8.456H9.016L9.892 10.532C10.084 10.98 10.192 11.328 10.216 11.576H10.24C10.272 11.288 10.38 10.94 10.564 10.532L11.44 8.456H13.432ZM17.4853 5.72C18.2693 5.72 18.8733 5.908 19.2973 6.284C19.7213 6.652 19.9333 7.18 19.9333 7.868C19.9333 8.292 19.8453 8.696 19.6693 9.08C19.4933 9.464 19.1813 9.94 18.7333 10.508L17.7013 11.816C17.4693 12.104 17.2613 12.324 17.0773 12.476L17.1013 12.512C17.3893 12.416 17.8453 12.368 18.4693 12.368H20.4133L20.4253 12.392L19.4893 14H14.0053L13.9453 13.88L17.3173 9.608C17.8133 8.976 18.0613 8.488 18.0613 8.144C18.0613 7.912 17.9893 7.732 17.8453 7.604C17.7013 7.476 17.5013 7.412 17.2453 7.412C16.7013 7.412 16.0893 7.656 15.4093 8.144L14.5333 6.716C15.5253 6.052 16.5093 5.72 17.4853 5.72Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_3367_19522" x1="-0.407766" y1="7.05882" x2="20.4987" y2="19.5818" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A78EEF" />
          <stop offset="0.945858" stopColor="#5B12CB" />
        </linearGradient>
      </defs>
    </svg>

  )
}

const Menu: React.FC = (props) => {
  // chainId 随时变化
  const { chainId } = useActiveWeb3React()
  const { t, i18n } = useTranslation()
  const { account, activate, deactivate } = useWeb3React()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme, theme } = useTheme()
  const priceData = useGetPriceData()
  const { login, logout } = useAuth()
  const ifMobileForNav = useMobile(1200)
  const network = getNetwork(chainId)

  useEffect(() => {
    window.onscroll = null;
  }, [])

  const selectLang = (e) => {
    setSelectedLanguage(e);
    i18next.changeLanguage(e.code)
  }

  // const currentLng = useMemo(() => {
  //   return i18n?.language ? i18n?.language.toUpperCase().split('-')[0] : 'EN'
  // }, [i18n.language])
  const currentLng = "EN"



  return (
    // <UikitMenu
    <Navbar
      ifMobile={ifMobileForNav}
      links={links}
      account={account || undefined}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLng || ''}
      langs={allLanguages}
      setLang={selectLang}
      t={t}
      // @ts-ignore
      coinList={UserTokens}
      SearchToken={ifMobileForNav ? null : AddHct}
      style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${theme.colors.gradients.backgroundCover} fixed`, opacity: 0.85 }}
      // style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `rgba(${isDark ? '0,0,0,' : '255,255,255,'}${ scroll.top / 10 > 0.8 ? 0.8 : scroll.top / 10 })`}}
      // style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${isDark ? 'linear-gradient(220.07deg,#442150 0%,#293051 51%,#442150 100%)' : 'repeating-linear-gradient(58.14deg, rgb(211, 231, 255) 0%, rgb(223, 211, 249) 43%, rgb(211, 231, 255) 100%)'} fixed`, opacity: 0.85 }}
      /* cakePriceUsd={cakePriceUsd} */
      /* cakePriceLink={`https://bscscan.com/token/${pantherAddress}`} */
      /* profile={profile} */
      {...props}
      network={network}
      version={VersionIcon}
    />
  )
}

export default Menu
