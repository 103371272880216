import React from 'react'
import styled, { css } from 'styled-components'
import { animated, useTransition, config } from 'react-spring'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { isMobile } from 'react-device-detect'
import '@reach/dialog/styles.css'
import { transparentize } from 'polished'
import '../../css/modal.css'
import Portal from '../Portal'


const AnimatedDialogOverlay = animated(DialogOverlay)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// StyledDialogOverlay会调用portal
const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    z-index: 999;
    background-color: transparent;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.2);
  }
`

const AnimatedDialogContent = animated(DialogContent)
// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogContent = styled(({ minHeight, maxHeight, mobile, isOpen, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  'aria-label': 'dialog'
})`
  &[data-reach-dialog-content] {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0 0 2rem 0;
    // border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
    // background-color: ${({ theme }) => theme.colors.invertedContrast};
    // box-shadow: 0 4px 8px 0 ${transparentize(0.95, '#191326')};
    background: ${({ theme }) => theme.isDark ? theme.modal.background : "#f1f0fc"};
    // background: ${({ theme }) => theme.isDark ? "#383a32" : "#f1f0fc"};

    
    // background: #f1f0fc;
    box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
    // border: 1px solid ${({ theme }) => theme.colors.borderColor};
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    align-self: ${({ mobile }) => (mobile ? 'flex-end' : 'center')};

    // max-width: 420px;
    ${({ maxHeight }) =>
    maxHeight &&
    css`
        // max-height: ${maxHeight}vh;
      ` }
    ${({ minHeight }) =>
    minHeight &&
    css`
        // min-height: calc(${minHeight}vh - 64px);
      ` }
    display: flex;
    border-radius: 20px;

    ${({ theme }) => theme.mediaQueries.lg} {
      // width: 65vw;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
      // width: 85vw;
    }
  }
`

interface ModalProps {
  isOpen: boolean
  onDismiss: () => any
  minHeight?: number | false
  maxHeight?: number
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode
  portalId?: string
}

export default function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 50,
  initialFocusRef,
  children,
  portalId
}: ModalProps) {
  const fadeTransition = useTransition(isOpen, {
    config: { duration: 200 },
    from: { transform: 'translateY(100%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(100%)' }
  })

  return (
    <>
      {fadeTransition(
        (styles, item) => {
          return (
            item && (
              portalId
                ? (
                  <Portal id={portalId}>
                    <StyledDialogContent
                      aria-label="dialog content"
                      minHeight={minHeight}
                      maxHeight={maxHeight}
                      mobile={isMobile}
                      style={styles}
                    >
                      {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                      {/* eslint-disable */}
                      {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                      {/* eslint-enable */}
                      {children}
                    </StyledDialogContent>
                  </Portal>
                )
                : (
                  <StyledDialogContent
                    aria-label="dialog content"
                    minHeight={minHeight}
                    maxHeight={maxHeight}
                    mobile={isMobile}
                    style={styles}
                  >
                    {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                    {/* eslint-disable */}
                    {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                    {/* eslint-enable */}
                    {children}
                  </StyledDialogContent>
                )
            )
          )
        }
      )}
    </>
  )
}
