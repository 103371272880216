import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Flex, Text, Button } from '@hurricaneswap/uikit'
import { NavLink } from 'react-router-dom'

import styled, { ThemeContext } from 'styled-components'
import useRequest from '@ahooksjs/use-request'
import { formatEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'

import { useActiveWeb3React } from 'hooks'
import moment from 'moment'

import { useTranslation } from 'react-i18next'
import { useContract } from 'hooks/useContract'

import '../../css/modal.css'
import nhctIcon from '../../assets/imgs/nhct-bg.png'
import useMobile from '../../hooks/useMobile'

import starIcon from '../../assets/imgs/star-icon.png'
import nhcthubABI from '../../constants/abis/nhcthub.json'
import addrConfig from '../../config'

const nhctHubAddr = addrConfig.avax.nhctHubAddr


const InfoBlock = styled.div`
  flex-basis:50%;
  flex-grow:1;
  padding-left:24px;
  position:relative;
  font-size:20px;
  margin-bottom:30px;
  color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 

  &::before {
    content: '';
    position: absolute;
    background: url('${starIcon}') no-repeat;
    width: 16px;
    height: 16px;
    display:block;
    left:6px;
`
const InfoBlock2 = styled(InfoBlock)`
    &::before {
      top:10px
    }
`
const InfoBlockTitle = styled.div`
      color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 
`
const InfoBlockContent = styled.div`
      color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 
      padding-top:10px;
`
const InfoBlockTip = styled.div`
      color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 
      line-height:30px;
      margin-top:10px;
      font-size:15px;
      ol{
        list-style:decimal;
        margin-left:20px;
      }
`
const BlodTextContainer = styled.div`
      font-weight:600;
`
const CardContainer = styled.div<{ ifMobile: boolean }>`
  max-width: 710px;
  width: 100%;
  padding: ${({ ifMobile }) => (ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  // height: 285px;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => (theme.colors.swapContainer)};
  color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 
  border-radius: 20px;
`


const Card = styled.div<{ ifMobile?: boolean }>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px;

`
const HelpCard = styled(Card)`
  background-image: url(${({ ifMobile }) => (!ifMobile ? nhctIcon : null)});
  background-size: auto;
  background-position: top right;
  background-repeat: no-repeat;
  color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 

`
const TextContainer = styled(Text)`
  color:${({ theme }) => (theme.isDark ? ' #ffffff' : 'rgba(77,60,85,0.65)')}; 
`

const Tips = styled.div`
  color:rgb(100, 37, 197)
`

const ConvertInfo = () => {
  const { chainId, account } = useActiveWeb3React()

  const ifMobile = useMobile()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const titile = t('Stats')
  const nhctHubContract = useContract(nhctHubAddr, nhcthubABI, true)

  const [burnedTotal, setBurnedTotal] = useState<any>(0)
  const [mintedTotal, setMintedTotal] = useState<any>(0)
  const [userBurned, setUserBurned] = useState<any>(0)
  const [userMinted, setUserMinted] = useState<any>(0)

  const fetchNHCTInfoBody = { "query": `{  hubs{   minted \n claimed \n burned}\n users(where:{id:"${account?.toLowerCase()}"}){  whitelist \n  minted \n  claimed \n burned }}`, "variables": null }
  const { run: fetchNHCTInfo, data: nhctInfo } = useRequest(
    () => fetch(addrConfig.avax.nhctHubGraph, {
      method: 'POST',
      body: JSON.stringify(fetchNHCTInfoBody)
    }), { manual: true, pollingInterval: 5000 })

  useEffect(() => {
    if (chainId && account) {
      fetchNHCTInfo()
    }
  }, [chainId, account, fetchNHCTInfo])

  const showNHCTInfo = async (data) => {
    const resText = await data.text()
    const res = JSON.parse(resText)
    const hubsData = res?.data?.hubs[0]
    const usrsData = res?.data?.users[0]
    // console.log('---res', res)
    if (hubsData) {
      if (hubsData.burned) {
        setBurnedTotal(formatEther(hubsData?.burned))
      }
      if (hubsData?.minted) {
        setMintedTotal(formatEther(hubsData?.minted))
      }
    }
    // 避免graph 出现cors错误 拿不到用户数据
    if (usrsData !== undefined) {
      setUserBurned(formatEther(usrsData?.burned))
      setUserMinted(formatEther(usrsData?.minted))
    }
  }
  useEffect(() => {
    if (nhctInfo) {
      showNHCTInfo(nhctInfo)
    }
  }, [nhctInfo])

  return (
    <>
      <CardContainer ifMobile={ifMobile}>
        <HelpCard ifMobile={ifMobile}>
          <Flex alignItems={ifMobile ? 'center' : 'flex-start'} width='100%' flexDirection='column' pt='30px' pl={ifMobile ? '0' : '21px'} pr={ifMobile ? '0' : '21px'} >
            <TextContainer mb='20px' fontSize='32px'>How to convert?</TextContainer>

            <Flex width='100%' >
              <InfoBlock2>
                <InfoBlockTitle>1. <NavLink to='/stake' ><Button variant="secondary" scale="sm">Stake</Button></NavLink> HCT to get sHCT</InfoBlockTitle>
              </InfoBlock2>
            </Flex>
            <Flex width='100%' >
              <InfoBlock2>
                <InfoBlockTitle>2. <NavLink to='/lock' ><Button variant="secondary" scale="sm">Lock</Button></NavLink> sHCT</InfoBlockTitle>
              </InfoBlock2>
            </Flex>
            <Flex width='100%' >
              <InfoBlock>
                <InfoBlockTitle>3. Burn HCT to get NHCT</InfoBlockTitle>
              </InfoBlock>
            </Flex>
          </Flex>
        </HelpCard>
      </CardContainer>
      <CardContainer ifMobile={ifMobile} style={{ "marginTop": "30px" }}>
        <Card ifMobile={ifMobile}>
          <Flex alignItems={ifMobile ? 'center' : 'flex-start'} width='100%' flexDirection='column' pt='30px' pl={ifMobile ? '0' : '21px'} pr={ifMobile ? '0' : '21px'} >
            <TextContainer mb='20px' fontSize='32px'>Information</TextContainer>

            <Flex width='100%' flexDirection={ifMobile ? 'column' : 'row'}>
              <InfoBlock>
                <InfoBlockTitle>Total HCT burned</InfoBlockTitle>
                <InfoBlockContent>{new BigNumber(burnedTotal).toFormat(4)} HCT</InfoBlockContent>
              </InfoBlock>
              <InfoBlock>
                <InfoBlockTitle>Total NHCT minted </InfoBlockTitle>
                <InfoBlockContent>{new BigNumber(mintedTotal).toFormat(4)} NHCT</InfoBlockContent>
              </InfoBlock>
            </Flex>
            <Flex width='100%' flexDirection={ifMobile ? 'column' : 'row'}>
              <InfoBlock>
                <InfoBlockTitle>Your burned HCT</InfoBlockTitle>
                <InfoBlockContent>{new BigNumber(userBurned).toFormat(4)} HCT</InfoBlockContent>
              </InfoBlock>
              <InfoBlock>
                <InfoBlockTitle>Your minted NHCT</InfoBlockTitle>
                <InfoBlockContent>{new BigNumber(userMinted).toFormat(4)} NHCT</InfoBlockContent>
              </InfoBlock>
            </Flex>
            <Flex width='100%' >
              <InfoBlock>
                <InfoBlockTitle>Note</InfoBlockTitle>
                <InfoBlockTip>
                  <ol >
                    <li>Allocation equals to your locked sHCT amount * 0.3</li>

                    <li>Users who locked sHCT before 11071850 block height could convert HCT to NHCT at a 1:1 as the initial offering reward. 
                      Users who locked sHCT after 11071850 block height could convert HCT based on a specific discount.
                      <br/>
                      <a target='_blank'  rel="noreferrer noopener" href="https://hurricaneswap.medium.com/2nd-generation-of-nhct-is-coming-9611f7a1d0ae" style={{textDecoration:'underline'}}>Learn more </a>about the detail of converting.
                        
                      <Tips>*Caution. This will burn your HCT Token.</Tips>
                        
                    </li>

                    <li>The minimal convert amount is 1 HCT.</li>
                    <li>If you have the Initial Quota, <NavLink to='/convert1ratio'  style={{textDecoration:'underline'}}>click here to convert NHCT with a 1:1 ratio.</NavLink></li>
                  </ol>
                </InfoBlockTip>
              </InfoBlock>
            </Flex>
          </Flex>
        </Card>
      </CardContainer>
    </>)
}

export default ConvertInfo
