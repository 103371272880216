import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import {
  updateAllowPolling,
  updateAllowSwap,
  updateAllowLiquidity,
  updateAllowStation,
  updateStationChainId,
  updateLastUpdateTime,
  updateSync,
  updateError
} from './actions'

export function useStationState (): AppState['station'] {
  return useSelector<AppState, AppState['station']> ((state) => state.station)
}


export function useStationActionHandlers (): {
  onUpdateAllowPolling: (status: boolean) => void
  onUpdateAllowLiquidity: (status: boolean) => void
  onUpdateAllowSwap: (status: boolean) => void
  onUpdateAllowStation: (status: boolean) => void
  onUpdateStationChainId: (status: number) => void
  onUpdateLastUpdateTime: (status: number) => void
  onUpdateSync: (status: boolean) => void
  onUpdateError: (status: any) => void
} {
  const dispatch = useDispatch<AppDispatch> ()

  const onUpdateAllowPolling = useCallback (
    (status: boolean) => {
      dispatch (
        updateAllowPolling ({ status })
      )
    },
    [dispatch]
  )

  const onUpdateAllowSwap = useCallback (
    (status: boolean) => {
      dispatch (updateAllowSwap ({ status }))
    },
    [dispatch]
  )

  const onUpdateAllowLiquidity = useCallback (
    (status: boolean) => {
      dispatch (updateAllowLiquidity ({ status }))
    },
    [dispatch]
  )
  const onUpdateAllowStation = useCallback (
    (status: boolean) => {
      dispatch (updateAllowStation ({ status }))
    },
    [dispatch]
  )
  const onUpdateStationChainId = useCallback (
    (id: number) => {
      dispatch (updateStationChainId ({ id }))
    },
    [dispatch]
  )
  const onUpdateLastUpdateTime = useCallback (
    (t: number) => {
      dispatch (updateLastUpdateTime ({ t }))
    },
    [dispatch]
  )
  const onUpdateSync = useCallback (
    (status: boolean) => {
      dispatch (updateSync ({ status }))
    },
    [dispatch]
  )
  const onUpdateError = useCallback (
    (status: any) => {
      dispatch (updateError ({ status }))
    },
    [dispatch]
  )

  return {
    onUpdateAllowPolling,
    onUpdateAllowSwap,
    onUpdateAllowLiquidity,
    onUpdateAllowStation,
    onUpdateStationChainId,
    onUpdateLastUpdateTime,
    onUpdateSync,
    onUpdateError
  }
}
