import { ChainId, JSBI, Percent, Token, WETH } from '@glhf-libs/sdk'

const IFMAINNET = process.env.REACT_APP_ENV === 'MAIN'

export const CHAIN_ID_AVAX = IFMAINNET ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET
export const CHAIN_ID_BSC = IFMAINNET ? ChainId.BSC_MAINNET : ChainId.BSC_TESTNET

export const baseChainID = IFMAINNET ? 43114 : 43113;
export const baseRpc = IFMAINNET ? 'https://api.avax.network/ext/bc/C/rpc' : 'https://api.avax-test.network/ext/bc/C/rpc'
export const bscBaseRpc = IFMAINNET ? 'https://bsc-dataseed1.ninicoin.io/' : 'https://data-seed-prebsc-2-s3.binance.org:8545/'
export const maxAllowance = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

// 废弃
export const WHITE_LIST = ['0x7C947ce72169cA14Fc110c135fFc7E0f2e40942D', '0xFaCBA6e9D7037EBe0Ac8831ce90952a06323B662', '0x5EB1Ed2f5723A09111151E6A51b3d266Bd135f76', '0xF194c424eb10Ecc81b65c0328542278545776EF0', '0x52b4e705eAA31662eCb86B12f8581ACcDff98789']

// bsc
export const BSC_ROUTER_ADDRESS = IFMAINNET ? '0x5098328EAD41F41614468Aa5e6dd57e67aC4304D' : '0xA1E17B0fBEf89f0692D951e9D64Ca35C90962121'
// AVAX
export const AVAX_ROUTER_ADDRESS = IFMAINNET ? '0xb9a9BB6CC39387548BAA7185fbFf51d47eef8771' : '0x27578f619497eB7F5e86ff23e2b31b31A0f6a7A0'
// heco
export const HECO_ROUTER_ADDRESS = IFMAINNET ? '' : '0x72cc27Eb3AEdf2CCAe8Eb28793C56E4fEda955FC'
// default avax
export const ROUTER_ADDRESS = IFMAINNET ? '0xb9a9BB6CC39387548BAA7185fbFf51d47eef8771' : '0x27578f619497eB7F5e86ff23e2b31b31A0f6a7A0'

export const BSC_LP_HUB = IFMAINNET ? '0x7009b3619d5ee60d0665BA27Cf85eDF95fd8Ad01' : '0x3Cc35D69B1e2113B4B40e5441Da401F25561786e'
export const LP_REMOTE = IFMAINNET ? '0x4691aF9D439df39473B1698076793a975Ebc497f' : '0x695b0D1fDb7658966BE0E7dA9094AC8E999f0ff3'


export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}


const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSC_MAINNET]: [WETH[ChainId.BSC_MAINNET]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET]],
  [ChainId.HECO_MAINNET]: [WETH[ChainId.HECO_MAINNET]],
  [ChainId.HECO_TESTNET]: [WETH[ChainId.HECO_TESTNET]],
  [ChainId.AVAX_MAINNET]: [WETH[ChainId.AVAX_MAINNET]],
  [ChainId.AVAX_TESTNET]: [WETH[ChainId.AVAX_TESTNET]],
}

export const HCT = IFMAINNET
  ? new Token(ChainId.AVAX_MAINNET, '0x45C13620B55C35A5f539d26E88247011Eb10fDbd', 18, 'HCT', 'HCT')
  : new Token(ChainId.AVAX_TESTNET, '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a', 18, 'HCT', 'HCT')
export const AUSDT = IFMAINNET
  ? new Token(ChainId.AVAX_MAINNET, "0xfbbc6be26e1712685848b7194365600513cf73ca", 18, "aUSDT", "aUSDT")
  : new Token(ChainId.AVAX_TESTNET, "0xC40f16B3DF9b2E11f48B11A535ad9ab55b7dbF67", 18, "aUSDT", "aUSDT")
export const USDTE = new Token(ChainId.AVAX_MAINNET, "0xc7198437980c041c805A1EDcbA50c1Ce5db95118", 6, "USDT.e", "USDT.e");

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET]],
  [ChainId.AVAX_TESTNET]: [HCT, AUSDT],
  [ChainId.AVAX_MAINNET]: [HCT, WETH[ChainId.AVAX_MAINNET], AUSDT, USDTE]
}

export const LTC = new Token(ChainId.HECO_TESTNET, '0x9cd0709FD68AFf65F047482d1407C19E5b385043', 18, 'LTC', 'LTC')
export const USDT = new Token(ChainId.HECO_TESTNET, '0xd6AA3269A1C06cB7a310A21AED06618D907e2008', 18, 'USDT', 'USDT')
export const HUSD = new Token(ChainId.HECO_TESTNET, '0xC17aa992Eec7b9d9b58e8175282Ae68C416F7118', 18, 'HUSD', 'HUSD')
export const BTC = new Token(ChainId.HECO_TESTNET, '0x86586e75E0b181999B1dCD620e58c560245826b8', 18, 'BTC', 'BTC')
export const DOT = new Token(ChainId.HECO_TESTNET, '0xE754BB537021c097239f7203e9a37fC059982304', 18, 'DOT', 'DOT')
export const FIL = new Token(ChainId.HECO_TESTNET, '0x838030272f7Cc9bb706cDaa2d20ebC9Ed246CB79', 18, 'FIL', 'FIL')

export const PNG = new Token(ChainId.AVAX_TESTNET, ZERO_ADDRESS, 18, 'PNG', 'Pangolin')
export const DAI = new Token(ChainId.AVAX_TESTNET, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin')
export const UNI = new Token(ChainId.AVAX_TESTNET, ZERO_ADDRESS, 18, 'UNI', 'Uniswap')
export const TOKENC = new Token(ChainId.BSC_MAINNET, '0x354a5de0b32ed7f34254ac2f9a1ddf61db19684b', 18, 'TokenC', 'TokenC')
/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
  [ChainId.AVAX_TESTNET]: {},
  [ChainId.AVAX_MAINNET]: {}
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], HUSD, BTC, DOT, FIL],
  [ChainId.AVAX_TESTNET]: [HCT],
  [ChainId.AVAX_MAINNET]: [...WETH_ONLY[ChainId.AVAX_MAINNET]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], HUSD, BTC, DOT, FIL],
  [ChainId.AVAX_TESTNET]: [...WETH_ONLY[ChainId.AVAX_TESTNET], HCT],
  [ChainId.AVAX_MAINNET]: [...WETH_ONLY[ChainId.AVAX_MAINNET], HCT]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [],
  [ChainId.AVAX_TESTNET]: [],
  [ChainId.AVAX_MAINNET]: []
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 100
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

export const defaultChainConfig = IFMAINNET ? [
  {
    chainId: 56,
    disabled: false,
    src: "/images/coins/BSC.svg",
    chainName: 'Binance Smart Chain ',
    shortName: 'BSC'
  },
  {
    chainId: 1,
    disabled: true,
    src: "/images/coins/polygon.svg",
    chainName: 'Polygon',
    shortName: 'Matic'
  },
  {
    chainId: 1,
    disabled: true,
    src: "/images/coins/solana.svg",
    chainName: 'Solana',
    shortName: 'Sol'
  },
  {
    chainId: 1,
    disabled: true,
    src: "/images/coins/ETH.svg",
    chainName: 'Ethereum',
    shortName: 'ETH'
  }
] : [
  {
    chainId: 97,
    disabled: false,
    src: "/images/coins/BSC.svg",
    chainName: 'Binance Smart Chain TEST',
    shortName: 'BSC'
  },
  {
    chainId: 1,
    disabled: true,
    src: "/images/coins/polygon.svg",
    chainName: 'Polygon',
    shortName: 'Matic'
  },
  {
    chainId: 1,
    disabled: true,
    src: "/images/coins/solana.svg",
    chainName: 'Solana',
    shortName: 'Sol'
  },
  {
    chainId: 1,
    disabled: true,
    src: "/images/coins/ETH.svg",
    chainName: 'Ethereum',
    shortName: 'ETH'
  }
]


export const lpBridgeDefaultChainConfig = IFMAINNET ? [
  {
    chainId: 56,
    disabled: false,
    src: "/images/coins/BSC.svg",
    chainName: 'Binance Smart Chain ',
    shortName: 'BSC'
  }
] : [
  {
    chainId: 97,
    disabled: false,
    src: "/images/coins/BSC.svg",
    chainName: 'Binance Smart Chain TEST',
    shortName: 'BSC'
  }
]
