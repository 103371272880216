import { currencyEquals, Trade, JSBI, ETHER } from '@glhf-libs/sdk'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useInterval } from 'ahooks'
import Column from 'components/Column'
import { Flex, Text, Button } from '@hurricaneswap/uikit'
import { formatEther, parseEther } from '@ethersproject/units'
import { Dots } from 'components/swap/styleds'
import { StyledBnbLogo } from 'components/CurrencyLogo'
import QuestionHelper from 'components/QuestionHelper'
import TranslatedText from 'components/TranslatedText'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import useMobile from 'hooks/useMobile'
import { Field } from 'state/swap/actions'
import CrossChianProgress from 'components/CrossChianProgress'
import { defaultChainConfig } from 'constants/index'
import TransactionConfirmationModal, {
  TransactionErrorContent
} from '../TransactionConfirmationModal'
import { useActiveWeb3React } from '../../hooks'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import { Wrapper, Section, ContentHeader, BottomSection } from './help'

const StakeTipsWrap = styled.div`
  margin-top:10px;
  display:flex;
`
const StakeTips = styled.div`
  color:rgb(100, 37, 197)
`
const StakeTipsIcon = styled.div`
    vertical-align: middle;
`
const StakeTipsText = styled.div`
  font-size:12px;
`


const step1LeastConfirm = 15
const step3LeastConfirm = 1



const ToolTip = () => {
  return (
    <div style={{ fontSize: '14px', maxWidth: '355px', width: '100%' }}>
      DO NOT transfer your Cross-Chain LP Tokens to others or buy from others; otherwise, you will suffer a financial loss!  <br />
      The Cross-Chain LP tokens such as bHc-CAKE/USDT act as Proof of Deposit, and the withdrawal quota depends on how much the user deposited earlier. <br />
      You can only withdraw no more than the amount you deposited earlier.
    </div>
  )
}


/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  )
}

const RightArrow = () => {
  const { isDark } = useTheme()
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 20H27" stroke={isDark ? '#fff' : '#4D3C55'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const Divider = styled.div`
margin: 16px 0;
width: 100%;
height: 1px;
background: ${({ theme }) => (theme.colors.tokenInfoBorder)};
`
const StyledText = styled(Text)`
@media (max-width: 320px) { 
  font-size: 20px;
  margin-bottom: -10px
}
`
const ContentWrap = styled.div`
  margin-top:30px;
`

export const getSubStr = (str: any) => {
  if (str) {
    const subStr1 = str.substr(0, 12);
    const subStr2 = str.substr(str.length - 10, 10);
    return `${subStr1}...${subStr2}`
  }
  return str
}

const NetworkTransferDir = ({ quoteChainId, baseChainId, from, to }) => {
  const { isDark } = useTheme()
  const { chainId } = useActiveWeb3React()
  const baseChainIdSrc = useMemo(() => {
    const filtered = defaultChainConfig.filter(i => i?.chainId === baseChainId)
    if (filtered.length > 0) {
      return filtered[0].src
    }
    return '/images/coins/AVAX.svg'
  }, [baseChainId])

  const quoteChainIdSrc = useMemo(() => {
    const filtered = defaultChainConfig.filter(i => i?.chainId === quoteChainId)
    if (filtered.length > 0) {
      return filtered[0].src
    }
    return '/images/coins/AVAX.svg'
  }, [quoteChainId])
  function getTokenChain(selectResult) {
    let chain
    if (selectResult === 97 || selectResult === 56) {
      chain = 'BSC'
    } else if (selectResult === 43114 || selectResult === 43113) {
      chain = 'Avalanche'
    }
    return chain
  }
  return (
    <Flex>
      <Flex flex={1} flexDirection='column'>
        <Text mb='12px' color={isDark ? 'rgba(255, 255, 255, 0.65)' : '#8A7F90'}>From</Text>
        <Flex height='40px' alignItems='center' justifyContent='flex-start'>
          <StyledBnbLogo src={baseChainIdSrc} size='24px' />
          <Text ml='10px'>{getTokenChain(baseChainId)}</Text>
        </Flex>
      </Flex>
      {/* <Flex alignSelf='flex-end'>
                <RightArrow />
            </Flex> */}
      <Flex flex={1} flexDirection='column'>
        <Text mb='12px' color={isDark ? 'rgba(255, 255, 255, 0.65)' : '#8A7F90'}>to</Text>
        <Flex height='40px' alignItems='center' justifyContent='flex-start'>
          <StyledBnbLogo src={quoteChainIdSrc} size='24px' />
          <Text ml='10px'>{getTokenChain(quoteChainId)}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default function ConfirmBridgeModal({
  confirmationData,
  account,
  parsedAmounts,
  // trade,
  from,
  to,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  type,
  baseChainId,
  quoteChainId,
  pendingText = 'Loading',
  forceChainId,
  fee,
  showProcess,
  syncResult
}: {
  confirmationData: any
  baseChainId: number | undefined,
  quoteChainId: number | undefined,
  account: string | undefined | null
  parsedAmounts: any
  isOpen: boolean
  from: string | undefined
  to: any | undefined
  originalTrade: Trade | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: number
  onAcceptChanges: () => void
  onConfirm: () => void
  swapErrorMessage: any | undefined
  onDismiss: () => void
  type: string
  pendingText?: string
  forceChainId?: any
  fee: any
  showProcess: any
  syncResult: any
}) {
  const amountIn = useMemo(() => {
    if (parsedAmounts && parsedAmounts[Field.INPUT]) {
      return parsedAmounts[Field.INPUT].toSignificant(18)
    }
    return 0
  }, [parsedAmounts])

  const [interval, setInterval] = useState(3000);
  const [confirmations, setConfirmations] = useState(0)
  const { chainId } = useActiveWeb3React()
  const { isDark } = useTheme()
  const ifMobile = useMobile()
  const baseCoin = useBaseCoin(ETHER, chainId)
  const [step3confirmations, setStep3confirmations] = useState(0)

  useInterval(() => {
    if (confirmationData) {
      confirmationData.wait().then(res => {
        if (res?.confirmations >= step1LeastConfirm) {
          setInterval(undefined)
          setConfirmations(step1LeastConfirm)
        }
        setConfirmations(res?.confirmations > step1LeastConfirm ? step1LeastConfirm : res?.confirmations)
      })
    }
  }, interval)

  const step = useMemo(() => {
    if (confirmations >= step1LeastConfirm && !txHash) {
      return 2
    }
    if (confirmations >= step1LeastConfirm && txHash) {
      return 3
    }
    return 1
  }, [confirmations, txHash])

  /* const step3confirmations = useMemo(() => {
      if (step === 3) { 
              return 1
      }
      return 0
  }, [step]) */

  useEffect(
    () => {
      if (step === 3) {
        setTimeout(() => {
          setStep3confirmations(1)
        }, 2000)
      }
      setStep3confirmations(0)
    }, [step])

  const status = useMemo(() => {
    if (syncResult === 'timeout') {
      return 'timeout'
    }
    // todo 一个确认区块，即读取到txhash就成功
    if (step === 3) {
      return 'success'
    }
    return 'loading'
  }, [step, syncResult])

  const title = useMemo(() => {
    if (syncResult === "timeout") {
      return "Network congestion"
    }
    if (syncResult === "error") {
      return "Failed"
    }
    if (syncResult === "success" && step === 3 && status === 'success') {
      return "Complete"
    }
    return "Synchronizing"
  }, [step, status, syncResult])

  const syncTip = useMemo(() => {
    if (syncResult === "timeout") {
      return "Oops, It seems the network jammed. Check your assets in wallet later."
    }
    if (syncResult === "error") {
      return "The fund will be sent back to your wallet, try again."
    }
    if (syncResult === "success" && step === 3 && status === 'success') {
      return "Do not transfer your LP token to others!"
    }
    return "It may take up to 5 mins, depending on the network status."
  }, [step, status, syncResult])

  const finalHash = useMemo(() => {
    if (step === 3 && status === 'success') {
      return txHash
    }
    return null
  }, [step, status, txHash])

  const dismiss = useCallback(() => {
    setInterval(3000)
    setConfirmations(0)
    onDismiss()
  }, [onDismiss])

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={dismiss} message={swapErrorMessage} />
      ) : showProcess ? (
        <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
          <Section style={{ padding: '51px 24px 0' }}>
            {
              (step === 3 && status === 'success') ? (
                <ContentHeader onDismiss={dismiss}>{title}</ContentHeader>
              ) : (
                <ContentHeader >{title}</ContentHeader>
              )
            }

            <ContentWrap>
              <CrossChianProgress
                tooltipText={<ToolTip />}
                forceChainId={forceChainId}
                hash={finalHash}
                tips={syncTip}
                from={{ symbol: from, chainId: baseChainId }}
                to={{ symbol: to, chainId: quoteChainId }}
                middleContent="Hurricane Bridge"
                step1LeastConfirm={step1LeastConfirm}
                step3LeastConfirm={step3LeastConfirm}
                step={step}
                step1Confirm={confirmations}
                step3Confirm={step3confirmations}
                status={status}
              />
            </ContentWrap>
          </Section>
        </Wrapper>) :
        (
          <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
            <Section style={{ paddingTop: '51px' }}>
              <ContentHeader onDismiss={dismiss}>Confirm</ContentHeader>
              {/* {modalHeader()} */}
            </Section>

            <Section style={{ fontSize: '16px', width: '100%' }}>
              <NetworkTransferDir baseChainId={baseChainId} quoteChainId={quoteChainId} from={from} to={to} />
              <Flex mt='10px' alignItems='center' justifyContent='space-between'>
                <Text color='#8A7F90'>Bridge Fee:</Text>
                <Text color='#8A7F90' textAlign='right'>{fee ? formatEther(fee) : <Dots>loading</Dots>} {baseCoin}</Text>
              </Flex>
              <Divider />
              {/* amount */}
              <Flex flexDirection='column'>
                <Text >You will receive</Text>
                <Flex flexWrap="wrap">
                  <StyledText fontSize='32px' color={isDark ? 'rgba(255, 255, 255, 0.85)' : '#67586E'}>{amountIn}&nbsp;</StyledText>
                  <StyledText fontSize='32px' color={isDark ? 'rgba(255, 255, 255, 0.85)' : '#67586E'}>{to}</StyledText>
                </Flex>
              </Flex>
              {/* address */}
              <Flex flexDirection='column' mt='20px'>
                <Text >Receive address</Text>
                <Text color={isDark ? '#8A7F90' : '#8A7F90'}>{ifMobile ? getSubStr(account) : account}</Text>
              </Flex>
              {/* <Divider /> */}
              {/* fee */}
              <Button mt='16px'
                onClick={onConfirm}
                width='100%'
              >
                <TranslatedText translationId={100}>
                  {type}
                </TranslatedText>
              </Button>
              <StakeTipsWrap>
                <StakeTips>*Do not transfer your LP token to others!</StakeTips>
                <StakeTipsIcon>
                  <QuestionHelper
                    placement="top"
                    text={
                      <StakeTipsText>
                        DO NOT transfer your Cross-Chain LP Tokens to others or buy from others; otherwise, you will suffer a financial loss!
                        <br />
                        The Cross-Chain LP tokens such as bHc-CAKE/USDT act as Proof of Deposit, and the withdrawal quota depends on how much the user deposited earlier.
                        <br />
                        You can only withdraw no more than the amount you deposited earlier.
                      </StakeTipsText>
                    }
                  />
                </StakeTipsIcon>
              </StakeTipsWrap>
            </Section>

            {/* <BottomSection style={{ flex: 1 }} gap="12px">{modalBottom()}</BottomSection> */}
          </Wrapper >
        ),
    [dismiss, forceChainId, finalHash, syncTip, title, status, step3confirmations, step, confirmations, showProcess, fee, baseChainId, quoteChainId, baseCoin, type, from, to, onConfirm, swapErrorMessage, amountIn, account, isDark, ifMobile]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={dismiss}
      attemptingTxn={false}
      hash={undefined}
      content={confirmationContent}
      pendingText={pendingText}
      forceChainId={forceChainId}
    />
  )
}
