import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { JSBI, Pair, Percent } from '@glhf-libs/sdk'
import useRequest from '@ahooksjs/use-request'
import { Button, Card as UIKitCard, CardBody, Text } from '@hurricaneswap/uikit'
import BigNumber from 'bignumber.js'

import { darken } from 'polished'
import { maxAllowance } from 'constants/index'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import { useMigrateContract, useTokenContract } from 'hooks/useContract'
import { useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { calculateSlippageAmount } from 'utils'
import { NormalStyleInput as NumericalInput } from '../NumericalInput'

import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import Card from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { Dots } from '../swap/styleds'
import { AppState } from '../../state'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import useTheme from '../../hooks/useTheme'

// import { BsHurricane } from 'react-icons/bs'

export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`
export const VersionWrap = styled(RowBetween)`
  height: 24px;
  border:1px solid ${({ theme }) => (theme.colors.secondary)};
  color:${({ theme }) => (theme.colors.secondary)};
  padding:0 12px;
  font-size:12px;
`
export const HoverCard = styled(Card)`
  border: 1px solid ${({ theme }) => { return theme.isDark ? '#fff' : 'rgba(0,0,0,0.2)' }};
  background:rgba(76, 35, 118, 0.04);
  :hover {
    /*border: 1px solid ${({ theme }) => darken(0.06, theme.colors.invertedContrast)};*/
    background:  ${({ theme }) => { return theme.isDark ? 'rgb(142, 137, 167)' : 'rgba(255, 255, 255, 0.6)' }};
    border:1px solid rgba(0, 0, 0, 0.12);
  }
`
// rgba(168 153 239 0.21)
const ShowCard = styled(Card)`
  background:${({ theme }) => theme.colors.invertedContrast};
  border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.colors.invertedContrast)};
  }
`
const MigrateOperateCard = styled(Card)`
margin-top:24px;
padding:0.75rem 1rem;
background:${({ theme }) => theme.colors.invertedContrast};
border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
:hover {
  border: 1px solid ${({ theme }) => darken(0.06, theme.colors.invertedContrast)};
}
`

/**
 border: 1px solid #f00;
box-shadow: 0px 0px 3px #f9b9b9;
 */
const CrossChainTip = styled.div`
color:${({ theme }) => { return theme.isDark ? "#fff" : "#6425C5" }};
padding-top:20px;
`
interface PositionCardProps {
  pair: Pair
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: any
  // eslint-disable-next-line react/no-unused-prop-types
  tips?: string
}
export function MinimalPositionCard({ pair, showUnwrapped = false, tips = '' }: PositionCardProps) {
  const { account, chainId } = useActiveWeb3React()
  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const currency0symbol = useBaseCoin(currency0, chainId)
  const currency1symbol = useBaseCoin(currency1, chainId)


  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]

  const { push } = useHistory()
  const routerToAdd = () => {
    push(`/add/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`)
  }
  const routerToRemove = () => {
    push(`/remove/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`)
  }
  return (
    <>
      {userPoolBalance && (
        <UIKitCard style={{ zIndex: 5 }}>
          <CardBody p='24px 24px 48px'>
            <AutoColumn gap="12px">
              <FixedHeightRow>
                <RowFixed>
                  <Text style={{ textTransform: 'uppercase', fontWeight: 600 }} fontSize="14px" color="textSubtle">
                    LP Tokens in your Wallet
                  </Text>
                </RowFixed>
              </FixedHeightRow>
              <FixedHeightRow onClick={() => setShowMore(!showMore)}>
                <RowFixed>
                  <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
                  <Text fontSize="14px">
                    {currency0symbol}/{currency1symbol}
                  </Text>
                </RowFixed>
                <RowFixed>
                  <Text fontSize="14px">{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</Text>
                </RowFixed>
              </FixedHeightRow>
              <AutoColumn gap="4px">
                <FixedHeightRow>
                  <Text fontSize="14px">{currency0symbol}:</Text>
                  {token0Deposited ? (
                    <RowFixed>
                      <Text ml="6px" fontSize="14px">
                        {token0Deposited?.toSignificant(6)}
                      </Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </FixedHeightRow>
                <FixedHeightRow>
                  <Text fontSize="14px">{currency1symbol}:</Text>
                  {token1Deposited ? (
                    <RowFixed>
                      <Text ml="6px" fontSize="14px">
                        {token1Deposited?.toSignificant(6)}
                      </Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </FixedHeightRow>

              </AutoColumn>
            </AutoColumn>
            {tips && (<CrossChainTip>{tips}</CrossChainTip>)}
          </CardBody>
        </UIKitCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair }: PositionCardProps) {
  const { account, chainId } = useActiveWeb3React()
  const { theme } = useTheme()
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const currency0symbol = useBaseCoin(currency0, chainId)
  const currency1symbol = useBaseCoin(currency1, chainId)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  let displayTokenPercent = poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-';
  if (poolTokenPercentage && poolTokenPercentage.toFixed(2) === "0.00") {
    displayTokenPercent = "<0.01%";
  }

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]
  const onlyOnAvax = useOnlyOnAvax()

  return (
    <HoverCard style={showMore ? (theme.isDark ? { background: '#8e89a7' } : { background: '#fff' }) : {}} className={theme.isDark}>
      <AutoColumn gap="12px">
        <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
            <Text>{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0symbol}/${currency1symbol}`}</Text>
          </RowFixed>
          <RowFixed>
            <VersionWrap> Hurricane-V2 </VersionWrap>
            {showMore ? (
              <ChevronUp size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency0symbol}:</Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text ml="6px">{token0Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency1symbol}:</Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text ml="6px">{token1Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool tokens:</Text>
              <Text>{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool share:</Text>
              <Text>{displayTokenPercent}</Text>
            </FixedHeightRow>

            {
              !onlyOnAvax && (
                <RowBetween marginTop="10px">
                  <Button as={Link} to={`/add/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`} style={{ width: '48%' }}>
                    Add
                  </Button>
                  <Button
                    as={Link}
                    style={{ width: '48%' }}
                    to={`/remove/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`}
                  >
                    Remove
                  </Button>
                </RowBetween>
              )
            }


          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard>
  )
}

export function StationFullPositionCard({ loading = false, pair }: PositionCardProps) {
  const { account, chainId } = useActiveWeb3React()

  const tokens = useMemo(() => {
    if (pair?.token0.symbol === 'USDT') {
      return [pair.token1, pair.token0]
    }
    return [pair.token0, pair.token1]
  }, [pair])

  const currency0 = unwrappedToken(tokens[0])
  const currency1 = unwrappedToken(tokens[1])
  const currency0symbol = useBaseCoin(currency0, chainId)
  const currency1symbol = useBaseCoin(currency1, chainId)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]

  const { allowStation, allowSwap, allowLiquidity, stationChainId } = useSelector<AppState, AppState['station']>((state) => state.station)

  const { push } = useHistory()

  const routerToAdd = () => {
    push(`/stationadd/${stationChainId}/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`)
  }
  const routerToRemove = () => {
    push(`/stationremove/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`)
  }

  return (
    <HoverCard>
      <AutoColumn gap="12px">
        <FixedHeightRow onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
            <Text>{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0symbol}/${currency1symbol}`}</Text>
          </RowFixed>
          <RowFixed>
            {
              loading
                ? <Loader />
                : (userPoolBalance)
                  ? userPoolBalance.toSignificant(4)
                  : '-'}
            {showMore ? (
              <ChevronUp size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency0symbol}:</Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                  <Text ml="6px">{token0Deposited?.toSignificant(6)}</Text>
                </RowFixed>
              ) : (
                loading ? <Loader /> : '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency1symbol}:</Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                  <Text ml="6px">{token1Deposited?.toSignificant(6)}</Text>
                </RowFixed>
              ) : (
                loading ? <Loader /> : '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool tokens:</Text>
              <Text>{
                loading
                  ? <Loader />
                  : (userPoolBalance && +userPoolBalance?.toSignificant(4) > 0)
                    ? userPoolBalance.toSignificant(4)
                    : '-'}</Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool share:</Text>
              <Text>{poolTokenPercentage
                ? `${poolTokenPercentage.toFixed(2)}%`
                : loading ? <Loader /> : '-'}</Text>
            </FixedHeightRow>

            <RowBetween marginTop="10px">
              <Button disabled={!allowStation} onClick={routerToAdd} style={{ width: '48%' }}>
                Add
              </Button>
              <Button
                disabled={!allowStation}
                style={{ width: '48%' }}
                onClick={routerToRemove}
              >
                Remove
              </Button>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard>
  )
}



export function FullPositionCardFlat({ pair }: PositionCardProps) {
  const { account, chainId } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const currency0symbol = useBaseCoin(currency0, chainId)
  const currency1symbol = useBaseCoin(currency1, chainId)

  const [showMore, setShowMore] = useState(true)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  let displayTokenPercent = poolTokenPercentage ? `${poolTokenPercentage.toFixed(2)}%` : '-';
  if (poolTokenPercentage && poolTokenPercentage.toFixed(2) === "0.00") {
    displayTokenPercent = "<0.01%";
  }

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
      ]
      : [undefined, undefined]
  const onlyOnAvax = useOnlyOnAvax()

  return (
    <ShowCard>
      <AutoColumn gap="12px">
        <FixedHeightRow style={{ cursor: 'pointer' }}>
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
            <Text>{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0symbol}/${currency1symbol}`}</Text>
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="8px">
            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency0symbol}:</Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text ml="6px">{token0Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text>Pooled {currency1symbol}:</Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text ml="6px">{token1Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} />
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool tokens:</Text>
              <Text>{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</Text>
            </FixedHeightRow>
            <FixedHeightRow>
              <Text>Your pool share:</Text>
              <Text>{displayTokenPercent}</Text>
            </FixedHeightRow>

            {
              !onlyOnAvax && (
                <RowBetween marginTop="10px">
                  <Button as={Link} to={`/add/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`} style={{ width: '48%' }}>
                    Add
                  </Button>
                  <Button
                    as={Link}
                    style={{ width: '48%' }}
                    to={`/remove/${currencyId(currency0, chainId)}/${currencyId(currency1, chainId)}`}
                  >
                    Remove
                  </Button>
                </RowBetween>
              )
            }
          </AutoColumn>
        )}
      </AutoColumn>
    </ShowCard>
  )
}


