import { ChainId, CurrencyAmount, TokenAmount, Trade } from '@glhf-libs/sdk'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ethers } from 'ethers'
import { parseEther, formatEther } from '@ethersproject/units'
import { Button, CardBody, Flex, Text, ToastContainer } from '@hurricaneswap/uikit'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import { AutoRow } from 'components/Row'
import { ArrowWrapper, BottomGrouping, IconDecoration, Wrapper, Dots } from 'components/swap/styleds'
import { useActiveWeb3React } from 'hooks'
import PageHeader from 'components/PageHeader'
import 'css/modal.css'
import ConnectWalletButton from 'components/ConnectWalletButton'
import CurrencySelectPanel from 'components/CurrencySelectPanel'
import { useSwitchChain } from 'hooks/Station'
import TranslatedText from 'components/TranslatedText'
import { useApproveCallback, ApprovalState } from 'hooks/useApproveCallback'
import { baseRpc, BSC_LP_HUB, CHAIN_ID_AVAX, CHAIN_ID_BSC, LP_REMOTE } from 'constants/index'
import { useHubContract, useRemoteContract } from 'hooks/useContract'
import { useRequest, useTimeout } from 'ahooks'
import { ExternalLink } from 'components/Shared'
import { Field } from 'state/swap/actions'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import ConfirmBridgeModal from 'components/Lpbridge/ConfirmBridgeModal'
import { getBscScanLink } from 'utils'
import { useTokenBalance } from 'state/wallet/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import AppBody from '../AppBody'

const defaultBridgeChainId = CHAIN_ID_BSC
const outputDefaultBridgeChainId = CHAIN_ID_AVAX

const avaxChainConfig = [
  {
    chainId: 43114,
    disabled: false,
    src: "/images/coins/AVAX.svg",
    chainName: 'Avalanche Mainnet C-Chain',
    shortName: 'AVAX'
  }
]
const StyledCardBody = styled(CardBody)`
  @media (max-width: 320px) { 
    padding: 21px 21px 12px;
  }
`
const Notice = styled(Text)`
  font-size: 12px;
  color:${({ theme }) => theme.colors.darkText};
  margin-top:8px;
  text-align:center
  
`

const SwapIcon = () => {
  return (<svg style={{ position: 'relative', zIndex: 0 }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F1EEE6" />
    <path d="M20 13V27" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27 20L20 27L13 20" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

  )
}


const ButtonGroup = ({ setHeight, fee, inputError, selectResult, currency, value }) => {
  const { chainId, account } = useActiveWeb3React()
  const [approval, approveCallback] = useApproveCallback(value, BSC_LP_HUB)
  const hubContract = useHubContract(BSC_LP_HUB)
  const remoteContract = useRemoteContract(LP_REMOTE)
  const addTransaction = useTransactionAdder()
  const [syncResult, setSyncResult] = useState('loading')
  const [timeoutDelay, setTimeoutDelay] = useState(undefined)

  useTimeout(() => {
    setSyncResult('timeout')
  }, timeoutDelay)

  // todo 配置读取rpc
  const provider = new ethers.providers.JsonRpcProvider(baseRpc)

  // requestBackCrossTask
  const { run, data, error, loading }: any = useRequest((address, amount, _fee) => hubContract.crossLPToken(address, amount, { value: _fee }), { manual: true })

  const onlyOnAvax = useMemo(() => {
    if (chainId) {
      return chainId === ChainId.AVAX_MAINNET || chainId === ChainId.AVAX_TESTNET
    }
    return false
  }, [chainId])

  const login = useSwitchChain()
  const handleSwitchNetwork = () => {
    login(defaultBridgeChainId)
  }

  const [toasts, setToasts]: any = useState([]);
  const onRemove = () => { setToasts([]) }
  const rawValue = useMemo(() => {
    if (value && value?.raw) {
      return value?.raw.toString()
    }
    return null
  }, [value])

  useEffect(() => {
    if (error) {
      setSwapState((prevState) => ({
        ...prevState,
        attemptingTxn: false,
        swapErrorMessage: (
          <AutoColumn style={{ padding: '5px 0 10px' }}>
            <Text mt='10px' mb='5px'>{error?.message || error?.data?.message}</Text>
          </AutoColumn>
        ),
        txHash: undefined
      }))

      const errorToast = {
        id: `id-${error?.code || error?.data?.code}`,
        title: `Error`,
        description: (
          <AutoColumn>
            <Text mt='10px' mb='10px'>{error?.message || error?.data?.message}</Text>
          </AutoColumn>
        ),
        type: 'danger',
      };

      setToasts([errorToast])
      console.error(error)
    }
  }, [error])

  const [{ showProcess, showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash, forceChainId }, setSwapState] = useState<{
    showProcess: boolean
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: any | undefined
    txHash: string | undefined
    forceChainId: any
  }>({
    showProcess: false,
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
    forceChainId: chainId,
  })
  const [confirmationData, setConfirmationData] = useState(undefined)

  const handleConfirmDismiss = useCallback(() => {
    setHeight('auto')
    setSwapState((prevState) => ({ ...prevState, showConfirm: false, showProcess: false, forceChainId: chainId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txHash, setSwapState])

  const startProcess = () => {
    setSwapState((prevState) => ({ ...prevState, attemptingTxn: false, txHash: undefined, showProcess: true }))
  }
  const closeProcess = () => {
    setSwapState((prevState) => ({ ...prevState, showProcess: false }))
  }

  const requestBridge = () => {
    setHeight('570px')
    setSyncResult('loading')
    setConfirmationData(undefined)
    setSwapState({
      tradeToConfirm: undefined,
      attemptingTxn: false,
      swapErrorMessage: undefined,
      showConfirm: true,
      txHash: undefined,
      showProcess: false,
      forceChainId: chainId,
    })
  }

  const onFetchingLogs = () => {
    startProcess()
    hubContract.removeAllListeners()
    remoteContract.removeAllListeners()
    remoteContract.connect(provider).once('LogReceiveCrossTask', async (addr, remoteToken, taskId, token, amount, action) => {
      const _chainId = outputDefaultBridgeChainId
      try {
        const trans = await action.getTransaction()
        const waitRes = await trans.wait()
        const ifSuccess = waitRes.status === 1

        if (ifSuccess) {
          setSyncResult('success')
        }
        // set bridge status
        // 如果用户dismiss则不显示该窗口
        if (showConfirm) {
          setSwapState((prevState) => ({
            ...prevState,
            attemptingTxn: false,
            swapErrorMessage: ifSuccess ? undefined : (
              <AutoColumn style={{ padding: '5px 0 10px' }}>
                <Text mt='10px' mb='5px'>{`Deposit ${formatEther(amount?.toBigInt())} ${to}`}</Text>
                <ExternalLink href={getBscScanLink(_chainId, action?.transactionHash, 'transaction')}>View on Explorer</ExternalLink>
              </AutoColumn>
            ),
            txHash: ifSuccess ? action?.transactionHash : undefined,
            forceChainId: _chainId,
          }))
        }

        /* if (to) {
          // modal
          const dataToast = {
            id: `id-${action?.transactionHash}`,
            title: ifSuccess ? `Success` : 'Error',
            description: (
              <AutoColumn style={{ padding: '5px 0 10px' }}>
                <Text mt='10px' mb='5px'>{`Bridge: Deposit ${formatEther(amount?.toBigInt())} ${from}`}</Text>
                <ExternalLink href={getBscScanLink(_chainId, action?.transactionHash, 'transaction')}>View on Explorer</ExternalLink>
              </AutoColumn>
            ),
            type: ifSuccess ? 'success' : 'danger',
          };
          setToasts([dataToast])
        } */
      } catch (e) {
        setSyncResult('error')
      }
      setTimeoutDelay(undefined)
      hubContract.removeAllListeners()
      remoteContract.removeAllListeners()

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const confirmBridge = async () => {
    // const fee = await hubContract.fee()
    run(currency?.address, value.raw.toString(), fee)
    setSwapState((prevState) => ({
      ...prevState,
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined,
    }))
  }

  const baseChainId = useMemo(() => {
    return selectResult?.currency?.chainId
  }, [selectResult])

  const quoteChainId = useMemo(() => {
    return outputDefaultBridgeChainId
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectResult])

  const from = useMemo(() => {
    // const chain = getTokenChain(selectResult)
    // return `${chain}`
    if (selectResult?.tokens && selectResult?.tokens.length > 0) {
      return `${selectResult?.label}`
    }
    return null
  }, [selectResult])

  const to = useMemo(() => {
    if (selectResult?.tokens && selectResult?.tokens.length > 0) {
      return `${selectResult?.prefix[quoteChainId]}${selectResult?.tokens[0]?.symbol}/${selectResult?.tokens[1]?.symbol}`
    }
    return null
  }, [selectResult, quoteChainId])

  const pendingText = useMemo(() => {
    if (value) {
      return `Deposit ${value?.toSignificant()} ${from}`
    }
    return 'Loading'
  }, [value, from])

  useEffect(() => {
    const waitConfirm = async () => {
      const res = await data.wait()
      // 跨链请求提交
      // callback...
    }
    if (data) {
      setConfirmationData(data)
      // add transaction
      addTransaction(data, {
        summary: `Deposit ${value.toExact()} ${from}`,
      })
      // 开启监听
      setTimeoutDelay(300000)
      onFetchingLogs()
      waitConfirm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setSwapState])

  return (
    <>
      <ConfirmBridgeModal
        syncResult={syncResult}
        confirmationData={confirmationData}
        showProcess={showProcess}
        fee={fee}
        account={account}
        parsedAmounts={
          {
            [Field.INPUT]: value,
            [Field.OUTPUT]: value
          }
        }
        type='Deposit'
        isOpen={showConfirm}
        // trade={undefined}
        from={from}
        to={to}
        baseChainId={baseChainId}
        quoteChainId={quoteChainId}
        originalTrade={tradeToConfirm}
        onAcceptChanges={() => null}
        attemptingTxn={attemptingTxn}
        txHash={txHash}
        recipient={account}
        allowedSlippage={0}
        onConfirm={confirmBridge}
        swapErrorMessage={swapErrorMessage}
        onDismiss={handleConfirmDismiss}
        pendingText={`${pendingText} LP Tokens`}
        forceChainId={forceChainId}
      />

      <BottomGrouping>
        {!account
          ? (
            <ConnectWalletButton />
          ) : (
            onlyOnAvax
              ? (
                <Button id="switch-network-button" onClick={handleSwitchNetwork} style={{ width: '100%' }}>
                  <TranslatedText translationId={100}>Switch Network</TranslatedText>
                </Button>
              )
              : rawValue > 0 ?
                inputError ? (
                  <Button
                    disabled
                    // onClick={requestBridge}
                    width='100%'
                  >{inputError}</Button>
                ) : (
                  approval === ApprovalState.APPROVED
                    ? (
                      <Button
                        onClick={requestBridge}
                        width='100%'
                      >Deposit</Button>
                    )
                    : (
                      <Button
                        width='100%'
                        onClick={approveCallback}
                        // variant={approval === ApprovalState.APPROVED}
                        disabled={approval !== ApprovalState.NOT_APPROVED}
                        mr="16px"
                      >
                        {approval === ApprovalState.PENDING
                          ? (
                            <Dots>Approving</Dots>
                          ) : 'Approve'
                        }
                      </Button>
                    )
                ) : (
                  <Button
                    disabled
                    // onClick={requestBridge}
                    width='100%'
                  >Deposit</Button>
                ))
        }
      </BottomGrouping >
      <ToastContainer toasts={toasts} onRemove={onRemove} />

    </>
  )
}

export default function LpBridge({ history }) {
  const [selectResult, setSelectResult] = useState(null)
  const [selectedLp, setSelectedLp] = useState(undefined)
  const [inputValue, setInputValue] = useState('')
  const { account } = useActiveWeb3React()
  const selectedBalance = useTokenBalance(account ?? undefined, selectedLp)
  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(selectedBalance)
  const hubContract = useHubContract(BSC_LP_HUB)

  const { run, data, loading, error, cancel } = useRequest(() => hubContract.fee(), { manual: true, pollingInterval: 6000 })

  useEffect(() => {
    if (account && hubContract) {
      run()
    }
  }, [account, hubContract, run])

  const handleTypeInput = useCallback(
    (value: string) => {
      setInputValue(value)
    }, [])

  const inputError = useMemo(() => {
    if (selectedBalance && +inputValue > +maxAmountInput.toExact()) {
      return 'Insufficient Balance'
    }
    return null
  }, [maxAmountInput, inputValue, selectedBalance])

  const parsedAmount = useMemo(() => {
    if (selectedLp && +inputValue > 0) {
      const typedValueParsed = parseEther(inputValue).toString()
      return new TokenAmount(selectedLp, typedValueParsed)
    }
    return null
  }, [inputValue, selectedLp])

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      setInputValue(maxAmountInput.toExact())
    }
  }, [maxAmountInput])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setSelectedLp(inputCurrency)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const login = useSwitchChain()

  useEffect(() => {
    login(defaultBridgeChainId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const feeError = useMemo(() => {
    if (error) {
      return 'Unreadable Fee'
    }
    return null
  }, [error])

  const [height, setHeight] = useState('auto')

  return (
    <>
      <AppBody>
        <Wrapper height={height} id="deposit">
          <PageHeader title="deposit" showSettings={Boolean(false)} />

          <StyledCardBody p='24px 24px 48px'>
            <AutoColumn gap="md">
              <CurrencySelectPanel
                setSelectResult={setSelectResult}
                defaultBridgeChainId={defaultBridgeChainId}
                dir="Input"
                label="Input"
                showMaxButton
                value={inputValue}
                currency={selectedLp}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                onCurrencySelect={handleInputSelect}
                id="deposit-currency-input"
              />

              <AutoColumn justify="space-between">
                <AutoRow justify='flex-end'>
                  {/* eslint-disable-next-line */}
                  <IconDecoration />
                  <ArrowWrapper clickable >
                    <SwapIcon />
                  </ArrowWrapper>
                </AutoRow>
              </AutoColumn>

              <CurrencySelectPanel
                hideBalance
                chainConfig={avaxChainConfig}
                defaultBridgeChainId={outputDefaultBridgeChainId}
                disabled
                dir="Output"
                label="Output"
                showMaxButton={false}
                value={inputValue}
                currency={selectedLp}
                onUserInput={handleTypeInput}
                onCurrencySelect={handleInputSelect}
                id="deposit-currency-output"
                selectLp={selectedLp}
                defaultBalanceValue=" "
              />
            </AutoColumn>

            <Flex mt="25px">
              <IconDecoration />
            </Flex>

            <Text color='#8A7F90' mt='12px'>Bridge Fee: {data ? `${formatEther(data)} BNB` : <Dots>loading</Dots>}</Text>
            <ButtonGroup setHeight={setHeight} fee={data} inputError={inputError || feeError} selectResult={selectResult} currency={selectedLp} value={parsedAmount} />
            <Notice>*Only LPs in HurricaneStation are supported.</Notice>
          </StyledCardBody>
        </Wrapper>
      </AppBody>

    </>
  )
}
