import { ChainId } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardBody, Flex, Text, ToastContainer } from '@hurricaneswap/uikit'
import { useLocation } from 'react-router-dom'
import { JsonRpcProvider } from '@ethersproject/providers'
import useRequest from '@ahooksjs/use-request'
import styled, { ThemeContext } from 'styled-components'
import { useContract, useTokenContract } from 'hooks/useContract'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { parseEther, formatEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'

import moment from 'moment'

import Column, { AutoColumn, ColumnCenter } from 'components/Column'
import Row, { RowBetween, AutoRow } from 'components/Row'

import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { BottomGrouping, IconDecoration, Wrapper } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import Loader from 'components/Loader'
import { useActiveWeb3React } from 'hooks'
import { useTotalSupply } from 'data/TotalSupply'
import { ApprovalState } from 'hooks/useApproveCallback'
import ConnectWalletButton from 'components/ConnectWalletButton'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import CurrencyLogo from 'components/CurrencyLogo'

import AppBody, { BodyGradiantWrapper, FixedPosition, BodyWrapper } from '../AppBody'
import '../../css/modal.css'
import { Dots } from '../../components/swap/styleds'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'
import LOCK_ABI from '../../constants/abis/lock.json'
import XHCT_ABI from '../../constants/abis/stake.json'
import STAKE_JSON_V2 from '../../constants/abis/stake-v2.json'

import { TYPE } from '../../components/Shared'

const { body: Body, italic: Italic } = TYPE

const LockModalTitle = styled.div`
font-family: GT Eesti Pro Text;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 22px;
color: ${({ theme }) => { return theme.isDark ? '#fff' : '#4D3C55' }};
padding:30px 20px 0;
`
const LockPeriod = styled.div`
margin-bottom: 20px;
`
const LockPeriodTitle = styled.div`
  color: #8A7F90;
  margin-bottom: 14px;
`
const Period = styled.div`
  background: rgba(76, 35, 118, 0.04);
  padding:24px 20px;
  border-radius:8px;
`
const StakeInfoRow = styled(RowBetween)`
  padding-bottom:8px;
`
const StakeInfoRowTitle = styled(Body)`
  color: ${({ theme }) => (theme.colors.contrast)};
  
`

// @ts-ignore
window.JsonRpcProvider = JsonRpcProvider

const maxAllowance = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
// lockContractAddr 地址需要修改2个地方，另外一个地方在src/components/LockInfo/index.tsx 0x3E69502d0b40E473d52eE1c99D000C3Cc26Ad2a5
const lockContractAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x0b6D1C3E60722123718FaF13bFc48270BD14E9A9' : '0xdcCB85D5E187E0D393AdDdA4e4416700D756376b'

const sHCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0xE4aE2E8648B8E84c4A487a559b04e884B822a350' : '0xe81d3f95c887ce9a89198312774d4274eec7e43f'
const _chainId = process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET


const sHCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": sHCTAddr,
  "decimals": 18,
  "name": "sHCT",
  "symbol": "sHCT",
  "logoURI": "https://assets.hurricaneswap.com/blockchains/43114/0xe4ae2e8648b8e84c4a487a559b04e884b822a350/logo.png"
}, [])


function Lock() {
  const { chainId, account } = useActiveWeb3React()
  const { search, pathname } = useLocation()
  const [ifSelectChainOpen, setSelectChain] = useState(true)
  const onlyOnAvax = useOnlyOnAvax()
  const login = useSwitchChain()
  const sHCTTotalSupply = useTotalSupply(sHCT)
  const SHCTContract = useContract(sHCT?.address, XHCT_ABI, true)
  const lockContract = useContract(lockContractAddr, LOCK_ABI, true)
  const theme = useContext(ThemeContext)
  const addTransaction = useTransactionAdder()
  const ButtonText = 'Lock sHCT'
  const [tokenBalance, setTokenBalance] = useState<any>('-')
  const [lockedAmount, setLockedAmount] = useState<any>('0')
  const [unlockedAmount, setUnlockedAmount] = useState<any>('0.0')
  const [totalReward, setTotalReward] = useState<any>('0.0')
  const [unlockTime, setUnlockTime] = useState<any>('-')
  const [startLockTime, setStartLockTime] = useState<any>('-')
  const [unlockPeriod, setUnlockPeriod] = useState<any>('180 days')
  const [unlockDate, setUnlockDate] = useState<any>('-')
  const [unlockBtnDisabled, setUnlockBtnDisabled] = useState<boolean>(true)

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)


  const [approval, setApproval] = useState(0)

  // useEffect(()=>{
  //   setUnlockDate()
  // },[unlockPeriod])


  const { run: getSHCTAllowance, data: sHCTAllowanceData, error: sHCTAllowanceError, loading: sHCTAllowanceLoading }
    = useRequest(() => SHCTContract?.allowance(account, lockContractAddr), { manual: true })

  const { run: approveSHCT, data: approveSHCTData, error: approveSHCTError, loading: sHCTApproveLoading }
    = useRequest(() => SHCTContract?.approve(lockContractAddr, maxAllowance), { manual: true })

  const { run: getBalance, data: getBalanceRes, error: getUserStakeError } = useRequest(() => SHCTContract?.balanceOf(account), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })
  const { run: getTotalReward, cancel: pauseQueryRewardsData, data: getTotalRewardRes, error: getTotalRewardError } = useRequest(() => lockContract?.earned(account), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const { run: getLockTime, cancel: pauseQueryLockTime, data: getLockTimeRes, error: getLockTimeError } = useRequest(() => lockContract?.lockTime(), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const { run: getStartLockTime, cancel: pauseQueryStartLockTime, data: getStartLockTimeRes, error: getStartLockTimeError } = useRequest(() => lockContract?.startLockTime(account), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })

  const { run: getLockedAmount, cancel: pauseQueryLockedAmount, data: getLockedAmountRes, error: getLockedAmountError, loading: getUnlockedAmountLoading }
    = useRequest(() => lockContract?.balanceOf(account), {
      manual: true,
      pollingInterval: 3000,
      pollingWhenHidden: false
    })

  // unlock 
  const { run: unlockToken, data: unlockTokenRes, error: unlockTokenError, loading: unlockTokenLoading }
    = useRequest(() => lockContract?.exit(), { manual: true })





  useEffect(() => {
    if (getBalanceRes) {
      // console.log('getBalanceRes', getBalanceRes, getBalanceRes.toString(), formatEther(getBalanceRes))
      // setTokenBalance((new BigNumber(getBalanceRes.toString()).div(10 ** sHCT.decimals)).toString())
      setTokenBalance(formatEther(getBalanceRes))
    }
  }, [getBalanceRes])
  useEffect(() => {
    // approveHCT()
    if (chainId && account) {
      setApproval(ApprovalState.UNKNOWN)
      getSHCTAllowance()
      getBalance()
      getTotalReward()
      getLockTime()
      getStartLockTime()
      getLockedAmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])

  useEffect(() => {
    if (sHCTApproveLoading) {
      setApproval(ApprovalState.PENDING)
    }
  }, [sHCTApproveLoading])

  useEffect(() => {
    if (approveSHCTData) {
      // todo 判断 approveHCTData?.hash===1 才成功
      approveSHCTData.wait().then(res => {
        getSHCTAllowance()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveSHCTData])

  useEffect(() => {
    if (approveSHCTError) {
      setApproval(ApprovalState.NOT_APPROVED)
    }
  }, [approveSHCTError])


  useEffect(() => {
    if (sHCTAllowanceLoading) {
      setApproval(ApprovalState.PENDING)
    }
  }, [sHCTAllowanceLoading])

  useEffect(() => {
    if (sHCTAllowanceData) {
      // console.log('sHCTAllowanceData', sHCTAllowanceData, sHCTAllowanceData.toString(), tokenBalance)
      // console.log('sHCTAllowanceData', formatEther(sHCTAllowanceData), (new BigNumber(tokenBalance)).lt(formatEther(sHCTAllowanceData)))
      if ((new BigNumber(tokenBalance)).lt(formatEther(sHCTAllowanceData))) {
        setApproval(ApprovalState.APPROVED)
      } else {
        setApproval(ApprovalState.NOT_APPROVED)
      }
    }
  }, [sHCTAllowanceData, tokenBalance])

  useEffect(() => {
    if (getTotalRewardRes) {
      // console.log('getTotalRewardRes', getTotalRewardRes, formatEther(getTotalRewardRes))
      setTotalReward(formatEther(getTotalRewardRes))
    }
  }, [getTotalRewardRes])

  useEffect(() => {
    if (getStartLockTimeRes) {
      // console.log('getStartLockTimeRes', getStartLockTimeRes, getStartLockTimeRes.toString())
      setStartLockTime(getStartLockTimeRes.toString())
    }
  }, [getStartLockTimeRes])

  useEffect(() => {
    if (getLockTimeRes) {
      // console.log('getLockTimeRes', getLockTimeRes, getLockTimeRes.toString())
      setUnlockTime(getLockTimeRes.toString())
    }
  }, [getLockTimeRes])

  useEffect(() => {
    if (getLockedAmountRes) {
      // console.log('getLockedAmountRes', getLockedAmountRes, formatEther(getLockedAmountRes))
      const lockedAmountFormat = formatEther(getLockedAmountRes)
      setLockedAmount(lockedAmountFormat)
      const now = new Date().getTime() / 1000
      const myUnlockTime = parseInt(startLockTime) + parseInt(unlockTime)
      // console.log("--------btn----2", startLockTime, unlockTime, now, myUnlockTime)
      // console.log(`开始时间：${startLockTime},锁仓时间长度:${unlockTime},解锁时间：${myUnlockTime},当前时间：${now},时间差值：${now - myUnlockTime}，当前时间是否大于解锁时间：${now > myUnlockTime}`)
      if (now > myUnlockTime) {
        // console.log(`-btn-当前时间${now}大于解锁时间${myUnlockTime}，可以解锁`)
        setUnlockedAmount(lockedAmountFormat)
        if (Number(lockedAmountFormat) > 0) {
          setUnlockBtnDisabled(false)
        } else {
          setUnlockBtnDisabled(true)
        }
      } else {
        // console.log(`-btn-当前时间${now}小于于解锁时间${myUnlockTime}，不可以解锁`)
        setUnlockedAmount('0.0')
        setUnlockBtnDisabled(true)
      }
    }
  }, [getLockedAmountRes, startLockTime, unlockTime])

  useEffect(() => {
    if (unlockTokenError) {
      console.log("unlockTokenError", unlockTokenError)
      setToasts([{
        id: `xxx`,
        title: `Error`,
        description: <Text>Error</Text>,
        type: 'danger',
      }])
    }
  }, [unlockTokenError])


  const [inputAmount, setInputAmount] = useState<any>(0)
  // const { onCurrencySelection, onUserInput } = useSwapActionHandlers()

  const error = useMemo(() => {
    if (!account) {
      return 'Connect Wallet'
    }
    if (!inputAmount) {
      return 'Enter an amount'
    }
    if (+inputAmount === 0) {
      return 'Enter an amount'
    }
    if (!tokenBalance) {
      return 'Insufficient balance'
    }
    // if (tokenBalance && (new BigNumber(inputAmount).gt(tokenBalance.toExact()))) {
    //   return 'Insufficient balance'
    // }
    return null
  }, [inputAmount, account, tokenBalance])

  const handleTypeInput = (value: string) => {
    const checkStr = value.split('.')
    if (checkStr[1]?.length > sHCT.decimals) {
      value = `${checkStr[0]}.${checkStr[1].slice(0, sHCT.decimals)}`
    }
    setInputAmount(value)
  }



  const handleMaxInput = useCallback(() => {
    if (tokenBalance) {
      // handleTypeInput(maxAmountInput.toExact())
      setInputAmount(tokenBalance)
    }
  }, [tokenBalance])



  const handleStake = async () => {
    try {
      // console.log('status', status)
      console.log('lockAmount', inputAmount, parseEther(inputAmount))
      const res = await lockContract.stake(parseEther(inputAmount))
      const tip = `Lock sHCT ${inputAmount}`
      onOpen()
      setSwapState(old => ({
        ...old,
        txHash: res?.hash,
        attemptingTxn: true
      }))

      const _ = await res.wait()
      setInputAmount(0)
      setSwapState(old => ({
        ...old,
        attemptingTxn: false
      }))

    } catch (e: any) {
      setSwapState(old => ({
        attemptingTxn: false,
        pendingText: '',
        txHash: undefined,
        summary: ''
      }))
      // todo 报错弹窗
      const errorToast = {
        id: `id-${e?.data?.code}`,
        title: `Error`,
        description: <Text>{e?.message || e?.data?.message}</Text>,
        type: 'danger',
      };

      setToasts([errorToast])
    }
  }

  const actionUnlock = useCallback(() => {

    if (Number(lockedAmount) > 0) {
      if (window.confirm("Attention！\nYou already have sHCT locked. Locking again will re-time the locking period for all sHCT (including unlocked sHCT) to another 180 days.")) {
        setIsOpen(true)
      }
    } else {
      setIsOpen(true)
    }
  }, [lockedAmount])

  const unlock = useCallback(() => {
    pauseQueryRewardsData()
    pauseQueryLockedAmount()
    pauseQueryLockTime()
    pauseQueryStartLockTime()
    unlockToken()
  }, [pauseQueryRewardsData, unlockToken, pauseQueryLockedAmount, pauseQueryLockTime, pauseQueryStartLockTime])


  useEffect(() => {
    if (unlockTokenRes) {
      setTotalReward(0)
      setUnlockedAmount(0)
      setUnlockBtnDisabled(true)
      getTotalReward()
      getLockedAmount()
      getLockTime()
      getStartLockTime()
    }
  }, [unlockTokenRes, getTotalReward, getLockedAmount, getLockTime, getStartLockTime])

  useEffect(() => {
    if (chainId !== 43113 && chainId !== 43114) {
      login(process.env.REACT_APP_ENV === 'MAIN' ? 43114 : 43113)
    }
  }, [login, chainId, account])

  // modal and loading
  const [{ attemptingTxn, txHash, pendingText, summary }, setSwapState] = useState({
    attemptingTxn: false,
    pendingText: '',
    txHash: undefined,
    summary: ''
  })

  const [isOpen, setIsOpen] = useState(false)
  const onDismiss = () => {
    setIsOpen(false)
    setSwapState(old => ({
      attemptingTxn: false,
      pendingText: '',
      txHash: undefined,
      summary: ''
    }))
  }
  const onOpen = () => {
    setIsOpen(true)
  }

  const [toasts, setToasts]: any = useState([]);
  const onRemove = () => { setToasts([]) }


  const modalHeader = () => {
    return (<AutoColumn gap="10px">
      <Text fontSize="30px" mr="8px">
        {inputAmount}
      </Text>
      <Row>
        <CurrencyLogo currency={sHCT} size="30px" />
        <Text fontSize="24px" ml="8px">
          sHCT Tokens
        </Text>
      </Row>
      <Italic fontSize={12} textAlign="left" padding="8px 0 0 0 ">
        *Caution, you can only withdraw funds after the unlock time.
      </Italic>
    </AutoColumn>
    )
  }

  const modalBottom = () => {

    return (<>
      {/* <RowBetween>
        <Body>Lock Amount</Body>
        <RowFixed>
          <CurrencyLogo currency={sHCT} size="40px" />
          <Body>{inputAmount}</Body>
        </RowFixed>
      </RowBetween> */}
      <RowBetween>
        <Body>Locking period</Body>
        <Body>{unlockPeriod}</Body>
      </RowBetween>
      <RowBetween >
        <Body>Estimated Unlock Time</Body>
        <Body>{moment().add(180, 'd').format('YYYY-MM-DD HH:mm')}</Body>
      </RowBetween>
      <Button mt="20px" onClick={handleStake}>Confirm Lock</Button>
    </>)
  }

  const handleDismissConfirmation = () => {
    // setShowConfirm(false)
    setIsOpen(false)
  }
  return (
    <>
      <AppBody>
        <Wrapper id="lock-page">

          <TransactionConfirmationModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            pendingText={pendingText}
            content={() => (
              <ConfirmationModalContent
                title='You will lock'
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
          />
          <LockModalTitle>Lock sHCT</LockModalTitle>
          <CardBody p='24px 24px 48px'>
            <LockPeriod>
              <LockPeriodTitle>Locking period</LockPeriodTitle>
              <Period>{unlockPeriod}</Period>
            </LockPeriod>
            <AutoColumn justify="space-between">
              <AutoRow justify='flex-end'>
                {/* eslint-disable-next-line */}
                <IconDecoration />
              </AutoRow>
            </AutoColumn>
            <AutoColumn gap="md" style={{ marginTop: "20px" }}>
              <CurrencyInputPanel
                portalId="lock-page"
                dir="Amount"
                disableCurrencySelect={Boolean(true)}
                value={inputAmount}
                showMaxButton
                onMax={handleMaxInput}
                currency={sHCT}
                onUserInput={handleTypeInput}
                id="lock-input"
              />
            </AutoColumn>
            <BottomGrouping>
              {
                !account ? (
                  <ConnectWalletButton />
                ) : (
                  onlyOnAvax
                    ? (
                      <Button id="switch-network-button" onClick={login} style={{ width: '100%' }}>
                        <TranslatedText translationId={100}>Switch Network</TranslatedText>
                      </Button>)
                    : (
                      <Column>
                        {sHCTAllowanceData && !sHCTAllowanceLoading && approval !== ApprovalState.APPROVED && <Button
                          mb='16px'
                          onClick={approveSHCT}
                          disabled={approval !== ApprovalState.NOT_APPROVED}
                          style={{ width: '100%' }}
                          variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}

                        >

                          {approval === ApprovalState.PENDING ? (
                            <AutoRow gap="6px" justify="center">
                              <Dots>Approving</Dots>
                            </AutoRow>
                          ) : approval === ApprovalState.APPROVED ? (
                            'Approved'
                          ) : (
                            `Approve ${sHCT?.symbol}`
                          )}
                        </Button>}

                        <Button
                          onClick={actionUnlock}
                          id="stake-button"
                          disabled={!!error || approval !== ApprovalState.APPROVED}
                          variant={
                            error || approval !== ApprovalState.APPROVED
                              ? 'danger'
                              : 'tertiary'
                          }
                          width='100%'
                        >
                          {
                            (sHCTAllowanceLoading || !tokenBalance)
                              ? <Dots>Loading</Dots>
                              : (
                                <TranslatedText translationId={100}>
                                  {error ?? ButtonText}
                                </TranslatedText>
                              )
                          }
                        </Button>
                      </Column>
                    )
                )
              }
              {/* {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />} */}
              {/* {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
            </BottomGrouping>
          </CardBody>
        </Wrapper>

      </AppBody>

      <ToastContainer toasts={toasts} onRemove={onRemove} />

      <BodyGradiantWrapper style={{ marginTop: "16px" }}>
        <FixedPosition>
          <BodyWrapper style={{ padding: "24px" }}>
            <LockModalTitle style={{ padding: "0", marginBottom: "16px" }}>Unlock sHCT</LockModalTitle>
            <StakeInfoRow>
              <StakeInfoRowTitle>Your Reward:</StakeInfoRowTitle>
              <Text>{new BigNumber(totalReward).toFormat()} HCT</Text>
            </StakeInfoRow>
            <StakeInfoRow>
              <StakeInfoRowTitle>Unlocked Amount:</StakeInfoRowTitle>
              <Text>{new BigNumber(unlockedAmount).toFormat()} sHCT</Text>
            </StakeInfoRow>
            <Button variant="secondary" disabled={unlockBtnDisabled} style={{ marginTop: "10px", width: '100%' }} onClick={unlock}>Unlock sHCT</Button>

          </BodyWrapper>
        </FixedPosition>
      </BodyGradiantWrapper>
    </>
  )
}

export default Lock