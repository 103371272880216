import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { Button, Flex, LinkExternal, OpenNewIcon, Text } from '@hurricaneswap/uikit'
import useTheme from 'hooks/useTheme'
import QuestionHelper from 'components/QuestionHelper'

import { useActiveWeb3React } from '../../hooks'
import { getBscScanLink } from '../../utils'

import './index.css'
import successIcon from '../../assets/imgs/success-bold-icon.svg';
import loadingIcon from '../../assets/imgs/loading-icon.svg';
import errorIcon from '../../assets/imgs/error-bold-icon.svg';
import warningIcon from '../../assets/imgs/warning-bold-icon.svg';
import clockIcon from '../../assets/imgs/clock-bold-icon.svg';
import infoIcon from '../../assets/imgs/fi_info.svg';

interface ProgressDataProps {
  from?: any
  to?: any
  middleContent?: string
  step: number
  step1Confirm?: number
  step1LeastConfirm?: number
  step3Confirm?: number
  step3LeastConfirm?: number
  hash?: any
  tips?: any
  status: string
  forceChainId?: number
  tooltipText?: any
}

export default function CrossChianProgress({ tooltipText, forceChainId, middleContent, from, to, step, step1Confirm, step1LeastConfirm = 21, step3Confirm = 1, step3LeastConfirm = 1, status, hash, tips }: ProgressDataProps) {
  // const [step1ConfirmPercent, setStep1ConfirmPercent] = useState(0)
  // const [step3ConfirmPercent, setStep3ConfirmPercent] = useState(0)
  const [step1ClassName, setStep1ClassName] = useState('')
  const [step2ClassName, setStep2ClassName] = useState('')
  const [step3ClassName, setStep3ClassName] = useState('')
  const { chainId } = useActiveWeb3React()
  const { isDark } = useTheme()

  const step1ConfirmPercent = useMemo(() => {
    if (step === 1 && (status === "loading" || status === "success")) {
      return Math.ceil((step1Confirm / step1LeastConfirm) * 100)
    }
    if (step1Confirm >= step1LeastConfirm) {
      return 100
    }
    return 0
  }, [step1Confirm, step1LeastConfirm, step, status])

  const step3ConfirmPercent = useMemo(() => {
    if (step === 3 && status === "success") {
      return (Math.ceil((step3Confirm / step3LeastConfirm) * 100))
    }
    if (step3Confirm >= step3LeastConfirm) {
      return 100
    }
    return 0
  }, [step, step3Confirm, step3LeastConfirm, status])

  useEffect(() => {
    if (step === 1) {
      setStep1ClassName(`hct-timeline-item-${status}`)
      setStep2ClassName('')
      setStep3ClassName('')
    } else if (step === 2) {
      setStep1ClassName('hct-timeline-item-completed')
      setStep2ClassName(`hct-timeline-item-${status}`)
      setStep3ClassName('')
    } else if (step === 3) {
      setStep1ClassName('hct-timeline-item-completed')
      setStep2ClassName('hct-timeline-item-completed')
      setStep3ClassName(`hct-timeline-item-${status}`)
    }
  }, [step, step1Confirm, step1LeastConfirm, status])

  const getStatusIcon = (currentStatus) => {
    if (currentStatus === 'loading' || currentStatus === 'pending') {
      return (<img src={loadingIcon} alt="" style={{ width: '20px' }} className="hct-status-icon anticon anticon-loading anticon-spin" />)
    }
    if (currentStatus === 'error') {
      return (<img src={errorIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />)
    }
    if (currentStatus === 'success') {
      return (<img src={successIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />)
    }
    if (currentStatus === 'warning' || currentStatus === 'timeout') {
      return (<img src={warningIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />)
    }
    return (<img src={clockIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />)
  }
  const Step1Icon = useCallback(() => {
    if (step > 1) {
      return getStatusIcon("success")
    }
    return getStatusIcon(status)
  }, [status, step])
  const step1Text = useMemo(() => {
    if (step === 1 && (status === "error" || status === "warning")) {
      return 'Failed'
    }
    if (step === 1 && status === "waiting") {
      return 'Waiting'
    }
    if (step === 1 && status === "pending") {
      return 'Pending'
    }
    return (`${step1Confirm}/${step1LeastConfirm} Confirmed`)
  }, [status, step, step1Confirm, step1LeastConfirm])

  const Step2Icon = useCallback(() => {
    if (step === 3) {
      return getStatusIcon("success")
    }
    if (step === 1) {
      return getStatusIcon("waiting")
    }
    return getStatusIcon(status)
  }, [status, step])


  const Step3Icon = useCallback(() => {
    if (step < 3) {
      return getStatusIcon("waiting")
    }
    return getStatusIcon(status)
  }, [status, step])
  const step3Text = useMemo(() => {
    if (step < 3) {
      return 'Waiting'
    }
    if (step === 3) {
      if (status === "error" || status === "warning") {
        return 'Failed'
      }
    }
    return (`${step3Confirm}/${step3LeastConfirm} Confirmed`)
  }, [status, step, step3Confirm, step3LeastConfirm])

  return (
    <div className={isDark ? "dark-theme" : "light-theme"}>
      <ul className="hct-timeline">
        <li className={`hct-timeline-item hct-timeline-item-max ${step1ClassName}`}>
          <div className="hct-timeline-item-tail" />
          <div className="hct-timeline-item-head " />
          <div className="hct-timeline-item-content">

            <div className="timeline-item-content-flex">
              <div className="timeline-item-content-point">From</div>
              <div className="hct-timeline-logo-wrap">
                <img src={`https://assets.hurricaneswap.com/blockchains/${from?.chainId || '56'}/logo${isDark ? '' : '-dark'}.svg`} alt="" className="hct-timeline-logo" style={{ width: "30px" }} />
              </div>
              <div className="timeline-item-content-desc">{from?.symbol || 'Binance Smart Chain'}</div>
            </div>

            <div className="timeline-item-content-flex">
              <span className="hct-status-icon-wrap">
                <Step1Icon />
              </span>
              <div className="hct-progress hct-progress-line hct-progress-status-success hct-progress-show-info hct-progress-small">
                <div className="hct-progress-outer">
                  <div className="hct-progress-inner">
                    <div className="hct-progress-bg" style={{ width: `${step1ConfirmPercent}%`, height: '8px' }} />
                  </div>
                </div>
              </div>
            </div>
            <Text className="hct-status-text">
              {step1Text}
            </Text>
          </div>
        </li>
        <li className={`hct-timeline-item ${step2ClassName}`}>
          <div className="hct-timeline-item-tail" />
          <div className="hct-timeline-item-head " />
          <div className="hct-timeline-item-content">
            <div className="timeline-item-content-flex">
              <div className="timeline-item-content-point">
                <Step2Icon />
              </div>
              <div className="hct-timeline-logo-wrap">
                <img src="https://assets.hurricaneswap.com/blockchains/43114/0x45c13620b55c35a5f539d26e88247011eb10fdbd/logo.png" alt="" className="hct-timeline-logo" style={{ width: "30px" }} />
              </div>
              <div className="timeline-item-content-desc">{middleContent || "Roke Protocol"} </div>
            </div>
          </div>
        </li>
        <li className={`hct-timeline-item hct-timeline-item-last ${step3ClassName}`}>
          <div className="hct-timeline-item-tail" />
          <div className="hct-timeline-item-head " />
          <div className="hct-timeline-item-content">
            <div className="timeline-item-content-flex">
              <div className="timeline-item-content-point">To</div>
              <div className="hct-timeline-logo-wrap">
                <img src={`https://assets.hurricaneswap.com/blockchains/${to?.chainId || '43114'}/logo${isDark ? '' : '-dark'}.svg`} alt="" className="hct-timeline-logo" style={{ width: "30px" }} />
              </div>
              <div className="timeline-item-content-desc">{to?.symbol || 'Avalanche C-chain'}</div>
              {/* <img src={errorIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />
              <img src={warningIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />
              <img src={successIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" />
              <img src={loadingIcon} alt="" style={{ width: '20px' }} className="hct-status-icon anticon anticon-loading anticon-spin" />
              <img src={clockIcon} alt="" style={{ width: '20px' }} className="hct-status-icon" /> */}

            </div>

            <div className="timeline-item-content-flex">
              <div className="timeline-item-content-point">
                <Step3Icon />
              </div>
              <div className="hct-progress hct-progress-line hct-progress-status-success hct-progress-show-info hct-progress-small">
                <div className="hct-progress-outer">
                  <div className="hct-progress-inner">
                    <div className="hct-progress-bg" style={{ width: `${step3ConfirmPercent}%`, height: '8px' }} />
                  </div>
                </div>
              </div>
            </div>
            <Text className="hct-status-text">{step3Text}</Text>
          </div>
        </li>
      </ul>
      <div className="tips-wrap">
        {chainId && hash && (<div className="view-on-explorer">
          <a style={{ fontSize: '16px' }} href={getBscScanLink(forceChainId || chainId, hash, 'transaction')} target="_blank" rel="noreferrer">View on Explorer <OpenNewIcon style={{ fill: "#5B8DEF", width: "14px", verticalAlign: "bottom" }} /></a></div>
        )}
        {tips && (<Flex alignItems='flex-start' justifyContent='center' className="mod">
          <Text fontSize='14px' style={{ wordBreak: 'break-word' }} lineHeight='16px' >{tips}</Text>
          {
            step === 3 && status === "success" && tooltipText ? (
              <QuestionHelper size={12} style={{ marginLeft: '0' }} text={tooltipText} />
            ) : (
              null
            )
          }

        </Flex>)}

      </div>
    </div>
  )
}
