import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
  Button,
  Text,
  Flex,
  HelpLinkCard,
  MessageLinkCard,
  HomeArrowIcon,
  OptionCard,
  Skeleton,
  TwitterIcon,
  TelegramIcon,
  MediumIcon,
  LinkExternal
} from '@hurricaneswap/uikit'
import { useTranslation } from 'react-i18next'
import useMobile from '../../hooks/useMobile'

import heartIcon from './Icons/heart.png'
import toolsIcon from './Icons/tools.png'
import transferIcon from './Icons/transfer.png'

const Title = styled(Text)`
  font-family: GTTextBold;
  font-size: 2.5em;
  line-height: 46px;
  text-align: center;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text};
`

const IconContainer = styled.div`
  width: 47.83px;
  height: 47.83px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items:center;
  justify-content: center;
`
const CardWrapper = styled.div`
  >div{
    width: 100%;
    max-width: 490px;
    margin-top: 10px;
  }
  margin: auto;
  width: 100%;
  max-width: 1000px;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 490px);
  grid-gap: 20px;
  @media (max-width: 522px) {
      grid-template-columns: repeat(auto-fill, 100%);
  
  }
`

const GridContainer = styled.div<{ ifMobile: boolean }>`
  display: flex;
  justify-content: center;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
    
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
const AuditReportLinkWrap = styled.div`
text-align:center;
color:#5723A6;
a{

}
`
const AuditReportGridContainer = styled.div<{ ifMobile: boolean }>`
display: grid;
justify-content: center;
grid-template-columns: ${(ifMobile => (ifMobile ? '1fr 1fr 1fr' : 'repeat(auto-fill, 300px)'))};
grid-gap: 20px;
padding: 30px 0 50px;
`

const CoinCardWrapper = styled.div<{ ifMobile: boolean }>`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(auto, 230px));
  grid-gap: 35px 20px;
  width: 100%;
  max-width: 100%;
  margin-bottom: ${({ ifMobile }) => (ifMobile ? '30px' : '136px')};
  
  > div{
    width: 100%;
    max-width: 230px; 
  
  }
  
  @media (max-width: 510px) {
      grid-template-columns: repeat(2, 1fr);
      >div{
        width: 100%;
        height: 210px;
        img{
            transform: scale(0.7);
            transform-origin: bottom right;
        }
      }
      
  }
  
  @media (max-width: 320px) {
      grid-template-columns: repeat(1, 1fr);
      justify-items: center;
      >div{
        width: 100%;
      }
  }
`

const Info = styled.div`
margin-top: 40px;
margin-bottom: 40px;
text-align: center;
//--progress:0.5;
width:100%;
max-width: 300px;
display:flex;
flex-direction:column;
align-items:center;
`

export default function TopInfo() {
  const ifMobile = useMobile()
  const { t } = useTranslation()

  return (<section style={{ width: '100%', maxWidth: '1000px', margin: '0 auto' }}>
    <Title style={{ textAlign: ifMobile ? 'center' : 'center' }}>{t('What is NHCT?')}</Title>
    <GridContainer ifMobile={ifMobile}>
      <Info className={ifMobile ? 'info swashIn' : 'info'}>
        <img src={heartIcon} alt='' />
        <Text mt={1} fontSize='20px' fontWeight='400'>{t('NFT Market')}</Text>
        <Text>{t('Hurricane NFT Market supports cross-chain NFT trades')}</Text>
      </Info>
      <Info className={ifMobile ? 'info swashIn' : 'info'}>
        <img src={toolsIcon} alt='' />
        <Text mt={1} fontSize='20px' fontWeight='400'>{t('Governance Token')}</Text>
        <Text>{t('NHCT will be used for voting, fee discount, and cross-chain NFT listing.')}</Text>
      </Info>
      <Info className={ifMobile ? 'info swashIn' : 'info'}>
        <img src={transferIcon} alt='' />
        <Text mt={1} fontSize='20px' fontWeight='400'>{t('Burn to Mint')}</Text>
        <Text>{t('NHCT can only be generated from HCT Convert.')}</Text>
      </Info>
    </GridContainer>
  </section>)

}