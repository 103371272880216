import { ChainId } from '@glhf-libs/sdk'
import React, { useContext, useMemo } from 'react'

import styled, { ThemeContext } from 'styled-components'
import { Button, LinkExternal } from '@hurricaneswap/uikit'
import { useActiveWeb3React } from '../../hooks'
import { AutoColumn } from '../Column'
import { getBscScanLink } from '../../utils'
import { Spinner } from '../Shared'

import { Wrapper, Section, IconWrap, ContentHeader } from './helpers'

const ContentWrap = styled.div`
  margin-top:30px;
`


type CommonModalProps = {
  onDismiss: () => void
  title: string
  content: () => React.ReactNode
}


const CommonModal = ({ onDismiss, title, content }: CommonModalProps) => {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null
  return (
    <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
      <Section>
        <ContentHeader onDismiss={onDismiss}>{title}</ContentHeader>
        <ContentWrap>
          {content()}
        </ContentWrap>
      </Section>
    </Wrapper>
  )
}

export default CommonModal
