import React, { useMemo } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ButtonMenu, ButtonMenuItem, ArrowBackIcon } from '@hurricaneswap/uikit'
import TranslatedText from '../TranslatedText'

const StyledNav = styled.div`
  z-index: 5;
  // height: 100%;
  display: flex;
  align-items: center;
`

const StyledHeader = styled.div`
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 1.5rem 2.5rem;
`

const StyledNavLink = styled(NavLink)`

color: ${({ theme }) => (theme.colors.text)}
`

const Nav = ({ activeIndex = 0 }: { activeIndex?: number }) => {
  const { search, pathname } = useLocation()
  const { t } = useTranslation()
  const Links = useMemo(() => {
    if (pathname.includes('stationpool')) {
      return (
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px' }} to={{ pathname: "/stationPool", search }}>{t("Station")}</StyledNavLink>
      )
    }
    if (pathname.includes('convert')) {
      return (
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px' }} to={{ pathname: "/convert", search }}>{t("Convert")}</StyledNavLink>
      )
    }
    if (pathname.includes('stationadd')) {
      return (
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px' }} to={{ pathname: "/stationPool", search }}>{t("Add Liquidity")}</StyledNavLink>
      )
    }
    if (pathname.includes('stationremove')) {
      return (
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px' }} to={{ pathname: "/stationPool", search }}>{t("Remove Liquidity")}</StyledNavLink>
      )
    }
    if (pathname.includes('deposit') || pathname.includes('withdraw')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/deposit", search }}>Deposit</StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/withdraw", search }}>Withdraw</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('stake/shct') || pathname.includes('unstake/shct')) {
      return (
        <>
          <StyledNavLink style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/stake", search }}><ArrowBackIcon /></StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/stake/shct", search }}>{t("Stake")}</StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/unstake/shct", search }}>{t("Unstake")}</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('stake/xhct') || pathname.includes('unstake/xhct')) {
      return (
        <>
          <StyledNavLink style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/stake", search }}><ArrowBackIcon /></StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/stake/xhct", search }}>{t("Stake")}</StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/unstake/xhct", search }}>{t("Unstake")}</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('migrate')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/migrate", search }}>{t("Your Hurricane-V1 Liquidity")}</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('lockup')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 1 }} to={{ pathname: "/lockup-shct", search }}>{t("Lockup sHCT")}</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('add') || pathname.includes('liquidity') || pathname.includes('addLiquidity') || pathname.includes('myLiquidity')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/add/avax", search }}>{t("Add Liquidity")}</StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/liquidity", search }}>{t("My Liquidity")}</StyledNavLink>
        </>
      )
    }
    return (
      <>
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/swap", search }}>{t("Swap")}</StyledNavLink>
      </>
    )
  }, [pathname, search, t])

  return (
    (
      <StyledNav>
        {Links}
      </StyledNav>
    )
  )
}

export default Nav
