import { ChainId } from '@glhf-libs/sdk'
import React, { useState } from 'react'
import { Button, Text } from '@hurricaneswap/uikit'
import { darken } from 'polished'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { JsonRpcProvider } from '@ethersproject/providers'
import styled, { ThemeContext } from 'styled-components'
import { WrappedTokenInfo } from 'state/lists/hooks'
import QuestionHelper from 'components/QuestionHelper'
import CurrencyLogo from 'components/CurrencyLogo'
import { RowBetween, RowFixed } from 'components/Row'
import { BodyGradiantWrapper, FixedPosition, BodyWrapper } from '../AppBody'


// @ts-ignore
window.JsonRpcProvider = JsonRpcProvider

const HCTAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0x45C13620B55C35A5f539d26E88247011Eb10fDbd' : '0xeddeb2ff49830f3aa30fee2f7fabc5136845304a'
const wavaxAddr = process.env.REACT_APP_ENV === 'MAIN' ? '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7' : '0x38EF95A32E443DeEFa79A310c23808D86d7e0A6B'
const _chainId = process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET

const wavax = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": wavaxAddr,
  "decimals": 18,
  "name": "wavax",
  "symbol": "WAVAX",
  "logoURI": "/images/coins/wavax.png"
}, [])

const HCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": HCTAddr,
  "decimals": 18,
  "name": "HCT",
  "symbol": "HCT",
  "logoURI": "https://assets.hurricaneswap.com/blockchains/43114/0x45c13620b55c35a5f539d26e88247011eb10fdbd/logo.png"
}, [])


const StakeInfoTitle = styled.div`
  font-family: GT Eesti Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: ${({ theme }) => { return theme.isDark ? '#fff' : '#4D3C55' }};
  margin-bottom:10px;
`
const StakeType = styled.div`
  border: 1px solid ${({ theme }) => { return theme.isDark ? '#fff' : 'rgba(76, 35, 118, 0.04)' }};
  background:rgba(76, 35, 118, 0.04);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding:10px 12px;
  margin-top:16px;
  height:64px;
  cursor:pointer;
  :hover {
    /*border: 1px solid ${({ theme }) => darken(0.06, theme.colors.invertedContrast)};*/
    background:  ${({ theme }) => { return theme.isDark ? 'rgb(142, 137, 167)' : 'rgba(255, 255, 255, 0.6)' }};
    border:1px solid rgba(0, 0, 0, 0.12);
  }
`

export default function Stake() {
  const history = useHistory()

  const chooseStakeType = (type) => {
    // console.log(type)
    if (type === 'WAVAX') {
      history.push('/stake/shct')
    } else if (type === 'HCT') {
      history.push('/stake/xhct')
    }
  }
  return (<>
    <BodyGradiantWrapper >
      <FixedPosition>
        <BodyWrapper style={{ padding: "51px 24px 42px" }}>
          <StakeInfoTitle style={{ marginBottom: "24px" }}>Choose your revenue</StakeInfoTitle>
          <StakeType onClick={() => chooseStakeType('WAVAX')}>
            <RowFixed>
              <Text>WAVAX</Text>
              <QuestionHelper
                placement="right"
                text={
                  <Text>Choose WAVAX:<br />You will receive sHCT after staking HCT. <br />The ratio of HCT/sHCT is constantly 1:1. <br />You can harvest the WAVAX as revenue.
                  </Text>
                }
              />
            </RowFixed>
            <RowFixed>
              <CurrencyLogo currency={wavax} size="40px" style={{ background: "#fff" }} />
            </RowFixed>
          </StakeType>
          <StakeType onClick={() => chooseStakeType('HCT')}>
            <RowFixed>
              <Text>HCT</Text>
              <QuestionHelper
                placement="right"
                text={
                  <Text>Choose HCT:<br /> You will receive xHCT after staking HCT.
                    <br />The ratio of xHCT/HCT will keep increasing.
                    <br />You can unstake xHCT to get additional HCT as revenue.
                  </Text>
                }
              />
            </RowFixed>
            <RowFixed>
              <CurrencyLogo currency={HCT} size="40px" style={{ background: "#fff" }} />
            </RowFixed>
          </StakeType>
        </BodyWrapper>
      </FixedPosition>
    </BodyGradiantWrapper>
  </>)
}
