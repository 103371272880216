import React, { useContext, useMemo, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { ChainId, Pair } from '@glhf-libs/sdk'
import { useTranslation } from 'react-i18next'
import { Button, CardBody, Text } from '@hurricaneswap/uikit'
import { Link, useLocation } from 'react-router-dom'
import Question from 'components/QuestionHelper'
import FullPositionCard from 'components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { TYPE } from 'components/Shared'
import { TransparentCard as LightCard } from 'components/Card'
import { Rowleft } from 'components/Row'
import { AutoColumn } from 'components/Column'

import { useActiveWeb3React } from 'hooks'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'


const { body: Body } = TYPE

export default function Pool() {
  const { inputCurrency: currencyIdA, outputCurrency: currencyIdB }: any = useParsedQueryString()
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const pathName = useMemo(() => {
    const res = (`/add${currencyIdA ? `/${currencyIdA}` : ''}${currencyIdB ? `/${currencyIdB}` : ''}`)
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyIdA, currencyIdB])
  const onlyOnAvax = useOnlyOnAvax()
  const login = useSwitchChain()

  useEffect(() => {
    if (chainId !== ChainId.AVAX_MAINNET && chainId !== ChainId.AVAX_TESTNET) {
      login(process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET)
    }
  }, [chainId, login])


  return (
    <>
      <AppBody>
        <PageHeader title="Liquidity" description={t("Add liquidity to receive LP tokens")}>
          {
            onlyOnAvax
              ? (
                <Button id="switch-network-button" onClick={login} style={{ width: '100%' }}>
                  <TranslatedText translationId={100}>Switch Network</TranslatedText>
                </Button>
              )
              : (
                <Button id="join-pool-button" as={Link} to="/add/AVAX" style={{ width: '100%' }}>
                  {t('Add Liquidity')}
                </Button>
              )
          }
        </PageHeader>
        <AutoColumn gap="lg" justify="center">
          <CardBody p='24px 24px 48px' style={{ width: '100%' }}>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
              <Rowleft>
                <Text color={theme.colors.text}>
                  {t("Your Liquidity")}
                </Text>
                <Question
                  // eslint-disable-next-line
                  text={
                    'When you add liquidity, you are given pool tokens that represent your share. If you don’t see a pool you joined in this list, try importing a pool below.'
                  }
                />
              </Rowleft>

              {!account ? (
                <LightCard padding="40px">
                  <Body color={theme.colors.textDisabled} textAlign="center">
                    {t("Connect to a wallet to view your liquidity.")}
                  </Body>
                </LightCard>
              ) : v2IsLoading ? (
                <LightCard padding="40px">
                  <Body color={theme.colors.textDisabled} textAlign="center">
                    <Dots>{t("Loading")}</Dots>
                  </Body>
                </LightCard>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {allV2PairsWithLiquidity.map((v2Pair) => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                </>
              ) : (
                <LightCard padding="40px">
                  <Body color={theme.colors.textDisabled} textAlign="center">
                    <TranslatedText translationId={104}>{t("No liquidity found.")}</TranslatedText>
                  </Body>
                </LightCard>
              )}

              <div>
                <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                  {/* eslint-disable-next-line */}
                  {t("Can’t find your LP token?")}
                </Text>

                <Button id="import-pool-link" as={Link} to="/find" style={{ width: '100%' }} variant="secondary">
                  {t('Import it')}
                </Button>

                <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                  {t('Or, if you staked your Hurricane-LP tokens in a farm, unstake them to see them here.')}
                </Text>
              </div>
            </AutoColumn>
          </CardBody>
        </AutoColumn>
      </AppBody>
    </>
  )
}
