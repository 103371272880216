import { ChainId } from '@glhf-libs/sdk'
import React, { useContext, useMemo } from 'react'

import styled, { ThemeContext } from 'styled-components'
import { Button, LinkExternal } from '@hurricaneswap/uikit'
import { useActiveWeb3React } from '../../hooks'
import { AutoColumn } from '../Column'
import { getBscScanLink } from '../../utils'
import { Spinner } from '../Shared'

import { Wrapper, Section, IconWrap, ContentHeader } from './helpers'

const CustomLightSpinner = styled(Spinner) <{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`
const ContentWrap = styled.div`
  text-align:center;
`
const ContentTitleWrap = styled.div`
  font-size:16px;
  font-weight: bold;
`
const TipsWrap = styled.div`
  margin-top:1rem;
  line-height:24px;
`

type CommonModalProps = {
  onDismiss: () => void
  title: string
  hash?: string | undefined
  content?: string
  tips?: any
  iconType?: string
}

const ConfirmIcon = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M110 55.4005V60.0005C109.994 70.7827 106.503 81.2739 100.047 89.9097C93.5908 98.5454 84.5164 104.863 74.1768 107.92C63.8372 110.977 52.7863 110.61 42.6724 106.873C32.5584 103.137 23.9233 96.231 18.0548 87.1859C12.1863 78.1407 9.39896 67.4409 10.1084 56.6821C10.8178 45.9234 14.986 35.6822 21.9914 27.4859C28.9968 19.2896 38.4639 13.5775 48.9809 11.2013C59.498 8.82509 70.5013 9.91223 80.35 14.3005" stroke="#05A660" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M110 20L60 70.05L45 55.05" stroke="#05A660" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)
const CommonModal = ({ onDismiss, title, hash, content, tips, iconType = "success" }: CommonModalProps) => {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  const ModalIcon = () => {
    if (iconType === 'success') {
      return (<ConfirmIcon />)
    }
    if (iconType === "warning") {
      return (<img src="/images/warning.svg" alt="warning" style={{ width: "90px" }} />)
    }
    if (iconType === "error") {
      return (<img src="/images/error.svg" alt="error" style={{ width: "90px" }} />)
    }
    return (<CustomLightSpinner src="/images/blue-loader.svg" alt="loader" size="90px" />)
  }
  if (!chainId) return null
  return (
    <Wrapper style={{ display: 'flex', flexDirection: 'column' }}>
      <Section>
        <ContentHeader onDismiss={onDismiss}>{title}</ContentHeader>
        <ContentWrap>
          <IconWrap>
            <ModalIcon />
          </IconWrap>
          <ContentTitleWrap>{content}</ContentTitleWrap>
          <TipsWrap>{tips}</TipsWrap>
          <AutoColumn gap="8px" justify="center">
            {chainId && hash && (
              <LinkExternal href={getBscScanLink(chainId, hash, 'transaction')}>View on Explorer</LinkExternal>
            )}
          </AutoColumn>
        </ContentWrap>
      </Section>
    </Wrapper>
  )
}

export default CommonModal
