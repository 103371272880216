import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { BigNumber } from 'bignumber.js'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'
import { Button, Text, Box, Flex, Skeleton, Heading, LinkExternal } from '@hurricaneswap/uikit'
import { RowBetween } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import truncateHash from 'utils/truncateHash'
import { isAddress } from '../../utils'

import Config from '../../config'



const MarginTop = styled.section`
  margin-top: 20px;
`

const MarginBottom = styled.section`
  margin-bottom: 20px;
`
export const TableWrapper = styled(Flex)`
  width: 100%;
  padding-top: 16px;
  flex-direction: column;
  gap: 16px;
  background-color: ${({ theme }) => theme.isDark ? 'rgba(29, 20, 43, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  border-radius: 12px; //${({ theme }) => theme.radii.card};
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

export const Break = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.cardBorder};
  width: 100%;
`
const ResponsiveGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 24px;
  @media screen and (max-width: 940px) {
  
  }
  @media screen and (max-width: 800px) {
   
  }
  @media screen and (max-width: 500px) {
    font-size:12px;
    grid-template-columns: 1fr 1fr;
    & > *:nth-child(4) {
      display: none;
    }
    & > *:nth-child(2) {
      display: none;
    }
  }
`
const UlGrid = styled.ul`
  display: grid;
  grid-template-columns:  30% 20% 50%;
  justify-items: start;
  align-items: center;
  margin-bottom:1rem;
  border-top: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }}; 
  @media screen and (max-width: 500px) {
    font-size:12px;
    grid-template-columns: 40% 60%;
    & > *:nth-child(2) {
      display: none;
    }
    & > *:nth-child(5) {
      display: none;
    }
  }
  li{
    border-left: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    &:nth-of-type(3n-2){
      padding-left: 0;
      padding-right: 0;
      border-left: none;
    }
  }
`
const ContractLink = styled(Text)`
  a{
    color:#5B8DEF; 
  }
`

const StyledLink = styled(LinkExternal)`
  color:#5B8DEF;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  & svg {
    width:14px;
    fill:#5B8DEF
  }
  
`
interface PoRTableProps {
  inputTokenAddr?: string
  outTokenAddr?: string
  isInfoPage?: boolean
}
function PoRTable({ inputTokenAddr, outTokenAddr, isInfoPage }: PoRTableProps) {
  const [inputTokenPoR, setInputTokenPoR] = useState({ avax: null, bsc: null })
  const [outputTokenPoR, setOutputTokenPoR] = useState({ avax: null, bsc: null })
  const [queryCondition, setQueryCondition] = useState([])
  const showInput = useMemo(() => Object.values(inputTokenPoR).filter(i => i).length > 0, [inputTokenPoR])
  const showOutput = useMemo(() => Object.values(outputTokenPoR).filter(i => i).length > 0, [outputTokenPoR])



  useEffect(() => {
    setQueryCondition(old => {
      const _ = []
      const _input = isAddress(inputTokenAddr) ? inputTokenAddr.toLowerCase() : ''
      const _output = isAddress(outTokenAddr) ? outTokenAddr.toLowerCase() : ''
      if (_input) {
        _.push(_input)
      }
      if (_output) {
        _.push(_output)
      }
      return _
    })

  }, [inputTokenAddr, outTokenAddr])

  useEffect(() => {
    async function getPoRData() {
      const inputPoR = { avax: null, bsc: null }
      const outputPoR = { avax: null, bsc: null }
      // const queryCondition = []
      // TO DO : 判断是否为aToken ，目前直接去graph取数据，取不到的就当做不是atoken

      if (queryCondition.length > 0) {
        const queryConditionStr = JSON.stringify(queryCondition)
        const avaxPoRData = await axios.post(Config.avax.por, {
          query: `{
            tokens(where:{id_in:${queryConditionStr}} ) {
              id
              name
              symbol
              decimals
              totalSupply
              originAddress
            }
          }`,
          variables: null
        });
        if (avaxPoRData?.data?.data?.tokens) {
          const avaxTokens = avaxPoRData?.data?.data?.tokens
          const bscTokenList = avaxTokens.map(item => {
            // console.log('item.id === inputTokenAddr.toLowerCase()', item.id, inputTokenAddr.toLowerCase())
            if (item.id === inputTokenAddr?.toLowerCase()) {
              inputPoR.avax = item
            }
            if (item.id === outTokenAddr?.toLowerCase()) {
              outputPoR.avax = item
            }
            return item.originAddress
          })
          // console.log('bscTokenList', bscTokenList)
          // console.log('inputPoR', inputPoR)
          // console.log('outputPoR', outputPoR)
          const bscPoRData = await axios.post(Config.bsc.por, {
            query: `{
              tokens(where:{id_in:${JSON.stringify(bscTokenList)}} ) {
                id
                name
                symbol
                decimals
                reserve
              }
            }`,
            variables: null
          });
          if (bscPoRData?.data?.data?.tokens) {
            const bscTokens = bscPoRData?.data?.data?.tokens
            // console.log("bscTokens", bscTokens)
            bscTokens.forEach(item => {
              if (item.id === inputPoR?.avax?.originAddress) {
                inputPoR.bsc = item
              }
              if (item.id === outputPoR?.avax?.originAddress) {
                outputPoR.bsc = item
              }
            })
            // console.log('inputPoR', inputPoR)
            // console.log('outputPoR', outputPoR)
          } else {
            inputPoR.bsc = null
            outputPoR.bsc = null
          }
        } else {
          inputPoR.avax = null
          outputPoR.avax = null
        }

        setInputTokenPoR(inputPoR)
        setOutputTokenPoR(outputPoR)
      }
    }

    getPoRData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTokenAddr, outTokenAddr, queryCondition])

  const PoRTables = useCallback(() => {
    return (<>
      <DrawPoRTable porData={inputTokenPoR} />
      <DrawPoRTable porData={outputTokenPoR} />
    </>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInput, showOutput])

  return (<>
    {(inputTokenPoR.avax || outputTokenPoR.avax)
      && (isInfoPage
        ? (<InfoTable inputTokenPoR={inputTokenPoR} outputTokenPoR={outputTokenPoR} />)
        : (<CommonTable  ><PoRTables /></CommonTable>)
      )}
  </>)
}

function InfoTable({ inputTokenPoR, outputTokenPoR }) {
  const { t } = useTranslation()
  return (<>
    {!inputTokenPoR.avax && !inputTokenPoR.bsc && !outputTokenPoR.avax && !outputTokenPoR.bsc && (<>
      <Heading mb="16px" mt="40px" scale="lg"> {t('Proof of Reserve')} </Heading>
      <TableWrapper>
        <ResponsiveGrid>
          <Text>{t("CHAIN")}</Text>
          <Text>{t("TOKEN NAME")}</Text>
          <Text>{t("SUPPLY")}</Text>
          <Text>{t("CONTRACT ADDRESS")}</Text>
        </ResponsiveGrid>
        <Break />
        {inputTokenPoR.bsc && (<>
          <ResponsiveGrid>
            <Text>Binance Smart Chain</Text>
            <Text>{inputTokenPoR.avax.symbol.slice(1)}</Text>
            <Text>{new BigNumber(inputTokenPoR.bsc.reserve).div(10 ** inputTokenPoR.avax.decimals).toFormat(2)} {inputTokenPoR.avax.symbol.slice(1)}</Text>
            <StyledLink style={{ textDecoration: 'underline' }} href={`${Config.bsc.explorer}/token/${inputTokenPoR.bsc.id}`}>
              {truncateHash(inputTokenPoR.bsc.id)}
            </StyledLink>
          </ResponsiveGrid>
          <Break />
        </>
        )}
        {inputTokenPoR.avax && (<>
          <ResponsiveGrid>
            <Text>Avalanche C-chain</Text>
            <Text>{inputTokenPoR.avax.symbol}</Text>
            <Text>{new BigNumber(inputTokenPoR.avax.totalSupply).div(10 ** inputTokenPoR.avax.decimals).toFormat(2)} {inputTokenPoR.avax.symbol}</Text>
            <StyledLink style={{ textDecoration: 'underline' }} href={`${Config.avax.explorer}/token/${inputTokenPoR.avax.id}`}>
              {truncateHash(inputTokenPoR.avax.id)}
            </StyledLink>
          </ResponsiveGrid>
          <Break />
        </>
        )}
        {outputTokenPoR.bsc && (<>
          <ResponsiveGrid>
            <Text>Binance Smart Chain</Text>
            <Text>{outputTokenPoR.avax.symbol.slice(1)}</Text>
            <Text>{new BigNumber(outputTokenPoR.bsc.reserve).div(10 ** outputTokenPoR.avax.decimals).toFormat(2)} {outputTokenPoR.avax.symbol.slice(1)}</Text>
            <StyledLink style={{ textDecoration: 'underline' }} href={`${Config.bsc.explorer}/token/${outputTokenPoR.bsc.id}`}>
              {truncateHash(outputTokenPoR.bsc.id)}
            </StyledLink>
          </ResponsiveGrid>
          <Break />
        </>
        )}
        {outputTokenPoR.avax && (<>
          <ResponsiveGrid>
            <Text>Avalanche C-chain</Text>
            <Text>{outputTokenPoR.avax.symbol}</Text>
            <Text>{new BigNumber(outputTokenPoR.avax.totalSupply).div(10 ** outputTokenPoR.avax.decimals).toFormat(2)} {outputTokenPoR.avax.symbol}</Text>
            <StyledLink style={{ textDecoration: 'underline' }} href={`${Config.avax.explorer}/token/${outputTokenPoR.avax.id}`}>
              {truncateHash(outputTokenPoR.avax.id)}
            </StyledLink>
          </ResponsiveGrid>
          <Break />
        </>
        )}
      </TableWrapper></>)}
  </>)
}

function CommonTable({ children }) {
  const { t } = useTranslation()

  return (<>
    <MarginTop>
      <Flex alignItems='center' justifyItems='center' height='22px' mb='24px'>
        <Text fontSize="16px" lineHeight='22px'>{t('Proof of Reserve')}</Text>
        <QuestionHelper
          // eslint-disable-next-line
          text={
            <Text>Proof of Reserve displays the amount of the specific asset between the source chain and Avalanche C-chain.
            </Text>
          }
        />
      </Flex >
      {children}
    </MarginTop>
  </>)
}

function DrawPoRTable({ porData }) {
  const avaxDecimals = porData?.avax?.decimals
  const totalSupply = porData?.avax?.totalSupply
  const bscDecimals = porData?.avax?.decimals
  const reserve = porData?.bsc?.reserve
  return (<>
    {porData.avax && porData.bsc && (
      <UlGrid >
        <li>
          <Text mb='4px' fontSize='12px'>Chain Name</Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>Binance Smart Chain</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' >Token </Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{porData.avax.symbol.slice(1)}</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' >Reserve</Text>
          <RowBetween>
            {reserve ?
              <Text style={{ wordBreak: 'break-all' }} fontSize='16px'>
                {new BigNumber(reserve).div(10 ** bscDecimals).toFormat(2)} {porData.avax.symbol.slice(1)}
              </Text> :
              '-'
            }
            {porData?.bsc?.id &&
              <StyledLink style={{ textDecoration: 'underline' }} href={`${Config.bsc.explorer}/token/${porData.bsc.id}`}>
                View contract
              </StyledLink>
            }
          </RowBetween>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' >Chain Name</Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>Avalanche C-chain</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' >Token </Text>
          <Text style={{ wordBreak: 'break-all' }}
            fontSize='16px'>{porData.avax.symbol}</Text>
        </li>
        <li>
          <Text mb='4px' fontSize='12px' >Supply</Text>
          <RowBetween>
            <Text style={{ wordBreak: 'break-all' }} fontSize='16px'>
              {new BigNumber(totalSupply).div(10 ** avaxDecimals).toFormat(2)} {porData.avax.symbol}
            </Text>
            <StyledLink style={{ textDecoration: 'underline' }} href={`${Config.avax.explorer}/token/${porData.avax.id}`}>
              View contract
            </StyledLink>
          </RowBetween>
        </li>
      </UlGrid>)}
  </>
  )
}



export default PoRTable
