import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// export const getFleets = createAsyncThunk(
//     'mining/getFleets',
//     async (params) => {
//         const response = await getAllFleet()
//         return response.data
//     }
// )

export enum Field {
    INPUT = 'INPUT',
    OUTPUT = 'OUTPUT'
}

export interface SwapState {
    readonly independentField: Field
    readonly typedValue: string
    readonly [Field.INPUT]: {
        readonly currencyId: string | undefined
    }
    readonly [Field.OUTPUT]: {
        readonly currencyId: string | undefined
    }
    // the typed recipient address or ENS name, or null if swap should go to sender
    readonly recipient: string | null
}

const initialState: SwapState = {
    independentField: Field.INPUT,
    typedValue: '',
    [Field.INPUT]: {
        currencyId: '',
    },
    [Field.OUTPUT]: {
        currencyId: '',
    },
    recipient: null,
}


export const currencySlice = createSlice({
    name: 'lpBridge',
    initialState: {
        [Field.INPUT]: {
            currencyId: '',
        },
        [Field.OUTPUT]: {
            currencyId: '',
        },
        independentField: '',
        typedValue: '',
        recipient: null,
    },
    reducers: {
        selectCurrency: (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
            return {
                [Field.INPUT]: {
                    currencyId: inputCurrencyId,
                },
                [Field.OUTPUT]: {
                    currencyId: outputCurrencyId,
                },
                independentField: field,
                typedValue,
                recipient,
            }
        }
    },
    extraReducers: {
        // [getMiningMechas.pending]: (state, action) => {
        //     // Add user to the state array
        //     state.pending = true
        // }
    },
})

// Action creators are generated for each case reducer function
// export const { fleets } = currencySlice.actions

export default currencySlice.reducer
