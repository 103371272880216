
import React, { useEffect, useState } from 'react'
import { CheckmarkCircleIcon, ErrorIcon, Flex, Text, Button, CloseIcon } from '@hurricaneswap/uikit'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from 'hooks'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import { useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import Loader from 'components/Loader'
import { defaultChainConfig } from 'constants/index'
import TranslatedText from '../TranslatedText'
import Column from '../Column'
import { useStationActionHandlers } from '../../state/station/hooks'
import { setupNetwork } from '../../utils'
import { useSwitchChain } from '../../hooks/Station'
import Modal from '../Modal'
import { RowBetween } from '../Row'
import { PaddedColumn } from '../SearchModal/styleds'
import useStationDefaultInput from '../../hooks/useStationDefaultInput'



const Wrapper = styled.div`
  flex: 1;
  padding: 24px;
  overflow-y: scroll;
`

const ButtonContainer = styled.div<{ selected: boolean, disabled: boolean }>`
  height: 72px;
  background:${({ theme }) => { return theme.isDark ? 'rgba(209 ,199, 219,0.04)' : 'rgba(76, 35, 118, 0.04)' }} ;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
   ${({ selected, theme }) => (selected) && css`border: 1px solid ${theme.colors.borderColor};`}
   ${({ disabled, theme }) => (disabled) && css`cursor: no-drop;`}
`
const ChainStatus = styled.div`
  flex-grow:1;
  text-align:right;
  color: ${({ theme }) => { return theme.isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(77, 60, 85, 0.65)' }};
`

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const More = styled.div`
  text-align:center;
  padding-bottom:32px;
  padding-top:16px;
`

const ChooseChain = ({ noNeedClose = false, isOpen, portalId, onDismiss, chainConfig = defaultChainConfig }) => {
  const history = useHistory()
  const { account, chainId } = useActiveWeb3React()
  const [id, setId]: any = useState()
  const { t } = useTranslation()
  const checkChainId = async () => {
    const res = await setupNetwork()
    if (!res) {
      history.push('/pool')
    }
  }

  // const {allowStation, stationChainId} = useSelector<AppState, AppState['station']>((state) => state.station)
  const allowStation = true
  const { onUpdateStationChainId } = useStationActionHandlers()

  const switchChain = useSwitchChain()
  const defaultInput = useStationDefaultInput()

  const handleClick = async (_id) => {
    if (chainId === _id) {
      onUpdateStationChainId(_id)
      setId(_id)
      onDismiss()
      return
    }
    const res = await switchChain(_id)
    if (res) {
      onUpdateStationChainId(_id)
      setId(_id)
    }
  }


  useEffect(() => {
    if (chainId === id) {
      onDismiss()
      window.localStorage.setItem('STATION_CHAIN_ID', String(id))
      // setTimeout(() => {
      //   history.push(`/stationadd/${id}/${defaultInput}`)
      // }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, id])

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Column style={{ width: '100%', flex: '1 1' }}>
        <PaddedColumn gap="14px">
          <RowBetween>
            <Text>
              <TranslatedText translationId={82}>{t("Select a Chain")}</TranslatedText>
            </Text>
            {
              !noNeedClose && (<CloseIcon style={{ cursor: 'pointer' }} onClick={onDismiss} />)
            }
          </RowBetween>
        </PaddedColumn>

        <Wrapper>
          {!account && (
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <Text mb="8px" bold>
                {t("Please connect your wallet to select a chain")}
              </Text>
            </Flex>
          )}
          {account && chainId && !allowStation && (
            <Flex justifyContent="center" flexDirection="column" alignItems="center">
              <Text mb="8px" bold>
                {t("Station Closed")}
              </Text>
            </Flex>
          )}
          {
            account && chainId && allowStation && (
              <Column style={{ width: '100%', flex: '1 1', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {
                  (!account)
                    ? (
                      <Button onClick={checkChainId}>
                        {t("Connect Network")}
                      </Button>
                    ) : (
                      <>
                        {
                          chainConfig.map(i => (
                            <ButtonContainer
                              selected={chainId === i?.chainId}
                              key={i?.chainId}
                              disabled={i?.disabled}
                              onClick={() => { if (!i?.disabled) handleClick(i?.chainId) }}
                            >
                              <StyledBnbLogo src={i?.src} size='24px' />
                              <Text ml="8px">{i?.chainName}</Text>
                              {i?.disabled && (<ChainStatus>({t("coming soon")})</ChainStatus>)}
                            </ButtonContainer>
                          ))
                        }
                      </>
                    )
                }
              </Column>
            )
          }
        </Wrapper>
        {account && <More>{t("More to come soon...")}</More>}

      </Column>

    </Modal>
  )
}

export default ChooseChain
