import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Button, Text } from '@hurricaneswap/uikit'
import { AutoColumn } from '../Column'
import { Wrapper, Section, BottomSection, ContentHeader } from './helpers'

type TransactionErrorContentProps = { message: any; onDismiss: () => void }

const AlertTriangle = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60 110C87.6142 110 110 87.6142 110 60C110 32.3858 87.6142 10 60 10C32.3858 10 10 32.3858 10 60C10 87.6142 32.3858 110 60 110Z" stroke="#E63535" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M75 45L45 75" stroke="#E63535" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M45 45L75 75" stroke="#E63535" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const TransactionErrorContent = ({ message, onDismiss }: TransactionErrorContentProps) => {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Error</ContentHeader>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle />
          <Text fontSize="16px" color="failure" style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
        </AutoColumn>
      </Section>
      {/* <BottomSection gap="12px">
        <Button onClick={onDismiss}>Dismiss</Button>
      </BottomSection> */}
    </Wrapper>
  )
}

export default TransactionErrorContent
