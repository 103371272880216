import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { ArrowDropDownIcon, Flex, Text } from '@hurricaneswap/uikit'
import useTheme from 'hooks/useTheme'
import { StyledBnbLogo } from 'components/CurrencyLogo'

const DropDownHeader = styled.div<{ disabled: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
//   box-shadow: ${({ theme, disabled }) => disabled ? 'none' : theme.shadows.inset};
//   border: 1px solid ${({ theme }) => (theme.isDark ? '#5723A6' : 'rgba(255, 255, 255, 0.6)')};
  border-radius: 8px;
  // background: #FFFFFF;
//   background: ${({ theme, disabled }) => disabled ? 'transparent' : theme.isDark ? '#1D142B' : 'rgba(255,255,255,0.25)'};
  @media (max-width: 440px) { 
    padding: 0px 0px;
  }

  transition: border-radius 0.15s;
`

const DropDownListContainer = styled.div`
  min-width: 136px;
  height: 0;
  position: absolute;
  overflow: hidden;
  // background: #FFFFFF;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;


  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 168px;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number; disabled: boolean }>`
  cursor: pointer;
  width: ${({ width }) => width}px;
  position: relative;
  // background: #FFFFFF;
  // background: ${({ theme }) => theme.isDark ? '#1D142B' : 'rgba(255,255,255,1)'};
  border-radius: 8px;
  height: 40px;
  // min-width: 136px;

  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 120px;
  }
  ${DropDownHeader}{
    background: ${({ theme, disabled }) => (disabled ? 'transparent' : theme.isDark ? 'rgba(255,255,255,0.25)' : 'rgba(76, 35, 118, 0.04)')};
    // border: 1px solid ${({ theme, disabled }) => (disabled ? 'transparent' : '#8D80D5')};  
  }
  ${(props) =>
        props.isOpen &&
        css`
      
      ${DropDownHeader}{
        // background: ${({ theme }) => theme.isDark ? '#1D142B' : '#FFFFFF'};
        // border: 1px solid #5723A6;
      }

      ${DropDownListContainer} {
        height: auto;
        transform: scaleY(1);
        opacity: 1;
        border: 0px solid ${({ theme }) => theme.colors.textSubtle};
        border-top-width: 0;
        border-radius: 8px;
        box-shadow: 0 20px 20px rgba(159, 56, 140, 0.12);
        margin-top:1px;
          border: 1px solid #5723A6;
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px 16px;
  &:hover {
    background: ${({ theme }) => (theme.isDark ? 'rgba(255,255,255,0.2)' : '#F4F4F6')};
  }
  }
`
const StyledText = styled(Text)`
@media (max-width: 320px) { 
  font-size: 14px;
  margin-left: 5px
}

`

export interface SelectProps {
    options: OptionProps[]
    onChange?: (option: OptionProps) => void
    showLogo: boolean
    disabled?: boolean
}

export interface OptionProps {
    label: string
    value: any
    src?: string
}

const Select: React.FunctionComponent<SelectProps> = ({ disabled = false, options, onChange, showLogo = false }) => {
    const containerRef = useRef(null)
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(options ? options[0] : null)
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const { isDark } = useTheme()
    const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!disabled) {
            setIsOpen(!isOpen)
            event.stopPropagation()
        }
    }

    const onOptionClicked = (option: OptionProps) => () => {
        setSelectedOption(option)
        setIsOpen(false)

        if (onChange) {
            onChange(option)
        }
    }

    useEffect(() => {
        setContainerSize({
            width: dropdownRef?.current?.offsetWidth || '100%', // Consider border
            height: dropdownRef?.current?.offsetHeight || '100%',
        })
    }, [])

    useEffect(() => {
        const handleClickOutside = () => {
            setIsOpen(false)
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])

    return (
        <DropDownContainer isOpen={isOpen} ref={containerRef} {...containerSize} disabled={disabled}>
            {containerSize.width !== 0 && (
                <DropDownHeader disabled={disabled} onClick={toggling} className={isOpen ? 'select selectHeader' : 'selectHeader'}>
                    <Flex>
                        {showLogo && (<StyledBnbLogo src={selectedOption?.src} size='24px' />)}
                        <StyledText ml='10px'>{selectedOption?.label || ''}</StyledText>
                    </Flex>
                </DropDownHeader>
            )}

            {
                !disabled && (<>
                    <ArrowDropDownIcon color="text" onClick={toggling} />
                    <DropDownListContainer>
                        <DropDownList ref={dropdownRef}>
                            {options && options.map((option) =>
                                option?.label !== selectedOption?.label ? (
                                    <ListItem onClick={onOptionClicked(option)} key={option.label}>
                                        <Flex alignItems='center'>
                                            {showLogo && (<StyledBnbLogo src={option?.src} size='24px' />)}
                                            <Text ml='10px'>{option.label}</Text>
                                        </Flex>
                                    </ListItem>
                                ) : null,
                            )}
                        </DropDownList>
                    </DropDownListContainer>
                </>)
            }


        </DropDownContainer>
    )
}

export default Select
