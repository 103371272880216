import { ChainId } from '@glhf-libs/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696', // TODO
  [ChainId.RINKEBY]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.ROPSTEN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.GÖRLI]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.KOVAN]: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
  [ChainId.HECO_MAINNET]: '0x56171094a15B8caC4314C0F8930100B939503bd9',
  [ChainId.HECO_TESTNET]: '0xD1dbc88E6a94053B11dA07CA3E5CE24B282d3d32',
  [ChainId.BSC_MAINNET]: '0x12A2Df4AE3CC8f456bac10549397FB31a975362F',
  [ChainId.BSC_TESTNET]: '0x7c9f88EE2F9b207E0B6f715968E2BAEed025F019',
  [ChainId.AVAX_MAINNET]: '0x7Ad656025a117756Aa87C910Bc4ECADfF7CECf7A',
  [ChainId.AVAX_TESTNET]: '0x801178D71B6Fc3CDF0aE655dB869DEDD5339295E',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
