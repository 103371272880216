import { ChainId, Currency, ETHER, Token } from '@glhf-libs/sdk'
import { useActiveWeb3React } from 'hooks'

export function currencyId(currency: Currency, chainId: number = ChainId.AVAX_MAINNET): string {
  if (currency === ETHER) {
    if (chainId === ChainId.AVAX_MAINNET || chainId === ChainId.AVAX_TESTNET) {
      return 'AVAX'
    }
    if (chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET) {
      return 'BNB'
    }
  }
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export default currencyId
