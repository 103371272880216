import React, { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Flex, Box, Text, Heading, Card, Skeleton } from '@hurricaneswap/uikit'
import { format, fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'
import LineChart from 'pages/Info/components/InfoCharts/LineChart'
import TokenTable from 'pages/Info/components/InfoTables/TokensTable'
import PoolTable from 'pages/Info/components/InfoTables/PoolsTable'
import { formatAmount } from 'pages/Info/utils/formatInfoNumbers'
import BarChart from 'pages/Info/components/InfoCharts/BarChart'
import QuestionHelper from 'components/QuestionHelper'

import {
  useAllPoolData,
  useAllTokenData,
  useProtocolChartData,
  useProtocolData,
  useProtocolTransactions,
} from 'state/info/hooks'
import TransactionTable from 'pages/Info/components/InfoTables/TransactionsTable'
import InfoNav from '../components/InfoNav'


const StyleCard = styled(Card)`
  background:${({ theme }) => theme.isDark ? 'rgba(29, 20, 43, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  border-radius:12px


`

export const ChartCardsContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 1em;

  & > * {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  } ;
`

const Overview: React.FC = () => {
  const { t } = useTranslation()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [liquidityDateHover, setLiquidityDateHover] = useState<string | undefined>()
  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [volumeDateHover, setVolumeDateHover] = useState<string | undefined>()

  const [protocolData] = useProtocolData()
  const [chartData] = useProtocolChartData()

  const [transactions] = useProtocolTransactions()

  const currentDate = format(new Date(), 'MMM d, yyyy')

  // Getting latest liquidity and volumeUSD to display on top of chart when not hovered
  useEffect(() => {
    if (volumeHover == null && protocolData) {
      setVolumeHover(protocolData.volumeUSD)
    }
  }, [protocolData, volumeHover])
  useEffect(() => {
    if (liquidityHover == null && protocolData) {
      setLiquidityHover(protocolData.liquidityUSD)
    }
  }, [liquidityHover, protocolData])

  const formattedLiquidityData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: fromUnixTime(day.date),
          value: day.liquidityUSD,
        }
      })
    }
    return []
  }, [chartData])

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: fromUnixTime(day.date),
          value: day.volumeUSD,
        }
      })
    }
    return []
  }, [chartData])

  const allTokens = useAllTokenData()

  const formattedTokens: any = useMemo(() => {
    return Object.values(allTokens)
      .map((token) => token.data)
      .filter((token) => token)
  }, [allTokens])

  const allPoolData = useAllPoolData()
  const poolDatas: any = useMemo(() => {
    return Object.values(allPoolData)
      .map((pool) => pool.data)
      .filter((pool) => pool)
  }, [allPoolData])

  const somePoolsAreLoading = useMemo(() => {
    return Object.values(allPoolData).some((pool) => !pool.data)
  }, [allPoolData])

  return (
    <>
      <InfoNav />
      <Heading scale="lg" mb="24px" id="info-overview-title" style={{ textAlign: 'center', fontSize: '40px' }}>
        {t('HurricaneSwap Info & Analytics')}
      </Heading>
      <ChartCardsContainer>
        <StyleCard>
          <Box p={['16px', '16px', '24px']}>
            <Text bold color="secondary">
              {t('Liquidity')}
              <QuestionHelper
                placement="right"
                text={
                  <Text>
                    This is only the TVL of the FARM, and the POOL is not included in the calculation.
                  </Text>
                }
              />
            </Text>
            {!!liquidityHover && liquidityHover > 0 ? (
              <Text bold fontSize="24px">
                ${formatAmount(liquidityHover)}
              </Text>
            ) : (
              <Skeleton width="128px" height="36px" />
            )}
            <Text>{liquidityDateHover ?? currentDate}</Text>
            <Box height="250px">
              <LineChart
                data={formattedLiquidityData}
                setHoverValue={setLiquidityHover}
                setHoverDate={setLiquidityDateHover}
              />
            </Box>
          </Box>
        </StyleCard>
        <StyleCard>
          <Box p={['16px', '16px', '24px']}>
            <Text bold color="secondary">
              {t('Volume 24H')}
            </Text>
            {!!volumeHover && volumeHover > 0 ? (
              <Text bold fontSize="24px">
                ${formatAmount(volumeHover)}
              </Text>
            ) : (
              <Skeleton width="128px" height="36px" />
            )}
            <Text>{volumeDateHover ?? currentDate}</Text>
            <Box height="250px">
              <BarChart data={formattedVolumeData} setHoverValue={setVolumeHover} setHoverDate={setVolumeDateHover} />
            </Box>
          </Box>
        </StyleCard>
      </ChartCardsContainer>
      <Heading scale="lg" mt="40px" mb="16px">
        {t('Top Tokens')}
      </Heading>
      <TokenTable tokenDatas={formattedTokens} />
      <Heading scale="lg" mt="40px" mb="16px">
        {t('Top Pools')}
      </Heading>
      <PoolTable poolDatas={poolDatas} loading={somePoolsAreLoading} />
      <Heading scale="lg" mt="40px" mb="16px">
        {t('Transactions')}
      </Heading>
      <TransactionTable transactions={transactions} />
    </>
  )
}

export default Overview
