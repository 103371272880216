import { ChainId } from '@glhf-libs/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button, CardBody, Flex, Text, ToastContainer } from '@hurricaneswap/uikit'
import { JsonRpcProvider } from '@ethersproject/providers'
import useRequest from '@ahooksjs/use-request'
import styled, { ThemeContext } from 'styled-components'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { useContract, useTokenContract } from 'hooks/useContract'
import { useDerivedMintInfo } from 'state/mint/hooks'
import { WrappedTokenInfo } from 'state/lists/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import Column, { AutoColumn, ColumnCenter } from 'components/Column'
import Row, { RowBetween, AutoRow } from 'components/Row'
import { BigNumber } from 'bignumber.js'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import CurrencyLogo from 'components/CurrencyLogo'
import { formatEther, parseEther, parseUnits } from '@ethersproject/units'
import { calculateSlippageAmount } from 'utils'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { ArrowWrapper, BottomGrouping, IconDecoration, Wrapper } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import { useActiveWeb3React } from 'hooks'
import { ApprovalState } from 'hooks/useApproveCallback'
import QuestionHelper from 'components/QuestionHelper'

// import { useAddPopup } from 'state/application/hooks'
import { Field } from 'state/swap/actions'
import { tryParseAmount, useSwapActionHandlers } from 'state/swap/hooks'
import PageHeader from 'components/PageHeader'
import ConnectWalletButton from 'components/ConnectWalletButton'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { injected } from '../../connectors'
import AppBody, { BodyGradiantWrapper, FixedPosition, BodyWrapper } from '../AppBody'
import '../../css/modal.css'
import { Dots } from '../../components/swap/styleds'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'
// import useMobile from '../../hooks/useMobile'
import nhcthubABI from '../../constants/abis/nhcthub.json'
import { TYPE } from '../../components/Shared'
import whitelist from './whitelist.json'

import addrConfig from '../../config'

const { body: Body, italic: Italic } = TYPE

const StakeInfoTitle = styled.div`
  font-family: GT Eesti Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: ${({ theme }) => (theme.colors.contrast)};
  margin-bottom:10px;
`
const StakeInfoRow = styled(RowBetween)`
  padding-bottom:8px;
`
const StakeInfoRowTitle = styled(Body)`
  color: ${({ theme }) => (theme.colors.contrast)};
`
const AddToken2Wallet = styled.div`
    text-decoration: underline;
    color:#8A7F90;
    text-align:center;
    margin-bottom:48px;
    margin-right:26px;
    z-index:100;
    margin-top:26px;
    cursor:pointer;

`
// @ts-ignore
window.JsonRpcProvider = JsonRpcProvider
const maxAllowance = '115792089237316195423570985008687907853269984665640564039457584007913129639935'
const nhctHubAddress = addrConfig.avax.nhctHubAddr
const HCTAddr = addrConfig.avax.hctAddr
const NHCTAddr = addrConfig.avax.nhctAddr
const _chainId = process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET

const NHCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": NHCTAddr,
  "decimals": 18,
  "name": "NHCT",
  "symbol": "NHCT",
  "logoURI": "https://assets.hurricaneswap.com/blockchains/43114/0x3ce2fcec09879af073b53bef5f4d04327a1bc032/logo.png"
}, [])

const HCT = new WrappedTokenInfo({
  "chainId": _chainId,
  "address": HCTAddr,
  "decimals": 18,
  "name": "HCT",
  "symbol": "HCT",
  "logoURI": "https://assets.hurricaneswap.com/blockchains/43114/0x45c13620b55c35a5f539d26e88247011eb10fdbd/logo.png"
}, [])

const SwapIcon = () => {
  return (<svg style={{ position: 'relative', zIndex: 1 }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F1EEE6" />
    <path d="M20 13V27" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27 20L20 27L13 20" stroke="#4D3C55" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

  )
}

const addNhct = () => {
  injected.getProvider().then(provider => {
    if (provider) {
      provider.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: NHCT?.address,
            symbol: NHCT?.symbol,
            decimals: NHCT?.decimals,
            image: 'https://assets.hurricaneswap.com/blockchains/43114/0x3ce2fcec09879af073b53bef5f4d04327a1bc032/logo.png',
          },
        },
      }).catch((error: any) => {
        console.error(error)
      })
    }
  });
}

export default function Convert() {
  const { chainId, account } = useActiveWeb3React()
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const onlyOnAvax = useOnlyOnAvax()
  const login = useSwitchChain()
  const nhctHubContract = useContract(nhctHubAddress, nhcthubABI, true)
  const HCTContract = useTokenContract(HCT?.address, true)
  const theme = useContext(ThemeContext)
  const addTransaction = useTransactionAdder()

  const { token0, token1, addr1, addr2, ButtonText, amountInContract, status } = useMemo(() => {
    return {
      token0: HCT,
      token1: NHCT,
      addr1: HCTAddr,
      addr2: NHCTAddr,
      ButtonText: 'Convert',
      amountInContract: HCTContract,
      status: 'stake'
    }
  }, [HCTContract])

  const { run: getHCTAllowance, data: HCTAllowanceData, error: HCTAllowanceError, loading: HCTAllowanceLoading }
    = useRequest(() => HCTContract?.allowance(account, nhctHubAddress), { manual: true })

  const { run: approveHCT, data: approveHCTData, error: approveHCTError, loading: HCTApproveLoading }
    = useRequest(() => HCTContract?.approve(nhctHubAddress, maxAllowance), { manual: true })
  const [approval, setApproval] = useState(0)

  const { currencyBalances } = useDerivedMintInfo(token0, token1)

  const [inputAmount, setInputAmount] = useState({
    [Field.INPUT]: '',
    [Field.OUTPUT]: ''
  })
  const [minAmount, setMinAmount] = useState(0)
  const [swapRate, setSwapRate] = useState(1)
  const [totalAmount, setTotalAmount] = useState<any>(0)
  const [releaseAmount, setReleaseAmount] = useState<any>(0)
  const [pendingAmount, setPendingAmount] = useState<any>(0)
  const [allocation, setAllocation] = useState<any>(0)
  const [claimDisabled, setclaimDisabled] = useState(true)
  const [releasePeriod, setReleasePeriod] = useState<any>(0)
  const [remainAmount, setRemainAmount] = useState<any>(0)
  const { run: getReleaseStatus, cancel: cancelGetReleaseStatus, data: getReleaseStatusRes, error: getReleaseStatusError } = useRequest(() => nhctHubContract?.releaseStatus(account), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })
  const { run: checkReleaseTask, cancel: cancelCheckReleaseTask, data: checkReleaseTaskRes, error: checkReleaseTaskError } = useRequest(() => nhctHubContract?.checkReleaseTask(account), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })
  const { run: getReleasePeriod, data: getReleasePeriodRes, error: getReleasePeriodError } = useRequest(() => nhctHubContract?.releasePeriod(), {
    manual: true,
    pollingInterval: 3000,
    pollingWhenHidden: false
  })
  const { run: actionClaim, data: claimRes, error: claimError } = useRequest(() => nhctHubContract?.claim(), {
    manual: true
  })
  // const { run: getSwapRate, data: getSwapRateRes, error: getSwapRateError } = useRequest(() => nhctHubContract?.getSwapRate(parseUnits("1")), {
  //   manual: true,
  //   pollingInterval: 3000,
  //   pollingWhenHidden: false
  // })

  const fetchNHCTInfoBody = { "query": `{  users(where:{id:"${account?.toLowerCase()}"}){  whitelist \n  minted \n  claimed \n burned }}`, "variables": null }
  const { run: fetchNHCTInfo, data: nhctInfo, error: nhctError } = useRequest(
    () => fetch(addrConfig.avax.nhctHubGraph, {
      method: 'POST',
      body: JSON.stringify(fetchNHCTInfoBody)
    }), { manual: true, pollingInterval: 5000 })


  useEffect(() => {
    // approveHCT()
    if (chainId && account) {
      setApproval(ApprovalState.UNKNOWN)
      getHCTAllowance()
      // getSwapRate()
      getReleaseStatus()
      checkReleaseTask()
      getReleasePeriod()
      fetchNHCTInfo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account])

  useEffect(() => {
    if (getReleaseStatusRes) {
      // console.log('parseUnits---', parseUnits("1"))
      // console.log('---getReleaseStatusRes', getReleaseStatusRes, releasePeriod)
      const startAt = getReleaseStatusRes[0] // bignumber
      const total = getReleaseStatusRes[1]// bignumber
      const released = getReleaseStatusRes[2]// bignumber
      const now: any = new Date().getTime() / 1000;
      const timestamp = parseInt(now)
      // console.log("totalAmount", formatEther(total))
      // console.log('total', total, "released", released)
      if (released.eq(0)) {
        setReleaseAmount(0)
      } else {
        setReleaseAmount(new BigNumber(formatEther(released)).toFormat(4))
      }

      // setRemainAmount();
      if (total.eq(0)) {
        setTotalAmount(0)
      } else {
        setTotalAmount(new BigNumber(formatEther(total)).toFormat(4))
      }
      // console.log("released", released, released.toString(), formatEther(released.toString()))
      if (total.sub(released).eq(0)) {
        setPendingAmount(0)
        setclaimDisabled(true)
      } else {
        if (startAt.add(releasePeriod).sub(timestamp).lte(0)) {
          setPendingAmount(new BigNumber(formatEther(total.sub(released))).toFormat(4))
        } else {
          const ratio = ((- startAt.sub(timestamp)) / releasePeriod)
          const totalMint = +formatEther(total) * ratio
          const releasedAmount = formatEther(released.toString())
          const pendingHCT = (Number(totalMint) - Number(releasedAmount)).toFixed(4)
          setPendingAmount(pendingHCT);
        }
        setclaimDisabled(false)
      }
    }
  }, [getReleaseStatusRes, releasePeriod, account])
  useEffect(() => {
    if (getReleaseStatusError) {
      // console.log('---getReleaseStatusError', getReleaseStatusError)
    }
  }, [getReleaseStatusError])
  useEffect(() => {
    if (getReleasePeriodRes) {
      // console.log('---getReleasePeriodRes', getReleasePeriodRes, getReleasePeriodRes.toString())
      if (getReleasePeriodRes.eq(0)) {
        setReleasePeriod(0)
      } else {
        setReleasePeriod(getReleasePeriodRes.toString())
      }
    }
  }, [getReleasePeriodRes])

  useEffect(() => {
    if (getReleasePeriodError) {
      // console.log('---getReleasePeriodError', getReleasePeriodError)
    }
  }, [getReleasePeriodError])

  useEffect(() => {
    if (HCTApproveLoading) {
      setApproval(ApprovalState.PENDING)
    }
  }, [HCTApproveLoading])

  useEffect(() => {
    if (approveHCTData) {
      // todo 判断 approveHCTData?.hash===1 才成功
      approveHCTData.wait().then(res => {
        getHCTAllowance()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveHCTData])

  useEffect(() => {
    if (checkReleaseTaskRes) {
      // console.log('checkReleaseTaskRes--', checkReleaseTaskRes)
      const totalAllocation = checkReleaseTaskRes[0][0].add(checkReleaseTaskRes[0][1]).add(checkReleaseTaskRes[0][2])
      if (totalAllocation.eq(0)) {
        setAllocation(0)
      } else {
        setAllocation(formatEther(totalAllocation))
      }
    }
  }, [checkReleaseTaskRes])

  useEffect(() => {
    if (checkReleaseTaskError) {
      // console.log('checkReleaseTaskError--', checkReleaseTaskError)
    }
  }, [checkReleaseTaskError])
  useEffect(() => {
    if (approveHCTError) {
      setApproval(ApprovalState.NOT_APPROVED)
    }
  }, [approveHCTError])
  useEffect(() => {
    if (HCTAllowanceLoading) {
      setApproval(ApprovalState.PENDING)
    }
  }, [HCTAllowanceLoading])

  useEffect(() => {
    if (HCTAllowanceData) {
      // console.log("HCTAllowanceData--", HCTAllowanceData, HCTAllowanceData.toString(), HCTAllowanceData.toString() > 2000000)
      if (HCTAllowanceData.toString() > 2000000) {
        setApproval(ApprovalState.APPROVED)
      } else {
        setApproval(ApprovalState.NOT_APPROVED)
      }
    }
  }, [HCTAllowanceData])
  // useEffect(() => {
  //   if (getSwapRateRes) {
  //     // console.log('---getSwapRateRes', getSwapRateRes)
  //   }
  // }, [getSwapRateRes])
  // useEffect(() => {
  //   if (getSwapRateError) {
  //     // console.log('---getSwapRateError', getSwapRateError)
  //   }
  // }, [getSwapRateError])

  const showNHCTInfo = useCallback(async () => {
    const resText = await nhctInfo.text()
    const res = JSON.parse(resText)
    const usrsData = res?.data?.users[0]
    // console.log("log: graph--usrsData-whitelist[account]", usrsData, whitelist[account])
    if (account) {
      const isWhitelistAccount = whitelist[account.toLowerCase()]
      // console.log("log: account", account, isWhitelistAccount)
      if (isWhitelistAccount) {
        const proofAmount = isWhitelistAccount.ether;
        // console.log('log: 在白名单内。proofAmount', proofAmount)
        if (proofAmount) {
          let userMinted = 0
          if (usrsData !== undefined) {
            userMinted = usrsData.minted
          }
          const diff = new BigNumber(proofAmount).minus(userMinted)
          // console.log("diff", diff, userMinted)
          if (diff.gt(0)) {
            // console.log('log: 在白名单内。额度还剩', diff.toFormat())
            setRemainAmount(diff.dividedBy(10 ** 18).toFormat(2));
          } else {
            // console.log('log: 在白名单内。额度已经用完')
            setRemainAmount(0)
          }
        } else {
          // console.log('log: 在白名单内。没有proofAmount数据')
          setRemainAmount(0)
        }
      } else {
        // console.log('log: 不在白名单内。')
        setRemainAmount(0)
      }
    } else {
      // console.log('log: 没登陆')
      setRemainAmount(0)
    }
  }, [nhctInfo, account])
  useEffect(() => {
    if (nhctInfo) {
      showNHCTInfo()
    }
  }, [showNHCTInfo, nhctInfo])

  useEffect(() => {
    if (nhctError) {
      console.log(nhctError)
    }
  }, [nhctError])
  const maxAmountInput: any = maxAmountSpend(currencyBalances.CURRENCY_A)
  // const { onCurrencySelection, onUserInput } = useSwapActionHandlers()

  const error = useMemo(() => {
    if (!account) {
      return 'Connect Wallet'
    }
    if (!inputAmount.INPUT) {
      return 'Enter an amount'
    }
    if (+inputAmount.INPUT === 0) {
      return 'Enter an amount'
    }
    if (!currencyBalances || (currencyBalances && new BigNumber(currencyBalances?.CURRENCY_A?.toExact()).lte(0))) {
      return 'Insufficient balance'
    }
    if (currencyBalances && (new BigNumber(inputAmount.INPUT).gt(currencyBalances?.CURRENCY_A?.toExact()))) {
      return 'Insufficient balance'
    }
    return null
  }, [inputAmount.INPUT, account, currencyBalances])

  //  换算比例 swapRate
  const handleTypeInput = useCallback(
    (value: string) => {
      const checkStr = value.split('.')
      if (checkStr[1]?.length > token0.decimals) {
        value = `${checkStr[0]}.${checkStr[1].slice(0, token0.decimals)}`
      }
      // console.log("value", value, (+value * swapRate), new BigNumber(value).multipliedBy(swapRate).toString(10))
      // console.log(formatEther(value))
      setInputAmount(old => ({
        [Field.INPUT]: value,
        [Field.OUTPUT]: +value > 0 ? new BigNumber(value).multipliedBy(swapRate).toString(10) : ''
      }))
    }, [token0, swapRate])

  const handleTypeOutput = useCallback(
    (value: string) => {
      const checkStr = value.split('.')
      if (checkStr[1]?.length > token1.decimals) {
        value = `${checkStr[0]}.${checkStr[1].slice(0, token1.decimals)}`
      }
      setInputAmount(old => ({
        [Field.INPUT]: +value > 0 ? new BigNumber(value).multipliedBy(swapRate).toString(10) : '',
        [Field.OUTPUT]: value
      }))
    }, [token1, swapRate])

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      handleTypeInput(maxAmountInput.toExact())
    }
  }, [maxAmountInput, handleTypeInput])

  // const addPopup = useAddPopup()
  const handleConvert = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClaim = async () => {
    actionClaim()
  }
  useEffect(() => {
    if (claimRes) {
      console.log('claimRes--', claimRes)
    }
  }, [claimRes])
  useEffect(() => {
    if (claimError) {
      console.log('claimError--', claimError)
      const err: any = { ...claimError }
      const errorToast = {
        id: `id-${err?.data?.code}`,
        title: `Error`,
        description: <Text>{err?.data?.message}</Text>,
        type: 'danger',
      };

      setToasts([errorToast])
    }
  }, [claimError])

  const confirmConvert = async () => {
    if (!nhctHubContract) return
    if (!inputAmount.INPUT) return
    if (+inputAmount.INPUT === 0) return

    const amountIn = tryParseAmount(inputAmount.INPUT, token0)
    const amountOut = tryParseAmount(inputAmount.OUTPUT, token1)

    const whitelistCheck = whitelist[account.toLowerCase()]
    let merkleProof = []
    let proofAmount = 0
    if (whitelistCheck) {
      merkleProof = whitelistCheck.proof
      proofAmount = whitelistCheck.ether
    }
    try {

      // const amountInterval = calculateSlippageAmount(amountIn, allowedSlippage)
      // const amountMin = formatEther(amountInterval[0].toString())
      // 1:1兑换 不需要滑点
      const res = await nhctHubContract.refining(merkleProof, proofAmount, amountIn?.raw.toString(), amountIn.raw.toString())
      const tip = `convert ${amountIn.toExact()} ${token0?.symbol} to  ${amountOut.toExact()} ${token1?.symbol}`
      addTransaction(res, {
        summary: tip
      })

      setSwapState(old => ({
        ...old,
        txHash: res?.hash
        // attemptingTxn: true,
        // pendingText: `${ status === 'enter' ? 'Staking' : 'Unstaking'} ${amountIn?.toSignificant(6)} HCT`
      }))

      const _ = await res.wait()

      if (_.status === 1) {
        setInputAmount({
          [Field.INPUT]: '',
          [Field.OUTPUT]: ''
        })
      }
    } catch (e: any) {
      console.log(e)
      setSwapState(old => ({
        attemptingTxn: false,
        pendingText: '',
        txHash: undefined,
        summary: ''
      }))
      // todo 报错弹窗
      const errorToast = {
        id: `id-${e?.data?.code}`,
        title: `Error`,
        description: <Text>{e?.data?.message}</Text>,
        type: 'danger',
      };

      setToasts([errorToast])
    }
  }

  useEffect(() => {
    if (chainId !== 43113 && chainId !== 43114) {
      login(process.env.REACT_APP_ENV === 'MAIN' ? 43114 : 43113)
    }
  }, [login, chainId, account])

  // modal and loading
  const [{ attemptingTxn, txHash, pendingText, summary }, setSwapState] = useState({
    attemptingTxn: false,
    pendingText: '',
    txHash: undefined,
    summary: ''
  })

  const [isOpen, setIsOpen] = useState(false)

  const modalHeader = () => {
    return (<AutoColumn gap="10px">
      <Text fontSize="30px" mr="8px" style={{ "overflow": "hidden", "textOverflow": "ellipsis" }}>
        {inputAmount.OUTPUT}
      </Text>
      <Row>
        <CurrencyLogo currency={NHCT} size="30px" />
        <Text fontSize="24px" ml="8px">
          NHCT Tokens
        </Text>
      </Row>
      <Text >
        Output is estimated. If the price changes by more than your slippage, your transaction will revert.
      </Text>
    </AutoColumn >
    )
  }

  const modalBottom = () => {
    return (<>
      <RowBetween>
        <Body>HCT Burn</Body>
        <Body>
          <Flex alignItems="center">
            <CurrencyLogo currency={HCT} size="30px" />
            <Text ml="8px">{inputAmount.INPUT} </Text>
          </Flex>
        </Body>
      </RowBetween>
      <RowBetween>
        <Body>NHCT Mint</Body>
        <Body>
          <Flex alignItems="center">
            <CurrencyLogo currency={NHCT} size="30px" />
            <Text ml="8px"> {inputAmount.OUTPUT}  </Text>
          </Flex>
        </Body>
      </RowBetween>
      <RowBetween >
        <Body>Rates </Body>
        <Body> 1 HCT = {swapRate} NHCT</Body>
      </RowBetween>
      <RowBetween >
        {/* <Body style={{ color: "#6425C5" }}>*Your minted NHCT will release linearly in 10 days </Body> */}
      </RowBetween>
      <Button mt="20px" onClick={confirmConvert}>Confirm Convert</Button>
    </>)
  }
  const handleDismissConfirmation = () => {
    // setShowConfirm(false)
    setIsOpen(false)
  }

  const onDismiss = () => {
    setIsOpen(false)
    setSwapState(old => ({
      attemptingTxn: false,
      pendingText: '',
      txHash: undefined,
      summary: ''
    }))
  }
  const onOpen = () => {
    setIsOpen(true)
  }

  const [toasts, setToasts]: any = useState([]);
  const onRemove = () => { setToasts([]) }

  return (
    <>
      <AppBody>
        <Wrapper id="convert-page">
          <TransactionConfirmationModal
            isOpen={isOpen}
            onDismiss={onDismiss}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            pendingText={pendingText}
            content={() => (
              <ConfirmationModalContent
                title='You will mint'
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
          />
          <PageHeader title="Convert" showSettings={false} />
          <CardBody p='24px 24px 48px'>

            <AutoColumn gap="md">
              <CurrencyInputPanel
                portalId="convert-page"
                dir="Input"
                disableCurrencySelect={Boolean(true)}
                value={inputAmount[Field.INPUT]}
                showMaxButton={false}
                onMax={handleMaxInput}
                currency={token0}
                onUserInput={handleTypeInput}
                id="bridge-currency-input"
              />

              <AutoColumn justify="space-between">
                <AutoRow justify='flex-end'>
                  {/* eslint-disable-next-line */}
                  <IconDecoration />

                  <ArrowWrapper clickable={false}>
                    <SwapIcon />
                  </ArrowWrapper>
                </AutoRow>
              </AutoColumn>

              <CurrencyInputPanel
                defaultBalanceValue=' '
                portalId="convert-page"
                disableCurrencySelect={Boolean(true)}
                value={inputAmount[Field.OUTPUT]}
                onUserInput={handleTypeOutput}
                dir="Output"
                showMaxButton={false}
                currency={token1}
                id="bridge-currency-output"
              />
              {/* <StakeInfoRow style={{ paddingTop: "10px" }}>
                <StakeInfoRowTitle>Allocation</StakeInfoRowTitle>
                <Body style={{ color: "rgba(100, 37, 197, 1)" }}>{allocation} HCT</Body>
              </StakeInfoRow> */}
              <StakeInfoRow style={{ paddingTop: "10px" }}>
                <StakeInfoRowTitle>
                  Initial quota
                  <QuestionHelper placement="top" text="The initial quota represents your remaining amount of converting HCT to NHCT with a 1:1 rate." />
                </StakeInfoRowTitle>
                <Body style={{ color: "rgba(100, 37, 197, 1)" }}>
                  {remainAmount} HCT
                </Body>
              </StakeInfoRow>
              <StakeInfoRow style={{ paddingTop: "10px" }}>
                <StakeInfoRowTitle>Rate</StakeInfoRowTitle>
                <Body style={{ color: "rgba(100, 37, 197, 1)" }}>
                  1 HCT = {swapRate} NHCT
                </Body>

              </StakeInfoRow>
              {/* <StakeInfoRow style={{ paddingTop: "10px" }}>
                <Body style={{ color: "rgba(100, 37, 197, 1)" }}>1 NHCT = 0.1527 USDT</Body>
                <Body style={{ color: "rgba(100, 37, 197, 1)" }}>1 HCT = 0.0113 USDT</Body>
              </StakeInfoRow> */}
            </AutoColumn>
            <BottomGrouping>
              {
                !account ? (
                  <ConnectWalletButton />
                ) : (
                  onlyOnAvax
                    ? (
                      <Button id="switch-network-button" onClick={login} style={{ width: '100%' }}>
                        <TranslatedText translationId={100}>Switch Network</TranslatedText>
                      </Button>)
                    : (
                      <Column>
                        {HCTAllowanceData && !HCTAllowanceLoading && approval !== ApprovalState.APPROVED && <Button
                          mb='16px'
                          onClick={approveHCT}
                          disabled={approval !== ApprovalState.NOT_APPROVED}
                          style={{ width: '100%' }}
                          variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}

                        >

                          {approval === ApprovalState.PENDING ? (
                            <AutoRow gap="6px" justify="center">
                              <Dots>Approving</Dots>
                            </AutoRow>
                          ) : approval === ApprovalState.APPROVED ? (
                            'Approved'
                          ) : (
                            `Approve ${token0?.symbol}`
                          )}
                        </Button>}
                        <Button
                          onClick={handleConvert}
                          id="stake-button"
                          disabled={!!error || approval !== ApprovalState.APPROVED}
                          variant={
                            error || approval !== ApprovalState.APPROVED
                              ? 'danger'
                              : 'tertiary'
                          }
                          width='100%'
                        >
                          {
                            (HCTAllowanceLoading || !currencyBalances || !currencyBalances?.CURRENCY_A?.raw.toString())
                              ? <Dots>Loading</Dots>
                              : (
                                <TranslatedText translationId={100}>
                                  {error ?? ButtonText}
                                </TranslatedText>
                              )
                          }
                        </Button>
                      </Column>
                    )
                )
              }
              {/* {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />} */}
              {/* {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null} */}
            </BottomGrouping>
          </CardBody>
        </Wrapper>

      </AppBody>

      <ToastContainer toasts={toasts} onRemove={onRemove} />

      {/* <BodyGradiantWrapper style={{ marginTop: "16px" }}>
        <FixedPosition>
          <BodyWrapper style={{ padding: "24px" }}>
            <StakeInfoTitle>Your converted</StakeInfoTitle>
            <StakeInfoRow>
              <StakeInfoRowTitle>Progress</StakeInfoRowTitle>
              <Text>{releaseAmount} /{totalAmount} NHCT</Text>
            </StakeInfoRow>
            <StakeInfoRow>
              <StakeInfoRowTitle>Ready-to-claim</StakeInfoRowTitle>
              <Text>{pendingAmount} NHCT</Text>
            </StakeInfoRow>
            <StakeInfoRow>
              <StakeInfoRowTitle>Pending amount</StakeInfoRowTitle>
              <Text>{pendingAmount} NHCT</Text>
            </StakeInfoRow>
            <Button variant="secondary" disabled={claimDisabled} onClick={handleClaim} style={{ marginTop: "10px", width: '100%' }} >Claim</Button>
          </BodyWrapper>
        </FixedPosition>
      </BodyGradiantWrapper> */}
      <AddToken2Wallet style={{ textDecoration: 'underline', }} onClick={addNhct}>Add NHCT to Wallet</AddToken2Wallet>
    </>
  )
}
